import React, { useCallback, useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import MyTextField from '../../utils/MyTextField'

//UnoButton
import UnoButton from '../../utils/UnoButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import { toogleButton, unoTheme } from "../../../assets/styles/unoTheme";

//RadioGroup
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

//BoostrapInput
import InputBase from '@material-ui/core/InputBase';
import MySelect from '../../utils/MySelect'
import MyRadioGroup from '../../utils/MyRadioGroup'
import MyDatePicker from '../../utils/MyDatePicker'

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

//Grid
import { Grid } from "@material-ui/core";

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import CircularProgress from '@material-ui/core/CircularProgress';

import ConfirmActionRightContent from './ConfirmActionRightContent'
import { getConsultingId, getFirstBeforeBusinessDay, getFirstPtDayMonth, getFirstPtDayOfYear, getLastPtDayMonth, getLastPtDayOfYear, getMonthAndYearPortfolioClosed, getMonthsByArray, getMonthsUntilPortfolioInit, getQuartersByMonthsArray, getSemestersByMonthsArray, getShortMonthName, isDateBeforePortfolioClosed, portfolioClosedToDate } from '../../utils/utils';
import moment from 'moment';
import { reportTypes } from '../../utils/ReportTypes';
import { checkUserIsRole, roles } from '../../utils/user';

const PROVISIONAL_CLIENTS_ENABLED = [394];


const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3)
    },

    main: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        textAlign: 'left',
        overflowY: 'scroll',
        borderTop: 'solid 1px #ccc',
        padding: '24px',

        '& .MuiPaper-root': {
            height: 'calc(100% - 184px)',
        }
    },

    accordionAccount: {
        marginBottom: theme.spacing(2),
    },
    accordionSummary: {
        //background: lemaTheme.secondaryColor,
        //color: "white",
        '&:nth-child(1)': {
            marginBottom: '10px'
        }
    },
    iconWhite: {
        color: 'white',
    },
    accordionDetails: {
        background: '#ffffff'
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    inputsAccount: {
        width: '100%',
    },
    cellIcon: {
        padding: '0px'
    },
    formNewApplication: {
        width: "100%",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    itemRegime: {
        fontSize: "0.6em !important",
    },
    alignLeft: {
        textAlign: "left",
        '& fieldset': {

            '& legend': {
                fontSize: '0.9em !important',
            }

        }

    },
    legendeRadioGroup: {
        fontSize: '0.9em !important',
    },
    headerSearch: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTypography-root': {
            marginBottom: '0px',
        }
    },
    headerDetail: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    input: {
        display: 'none',
    },
    green: {
        color: "green",
        fontWeight: 'bold'
    },
    red: {
        color: "red",
        fontWeight: 'bold'
    },
    blue: {
        color: "blue",
        fontWeight: 'bold'
    },
    spacingLeft1: {
        marginLeft: theme.spacing(1)
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    hiddenIcon: {
        visibility: 'hidden',
    },
    bottom: {

        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'flex-end',

        '& .bottomButtons': {
            paddingTop: theme.spacing(2),
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }

    },
    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },

    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        alignItems: 'center',
        paddingBottom: theme.spacing(4),

        '& .titlesModal': {
            fontWeight: 'bold',
            marginBottom: '16px',
        },

        '& .periodSelect': {
            marginTop: '24px',
            width: '100%',
            padding: '24px 0px 0px 0px',
            borderTop: '1px solid #ced4da',
            textAlign: 'center',

            '& .selectors': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },

            '& .alertNoPeriod': {
                marginTop: '26px',
                color: 'red',
                fontSize: '12px',
                width: '180px',
            },

            '& .formPersonalizaded': {
                textAlign: 'left',
                marginTop: '16px',
                padding: '16px',
                backgroundColor: '#eeeeee',
                borderRadius: '10px',

                '& .MuiTypography-root': {
                    fontSize: '13px',
                }
            }


        },
    },

    validationMessage: {
        color: unoTheme.errorColor,
        fontSize: '14px',
        textAlign: 'right',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },

    toogleButton: toogleButton,

}));

export default function SelectPeriodReport(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const currentUser = useSelector(state => state.currentUser);

    const { content } = props

    console.log("Content: ", content);

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    const [confirmingAction, setConfirmingAction] = useState(false);

    // The first commit of Material-UI
    // const [selectedMonth, setSelectedMonth] = useState(months[0]);
    // const [selectedYear, setSelectedYear] = useState(years[0]);
    const [selectedDate, setSelectedDate] = useState(new Date(2020, 12, 1));
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [loader, setLoader] = useState(false);
    const [validateMessage, setValidateMessage] = useState("");

    const [formattedPortfolioYearsMonths, setFormattedPortfolioYearsMonths] = React.useState();

    const [provisionalReport, setProvisionalReport] = useState();

    const [reportMode, setReportMode] = React.useState('general');
    const handleReportMode = (event, newFormats) => {

        if (newFormats && newFormats.length) {
            initState();
            setReportMode(newFormats);
        }
    };

    const [state, setState] = React.useState({
        dashboard: false,
        portfolio: false,
        distribuition: false,
        articles: false,
        targets: false,
        myFunds: false,
        assetsAndAccounts: false,
        transactionsInPeriod: false,
        myTitles: false,
    });

    function initState() {
        setState({
            dashboard: false,
            portfolio: false,
            distribuition: false,
            articles: false,
            targets: false,
            myFunds: false,
            assetsAndAccounts: false,
            transactionsInPeriod: false,
            myTitles: false,
        })
    }

    const [mainPeriod, setMainPeriod] = React.useState('month');
    const handleMainPeriod = (event, newFormats) => {
        if (newFormats && newFormats.length) {
            setMainPeriod(newFormats);
        }
    };

    const [optionsMonths, setOptionsMonths] = React.useState([]);
    const [optionsQuarters, setOptionsQuarters] = React.useState([]);
    const [optionsSemesters, setOptionsSemesters] = React.useState([]);
    const [optionsYears, setOptionsYears] = React.useState([]);


    const [monthPeriod, setMonthPeriod] = React.useState(0);
    function handleMonthPeriod(value) {
        setMonthPeriod(value)
    }

    const [quarterPeriod, setQuarterPeriod] = React.useState(0);
    function handleQuarterPeriod(value) {
        setQuarterPeriod(value)
    }

    const [semesterPeriod, setSemesterPeriod] = React.useState(0);
    function handleSemesterPeriod(value) {
        setSemesterPeriod(value)
    }

    const [yearPeriod, setYearPeriod] = React.useState(0);
    function handleYearPeriod(value) {

        console.log('formattedPortfolioYearsMonths onChange: ', formattedPortfolioYearsMonths)

        if (value != yearPeriod) {

            const oldMonthsInSelectedYear = formattedPortfolioYearsMonths.months[
                optionsYears[yearPeriod]
            ]
            const monthsInSelectedYear = formattedPortfolioYearsMonths.months[
                optionsYears[value]
            ]

            setOptionsMonths(getMonthsByArray(monthsInSelectedYear));

            if (!monthsInSelectedYear.includes(monthPeriod)) {

                setYearPeriod(formattedPortfolioYearsMonths.months[
                    optionsYears[value]
                ][0]);

            }

            //Caso inclua o período deve-se garantir que o indice de acesso ao mês no novo ano esteja correto
            //2022 [1,2,3,4,5,6,7,8,9] Setembro aqui tem índice 8
            //2021 [9,10,11,12] Setembro aqui tem índice 0         
            if (monthsInSelectedYear.includes(oldMonthsInSelectedYear[monthPeriod])) {
                const newIndexMonth = monthsInSelectedYear.findIndex(el => el == oldMonthsInSelectedYear[monthPeriod]);
                setMonthPeriod(newIndexMonth)
                console.log("NOVO INDEX MONTH: ", newIndexMonth);
            }

            const quarters = getQuartersByMonthsArray(monthsInSelectedYear);
            setOptionsQuarters(quarters);
            if (!quarters[quarterPeriod]) {
                setQuarterPeriod(0);
            }


            const semesters = getSemestersByMonthsArray(monthsInSelectedYear);
            setOptionsSemesters(semesters);
            if (!semesters[semesterPeriod]) {
                setQuarterPeriod(0);
            }


        }
        setYearPeriod(value)
    }

    function getSelectedReports(state) {
        let selecteds = [];
        Object.entries(state).map(([key, element]) => {

            if (state[key]) {
                selecteds.push(key)
            }

        })

        //initState();
        return selecteds;
    }

    function formatConfigReport(client) {

        const configReport = {};
        const yearReport = optionsYears[yearPeriod];

        if (mainPeriod == 'month') {

            console.log('optionsMonths: ', optionsMonths);
            console.log('monthPeriod: ', monthPeriod);
            const monthReport = optionsMonths[monthPeriod].id;

            configReport.startDate = getFirstPtDayMonth(monthReport, yearReport);
            configReport.endDate = getLastPtDayMonth(monthReport, yearReport);
            configReport.periodReport = 'mensal';
            //configReport.periodReport = 'ano';

        } else if (mainPeriod == 'quarter') {

            const { startMonth, endMonth } = optionsQuarters[quarterPeriod];

            configReport.startDate = getFirstPtDayMonth(startMonth, yearReport);
            configReport.endDate = getLastPtDayMonth(endMonth, yearReport);
            configReport.periodReport = 3;

        } else if (mainPeriod == 'semester') {

            const { startMonth, endMonth } = optionsSemesters[semesterPeriod];

            configReport.startDate = getFirstPtDayMonth(startMonth, yearReport);
            configReport.endDate = getLastPtDayMonth(endMonth, yearReport);
            configReport.periodReport = 6;

        } else if (mainPeriod == 'year') {

            configReport.startDate = getFirstPtDayOfYear(yearReport);

            //const portfolioClosedDate = portfolioClosedToDate(client.portfolio_closed);
            // if (portfolioClosedDate.getFullYear() != yearReport) {
            //     configReport.endDate = getLastPtDayOfYear(yearReport);
            // } else {
            //     configReport.endDate = moment.utc(portfolioClosedDate).format('DD/MM/YYYY');
            // }

            const monthReport = optionsMonths[monthPeriod].id;
            configReport.endDate = getLastPtDayMonth(monthReport, yearReport);

            configReport.periodReport = 'ano';

        }

        if (reportMode == 'general') {
            configReport.reportType = 'fullReport';
        } else {
            configReport.reportType = 'custom';
            configReport.reportTypeList = getSelectedReports(state);
        }

        //console.log('configReport: ', configReport);
        return configReport
    }

    function isEmptyCustomReport(state) {
        if (!state.dashboard
            && !state.portfolio
            && !state.distribuition
            && !state.articles
            && !state.targets
            && !state.myFunds
            && !state.assetsAndAccounts
            && !state.transactionsInPeriod
            && !state.myTitles
        ) {
            return true
        }
    }

    function isUnableToReport() {

        if (reportMode != 'general') {
            if (isEmptyCustomReport(state)) {
                return true;
            }

        }

        if (mainPeriod == 'quarter' && optionsQuarters.length == 0) {
            return true;
        }

        if (mainPeriod == 'semester' && optionsSemesters.length == 0) {
            return true;
        }

        return false;
    }

    function doReport(configReport) {
        console.log('configReport: ', configReport);
        dispatch(allActions.reportLoadActions.setReportLoad({
            show: true,
            ...configReport,
            //periodReport: formats,
            message: 'Gerando relatório...',
        }));
    }

    const {
        dashboard,
        portfolio,
        distribuition,
        articles,
        targets,
        myFunds,
        assetsAndAccounts,
        transactionsInPeriod,
        myTitles
    } = state;

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const doProvisionalReport = useCallback(() => {

        const { month, year } = provisionalReport;
        console.log('month, year: ', month, year);


        dispatch(allActions.reportLoadActions.setReportLoad({
            show: true,
            reportType: reportTypes.provisional.cod,
            periodReport: 'mensal',
            startDate: getFirstPtDayMonth(month, year),
            endDate: getFirstBeforeBusinessDay(getLastPtDayMonth(month, year)),
            message: 'Gerando o relatório provisório...'
        }))

    })

    const checkProvisionalReport = useCallback((portfolioClosed) => {
        console.log('portfolioClosed xxx: ', portfolioClosed);
        const { month, year } = getMonthAndYearPortfolioClosed(portfolioClosed);
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();
        console.log(currentMonth, currentYear);

        //setProvisionalReport({ month: month + 1, year: year });

        let monthToCompare = currentMonth - 1;
        let yearToCompare = currentYear;
        if (monthToCompare === 0) {
            monthToCompare = 12;
            yearToCompare--;
        }

        if (month == monthToCompare && year == yearToCompare) {
            //pode gerar relatório provisório
            setProvisionalReport({ month: currentMonth, year: currentYear });
        } else {
            setProvisionalReport(undefined);
        }
    }, [])

    useEffect(() => {
        if (mainPeriod === "month")
            setState({ ...state, transactionsInPeriod: false })
    }, [mainPeriod])

    useEffect(() => {

        if (content && content.client) {

            const { client } = content;

            setMainPeriod('month');
            setReportMode('general');

            const monthsUntilPortfolioInit = getMonthsUntilPortfolioInit(client.portfolio_init, client.portfolio_closed);
            console.log('formattedPortfolioYearsMonths: ', monthsUntilPortfolioInit);

            setOptionsYears(monthsUntilPortfolioInit.years);

            //Inicializando selectedPortfolioMonth e selectedPortfolioYear do client com os últimos registros obtidos            

            const monthsPortfolio = monthsUntilPortfolioInit.months;
            const yearsPortfolio = monthsUntilPortfolioInit.years;



            if (yearsPortfolio
                && monthsPortfolio) {

                const selectedPeriodYear = yearsPortfolio.length - 1;
                setYearPeriod(selectedPeriodYear);

                setOptionsMonths(getMonthsByArray(monthsPortfolio[
                    yearsPortfolio[selectedPeriodYear]
                ]));

                setOptionsQuarters(getQuartersByMonthsArray(monthsPortfolio[
                    yearsPortfolio[selectedPeriodYear]
                ]));

                setOptionsSemesters(getSemestersByMonthsArray(monthsPortfolio[
                    yearsPortfolio[selectedPeriodYear]
                ]));

                if (monthsPortfolio[yearsPortfolio[yearsPortfolio.length - 1]]) {
                    console.log("SETANDO MONTH PERIOD: ", monthsPortfolio[yearsPortfolio[yearsPortfolio.length - 1]].length - 1);
                    console.log("monthsPortfolio: ", monthsPortfolio);
                    setMonthPeriod(monthsPortfolio[yearsPortfolio[yearsPortfolio.length - 1]].length - 1);
                }
            }


            setFormattedPortfolioYearsMonths(monthsUntilPortfolioInit);

            checkProvisionalReport(content.client.portfolio_closed);
        }



    }, [])



    return (
        <div className={classes.root}>



            <div className={classes.header}>
                <IconButton
                    className={classes.hiddenIcon}>
                    <ArrowBackIcon />
                </IconButton>


                <div>
                    <Typography
                        variant={'h6'}
                        align={'left'}>

                        {"Configuração de Relatório"}

                    </Typography>

                </div>


                <IconButton
                    onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                    <CloseIcon />
                </IconButton>

            </div>


            <div className={classes.main}>
                <div
                    className={classes.contentModal}>

                    <Typography className={'titlesModal'}>{"1. Selecione o período"}</Typography>

                    <ToggleButtonGroup
                        className={classes.toogleButton}
                        exclusive value={mainPeriod} onChange={handleMainPeriod} aria-label="text formatting">
                        <ToggleButton value="month" aria-label="mensal">
                            {'Mensal'}
                        </ToggleButton>
                        <ToggleButton value="quarter" aria-label="trimestral">
                            {'Trimestral'}
                        </ToggleButton>
                        <ToggleButton value="semester" aria-label="semestral">
                            {'Semestral'}
                        </ToggleButton>
                        <ToggleButton value="year" aria-label="year">
                            {'Anual'}
                        </ToggleButton>

                    </ToggleButtonGroup>

                    <div className="periodSelect">

                        <Typography className={'titlesModal'}>{"2. Selecione o tipo"}</Typography>


                        <ToggleButtonGroup
                            className={classes.toogleButton}
                            exclusive value={reportMode} onChange={handleReportMode} aria-label="text formatting">
                            <ToggleButton value="general" aria-label="mensal">
                                {'Geral'}
                            </ToggleButton>

                            <ToggleButton value="personalizaded" aria-label="trimestral">
                                {'Personalizado'}
                            </ToggleButton>

                        </ToggleButtonGroup>

                        {
                            reportMode == 'personalizaded' ?
                                <FormGroup className={'formPersonalizaded'}>
                                    <Grid container spacing={1}>
                                        <Grid item md={4}>
                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={dashboard} onChange={handleChange} name={reportTypes.dashboard.cod} />}
                                                label="Dashboard"
                                            />

                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={portfolio} onChange={handleChange} name={reportTypes.portfolio.cod} />}
                                                label="Carteira"
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={distribuition} onChange={handleChange} name={reportTypes.distribuition.cod} />}
                                                label="Distribuição"
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={articles} onChange={handleChange} name={reportTypes.articles.cod} />}
                                                label="Enquadramentos"
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={targets} onChange={handleChange} name={reportTypes.targets.cod} />}
                                                label="Metas"
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={myFunds} onChange={handleChange} name={reportTypes.myFunds.cod} />}
                                                label="Fundos"
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={assetsAndAccounts} onChange={handleChange} name={reportTypes.assetsAndAccounts.cod} />}
                                                label="Ativos e Contas"
                                            />
                                        </Grid>

                                        {
                                            //mainPeriod !== "month" &&
                                            getConsultingId(currentUser) === 1 &&
                                            <Grid item md={4}>
                                                <FormControlLabel
                                                    control={<Checkbox color="primary" checked={transactionsInPeriod} onChange={handleChange} name={reportTypes.transactionsInPeriod.cod} />}
                                                    label="Movimentações Por Período"
                                                />
                                            </Grid>
                                        }

                                        <Grid item md={4}>
                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={myTitles} onChange={handleChange} name={reportTypes.myTitles.cod} />}
                                                label="Análise de títulos"
                                            />
                                        </Grid>

                                    </Grid>






                                </FormGroup> : null
                        }


                    </div>

                    <div className="periodSelect">

                        <Typography className={'titlesModal'}>{"3. Selecione a data"}</Typography>

                        <div className={'selectors'}>


                            <MySelect
                                outerlabel={'Ano'}
                                value={yearPeriod}
                                onChange={(e) => handleYearPeriod(e.target.value)}
                                options={optionsYears.map((row, index) => (
                                    <option key={'year-period-' + index} value={index}>{row}</option>
                                ))}
                            />

                            <div style={{ width: '16px' }}></div>

                            {

                                mainPeriod == 'month' || mainPeriod == 'year' ?
                                    <MySelect
                                        outerlabel={'Mês'}
                                        value={monthPeriod}
                                        onChange={(e) => handleMonthPeriod(e.target.value)}
                                        options={optionsMonths.map((row, index) => (
                                            <option key={'month-period-' + index} value={index}>{row.name}</option>
                                        ))}
                                    /> : null
                            }

                            {

                                mainPeriod == 'quarter' ?

                                    <>

                                        {
                                            optionsQuarters && optionsQuarters.length ?
                                                <MySelect
                                                    outerlabel={'Trimestre'}
                                                    value={quarterPeriod}
                                                    onChange={(e) => handleQuarterPeriod(e.target.value)}
                                                    options={optionsQuarters.map((row, index) => (
                                                        <option key={'quarter-period-' + index} value={index}>{row.name}</option>
                                                    ))}
                                                /> : <span className={'alertNoPeriod'}>{'Não há trimestre completo para o ano selecionado'}</span>
                                        }

                                    </> : null

                            }

                            {

                                mainPeriod == 'semester' ?
                                    <>

                                        {
                                            optionsSemesters && optionsSemesters.length ?
                                                <MySelect
                                                    outerlabel={'Semestre'}
                                                    value={semesterPeriod}
                                                    onChange={(e) => handleSemesterPeriod(e.target.value)}
                                                    options={optionsSemesters.map((row, index) => (
                                                        <option key={'semester-period-' + index} value={index}>{row.name}</option>
                                                    ))}
                                                /> : <span className={'alertNoPeriod'}>{'Não há semestre completo para o ano selecionado'}</span>
                                        }

                                    </> : null

                            }


                        </div>
                    </div>




                </div>

                <br />
                <div className={classes.bottom}>

                    {
                        reportMode != 'general' && isEmptyCustomReport(state) ?
                            <Typography className={classes.validationMessage}>
                                {'Selecione pelo menos 1 tipo de relatório'}
                            </Typography>
                            : null

                    }

                    <div style={{ display: "flex", alignItems: 'center', justifyContent: 'flex-end' }}>
                        {
                            mainPeriod == 'month' &&
                            provisionalReport &&
                            (
                                checkUserIsRole(currentUser, [roles.superadmin])
                                ||
                                PROVISIONAL_CLIENTS_ENABLED.includes(content?.client?.id)
                            )
                            &&
                            <UnoButton
                                style={{ marginRight: '16px' }}
                                disabled={isUnableToReport()}
                                onClick={() => doProvisionalReport()}
                                type={'success'}>
                                {'Gerar provisório [ ' + getShortMonthName(provisionalReport.month) + '/' + provisionalReport.year + ' ]'}
                            </UnoButton>
                        }
                        <UnoButton
                            disabled={isUnableToReport()}
                            onClick={() => doReport(formatConfigReport(content && content.client))}
                            type={'primary'}>
                            {'Gerar Relatório'}
                        </UnoButton>
                    </div>





                </div>

            </div>


            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                    </div> : null
            }


        </div >
    );
}



