import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { camelCase, toUpper } from 'lodash';
import moment from 'moment';
import business from 'moment-business';
import React from 'react';
import VMasker from 'vanilla-masker';
import { LOGOS_URL } from '../../API/config';
import { unoTheme } from "../../assets/styles/unoTheme";
import { getEvolutionPlOptionsChart } from '../../controllers/ChartsController';
import { getRealLastValueQuotaMonth } from '../../controllers/FundController';
import { getClientJsonQuotas } from '../../controllers/FundLocalQuotasController';
import { GENERIC_ASSET_TITLES_TYPE, isPrivateIpcaTitle } from '../../controllers/TitleController';
import { ArticleParams } from './ArticleParams';
import { checkFinalLabelDistribuitionTable, distributionOptionClassification, getLabelLiquidityDistribuition, order } from './distribution';
import { hollidays } from './hollidays';
import { roles } from './user';

export const INVALID_DATE_STRING = 'Data inválida';

const { classe, device, manager_anbima, administrator_anbima, benchmark_anbima, disponibilization } = distributionOptionClassification;

export const getVariableName = (variable) => Object.keys({ variable })[0];

export const getFilteredObject = (originalObject, newObject) => {
    let availableProps = {};
    Object.entries(originalObject).forEach((propData, propKey) => {
        let propValue = propData[1];
        Object.entries(newObject).forEach((selectedPropData, selectedPropKey) => {
            let selectedPropDataKey = selectedPropData[0];
            let selectedPropValue = selectedPropData[1];
            if (propValue === selectedPropValue) return;
            availableProps[selectedPropDataKey] = selectedPropValue;
        });
    });
    return availableProps;
}

export const createNewObjectByVariables = (variableKeys, variableValues) => {
    let newObject = {};
    variableValues.forEach((value, index) => {
        newObject[variableKeys[index]] = value;
    });
    return newObject;
};

export const pascalCase = str => camelCase(str).replace(/^(.)/, toUpper);

/**
 * 
 * @param {Array} arrayToSort 
 * @param {String} propertyLabel
 * @param {Boolean} selectedColumnDirection 
 */
export function sortArrayOrderBy(arrayToSort, propertyLabel, selectedColumnDirection) {

    let propertyLabelType = '';
    if (arrayToSort[0]) {
        propertyLabelType = typeof arrayToSort[0][propertyLabel];
    }

    let isPropertyLabelString = propertyLabelType == 'string';
    let isPropertyLabelNumber = propertyLabelType == 'number';

    switch (selectedColumnDirection) {
        case order.ASC:
            arrayToSort.sort(function (a, b) {
                if (isPropertyLabelString) {
                    if (a[propertyLabel] > b[propertyLabel]) return -1;
                    if (b[propertyLabel] > a[propertyLabel]) return 1;
                    return 0;
                } else if (isPropertyLabelNumber) {
                    return b[propertyLabel] - a[propertyLabel];
                }
            });
            break;
        case order.DESC:
            arrayToSort.sort(function (a, b) {
                if (isPropertyLabelString) {
                    if (a[propertyLabel] < b[propertyLabel]) return -1;
                    if (b[propertyLabel] < a[propertyLabel]) return 1;
                    return 0;
                } else if (isPropertyLabelNumber) {
                    return a[propertyLabel] - b[propertyLabel];
                }
            });
            break;
        default:
            break;
    }
    return arrayToSort;
};

/**
 * 
 * @param {any} event 
 * @param {Function} action 
 * @returns 
 */
export const isKeyPressedEnter = (keyCode) => {
    const isKeyPressedEnter = keyCode === 13;
    if (isKeyPressedEnter) {
        return true;
    }
    return false;
};

export function formatTextForValidDisplay(string, initialSplit, endString) {
    if (!string) {
        return '-';
    }
    let splitedString = string.split(' ');

    if (splitedString.length <= 2) return string;

    let splicedString = splitedString.slice(0, initialSplit);
    let splicedStringLength = splicedString.length;

    let toChangeString = splicedStringLength > initialSplit;
    let splicedStringLastIndex = splicedStringLength - 1;

    let splicedStringLastWord = splicedString[splicedStringLastIndex];
    let isStringInvalid = splicedStringLastWord.length <= 3;
    while (isStringInvalid) {
        splicedStringLastIndex++;
        splicedStringLength++;

        splicedString = string.split(' ').slice(0, splicedStringLength);
        splicedStringLastWord = splicedString[splicedStringLastIndex];

        isStringInvalid = splicedStringLastWord && splicedStringLastWord.length <= 3;
    }
    return splicedString.join(' ') + (toChangeString && endString ? endString : '');
}

export function formatStringToDisplay(string, numberWords, endString) {
    let splitedString = string.split(' ');
    if (splitedString.length <= numberWords) {
        if (numberWords > 3 && splitedString.some(el => el.length > 11)) {
            return splitedString.slice(0, 3).join(' ') + (endString ? endString : '');
        }
        return string;
    }
    let toReturnStringArray = splitedString.slice(0, numberWords);

    if (numberWords > 3 && toReturnStringArray.some(el => el.length > 11)) {
        return toReturnStringArray.slice(0, 3).join(' ') + (endString ? endString : '');
    }

    return toReturnStringArray.join(' ') + (endString ? endString : '');
}

export function splitIn2Lines(string, wordsLine1) {
    let splitedString = string.split(' ');
    if (splitedString.length <= wordsLine1) {
        return {
            line1: string,
            line2: "",
        };
    }

    const line1 = splitedString.slice(0, wordsLine1);
    const line2 = splitedString.slice(wordsLine1);


    return {
        line1: line1.join(' '),
        line2: formatStringToDisplay(line2.join(' '), 2, '...'),
    };
}

export function isAuthenticated() {

    return localStorage.getItem('token') != null
        && localStorage.getItem('token') != undefined
}

export function pixelsToMM(pixels) {

    return pixels / 3.7795275591;
}

export function timeStampToPtDate(t) {
    return moment.utc(t).format("DD/MM/YYYY")
}


/**
 * Pt string date to moment
 * @param {string} ptDate
 * @param {Boolean} isUtc
 * @returns {boolean} moment
 */
export function stringPtDateToMoment(ptDate, isUtc) {

    //console.log('ptDate: ', ptDate)

    let splittedDate = ptDate.split('/');

    return isUtc ?
        moment.utc(splittedDate[2] + '-' + splittedDate[1] + '-' + splittedDate[0])
        :
        moment(splittedDate[2] + '-' + splittedDate[1] + '-' + splittedDate[0]);
}

export function stringPtDateToJsDate(ptDate, isUtc) {

    let splittedDate = ptDate.split('/');

    return new Date(parseInt(splittedDate[2]), parseInt(splittedDate[1] - 1), parseInt(splittedDate[0]));
}

export function formatPtStringDate(t) {
    //2020-10-20
    t = t.toString();
    return t.substring(8, 10) + "/" + t.substring(5, 7) + "/" + t.substring(0, 4)
}

export function formatPtStringDate2(t) {
    //01/31/2020
    t = t.toString();
    let splittedDate = t.split('/');
    return splittedDate[1] + "/" + splittedDate[0] + "/" + splittedDate[2]
}

export function formatPtStringDateToAnbimaDate(t) {
    //01/31/2020
    t = t.toString();
    let splittedDate = t.split('/');
    return splittedDate[2] + "-" + splittedDate[1] + "-" + splittedDate[0]
}

export function formatAnbimaDateToPt(t) {
    //01/31/2020
    t = t.toString();
    let splittedDate = t.split('-');
    return splittedDate[2] + "/" + splittedDate[1] + "/" + splittedDate[0]
}

export function getFirstDateDayMonth(month, year) {
    const date = moment.utc(new Date(year, parseInt(month) - 1, 1));
    if (date.isValid()) {

        return date;

    } else {

        return INVALID_DATE_STRING;
    }
}

export function getLastPtDayOfYear(year) {
    return moment.utc(new Date(year, 11, 31)).format('DD/MM/YYYY');
}

export function getFirstPtDayOfYear(year) {
    return moment.utc(new Date(year, 0, 1)).format('DD/MM/YYYY');
}

export function getFirstPtDayMonth(month, year) {
    const date = moment.utc(new Date(year, parseInt(month) - 1, 1));
    if (date.isValid()) {
        return moment.utc(new Date(year, parseInt(month) - 1, 1)).format('DD/MM/YYYY');

    } else {

        return 'Data inválida'
    }
}
export function getLastPtDayMonth(month, year) {
    return moment.utc(new Date(year, parseInt(month), 0)).format('DD/MM/YYYY');
}

export function getFirstDayMonth(month, year, isPureDate) {
    const date = moment.utc(new Date(year, parseInt(month) - 1, 1));
    if (date.isValid()) {

        if (isPureDate) {
            return date
        }
        return moment.utc(new Date(year, parseInt(month) - 1, 1)).format('DD/MM/YYYY');

    } else {

        return 'Data inválida'
    }
}

export function getLastMomentDayMonth(month, year) {
    return moment.utc(new Date(year, parseInt(month), 0));
}

export function getLastDayMonth(month, year) {
    return new Date(year, parseInt(month), 0);
}

export function getLastDayMonthAgo(month, year) {

    month--;
    if (month === 0) {
        month = 12;
        year--;
    }
    return new Date(year, parseInt(month), 0);
}


//Verificar se o asset e seus possíveis subassets estão com o mesmo balance
export function checkAssetsAndSubAssetsSameBalances(asset) {

    let check = true;
    if (asset.subAssets) {

        asset.subAssets.forEach(subAsset => {

            // console.log("Comaprando:");
            // console.log("1: ", subAsset.pl);
            // console.log("2: ", subAsset.balance_now);
            if (!checkIsSameBalance(subAsset.pl, subAsset.balance_now)) {
                check = false;
            }

        });

    } else {

        if (!checkIsSameBalance(asset.pl, asset.balance_now)) {

            check = false;

        }

    }

    // if (asset.cnpj == "11.328.882/0001-35") {
    //     console.log("check balance: ", check);
    // }

    return check;

}

//Verificar se o asset e seus possíveis subassets estão com a mesma quantidade final de cotas
export function checkAssetsAndSubAssetsSameFinalQuotas(asset) {


    let check = true;
    if (asset.subAssets) {

        asset.subAssets.forEach(subAsset => {



            if (!checkIsSameQuotas(subAsset.quota_amount_now, subAsset.qtd_quotas_sensibilized_final)) {
                check = false;
            }

        });

    } else {

        if (!checkIsSameQuotas(asset.quota_amount_now, asset.qtd_quotas_sensibilized_final)) {

            check = false;

        }

    }


    return check;

}

//Verificar se o asset e seus possíveis subassets estão com a mesma quantidade final de cotas
export function checkAssetsAndSubAssetsEndFundoZeroBalance(asset, firstDayMonthDetail, endDayMonthDetail) {


    let check = true;
    if (asset.subAssets) {

        asset.subAssets.forEach(subAsset => {


            const { uno_date_quota_end } = subAsset;
            if (uno_date_quota_end != null
                && moment.utc(uno_date_quota_end).isBetween(firstDayMonthDetail, endDayMonthDetail)
                && (subAsset.balance_now > 0 || subAsset.quota_amount_now > 0)) {

                check = false;
            }

        });

    } else {

        const { uno_date_quota_end } = asset;
        if (uno_date_quota_end != null
            && moment.utc(uno_date_quota_end).isBetween(firstDayMonthDetail, endDayMonthDetail)
            && (asset.balance_now > 0 || asset.quota_amount_now > 0)) {

            check = false;
        }

    }


    return check;

}

export function truncDecimalString(string, truncDecimal) {


    if (string == null) {
        return "";
    }

    string = string.toString();
    let indexDecimal = null;

    if (string.indexOf(",") != -1) {

        indexDecimal = string.indexOf(",") + 1

    } else {

        if (string.indexOf(".") != -1) {

            indexDecimal = string.indexOf(".") + 1

        } else {

            indexDecimal = string.length

        }

    }
    //console.log("Index decimal: ", indexDecimal);

    return string.substring(0, indexDecimal + truncDecimal)
}

export function checkIsSameQuotas(realQuotas, validatedQuotas) {

    if (realQuotas < 0) {
        realQuotas = realQuotas * (-1);
    }

    if (validatedQuotas < 0) {
        validatedQuotas = validatedQuotas * (-1);
    }

    // console.log("IS SAME: ", applyMaskAmountFreeDecimals(realQuotas, false, 2).toString().trim() == applyMaskAmountFreeDecimals(validatedQuotas, false, 2).toString().trim());
    // console.log("IS SAME: ", realQuotas.toString().trim() == validatedQuotas.toString().trim());

    // console.log("IS SAME: ", truncDecimalString(realQuotas, 2) == truncDecimalString(validatedQuotas, 2));

    // console.log("1: ", realQuotas);
    // console.log("2: ", validatedQuotas);

    // console.log("1 trunc: ", truncDecimalString(realQuotas, 2));
    // console.log("2 trunc: ", truncDecimalString(validatedQuotas, 2));

    return truncDecimalString(realQuotas, 2) == truncDecimalString(validatedQuotas, 2);
}

export function checkIsSameBalance(realBalance, validatedBalance) {

    let maxDif = 0.01;

    if (validatedBalance == null || realBalance == null) {
        return false;
    }

    let lengthValidatedBalance = validatedBalance.toString().length;

    // console.log("Comparando: ", realBalance.toString().substring(0, lengthValidatedBalance));
    // console.log("Comparando: ", validatedBalance.toString());
    let floatRealBalance = parseFloat(realBalance);
    let floatValidatedBalance = parseFloat(validatedBalance);

    //A diferença pode ser de até 0.01 (1 centavo)
    let dif = floatRealBalance - floatValidatedBalance;
    // console.log("///////")
    // console.log('1: ', floatRealBalance)
    // console.log('2: ', floatValidatedBalance)
    if (dif < 0) {
        dif *= (-1);
    }

    //console.log("DIF: ", dif);

    return realBalance.toString().substring(0, lengthValidatedBalance) === validatedBalance.toString()
        || (dif < maxDif);
}

//Trazer o mês anterior a partir da data selecionada na carteira e o número de meses anteriores
export function getMonthAgo(month, year, monthsAgo) {

    // console.log("MONTH: ", month);
    // console.log("YEAR: ", year);
    // console.log("ago: ", monthsAgo);
    let dateAgo = moment.utc(new Date(year, parseInt(month), 1)).subtract(parseInt(monthsAgo), 'months').format('DD/MM/YYYY');

    return dateAgo;
}

export function getDayMonthYearByDate(date) {

    const d = moment.utc(date).format('DD/MM/YYYY');
    const splitted = d.split('/');
    const day = splitted[0];
    const month = splitted[1];
    const year = splitted[2];

    return { day, month, year };

}

export function downloadFile(content, fileName, contentType) {
    const a = document.createElement("a");
    const file = new Blob([JSON.stringify(content)], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
}

export function getTheLastDayCompleteMonthPt() {

    //Verificando se o dia atual é o último dia do mês
    let test = new Date();
    let month = test.getMonth();

    test.setDate(test.getDate() + 1);
    if (test.getMonth() !== month) {
        return moment(new Date()).format('DD/MM/YYYY');
    } else {
        return moment(
            new Date(parseInt(moment(new Date()).format('YYYY')), parseInt(moment(new Date()).format('MM')) - 1, 0)).format('DD/MM/YYYY');
    }
}

//Verificar se o mês já acabou
export function isMonthEnded(date) {

    let splitedDate = date.split('/');
    let endDate = new Date(splitedDate[2], splitedDate[1], splitedDate[0], 0, 0, 0, 0);
    let today = new Date();

    console.log("END DATE: ", endDate);
    console.log("TODAY: ", today);

    if (today.getTime() > endDate.getTime()) {
        return true;
    }

    return false;
}

export function getBusinessDaysInMonth(month, year) {

    let hollidaysAnbima = hollidays(year);

    let dayToCompare = moment.utc(new Date(year, parseInt(month) - 1, 1));

    let firstRoll = true;

    const daysInMonth = [];

    //console.log('dayToCompare: ', dayToCompare);
    //console.log('dayToCompare.month(): ', dayToCompare.month());
    while ((dayToCompare.month() + 1) === month) {
        //Na primeira tentativa deve se comparar o primeiro dia do mês, nas demais vai se acrescentando 1 dia
        if (!firstRoll) {
            dayToCompare.add(1, 'days');
        }

        firstRoll = false;

        if ((dayToCompare.month() + 1) !== month) {
            break;
        }

        let formattedDay = dayToCompare.format('DD/MM/YYYY');

        //console.log("Testando: ", formattedDay);

        if (!hollidaysAnbima.includes(formattedDay)
            && business.isWeekDay(dayToCompare)) {

            daysInMonth.push(dayToCompare.format('DD/MM/YYYY'));

        }
    }

    return daysInMonth;

}

//Retorno o dia útil a partir do mês, ano e o número de dias úteis demandados
export function getBusinessDay(countBusinessDays, month, year) {


    let hollidaysAnbima = hollidays(year);

    let countBusinessDaysInMonth = 0;
    let firstRoll = true;

    //console.log("MONTH: ", month);
    let dayToCompare = moment(new Date(parseInt(year), parseInt(month) - 1, 1));
    //console.log("First day to compare: ", dayToCompare);

    if (dayToCompare.isValid()) {

        while (countBusinessDaysInMonth < countBusinessDays) {

            //Na primeira tentativa deve se comparar o primeiro dia do mês, nas demais vai se acrescentando 1 dia
            if (!firstRoll) {
                dayToCompare.add(1, 'days');
            }

            firstRoll = false;

            let formattedDay = dayToCompare.format('DD/MM/YYYY');

            //console.log("Testando: ", formattedDay);

            if (!hollidaysAnbima.includes(formattedDay)
                && business.isWeekDay(dayToCompare)) {

                //console.log("É BUSINESS DAY!!!")
                countBusinessDaysInMonth++;

            } else {
                //console.log("NÃO É!!!")
            }

        }

    }

    return dayToCompare;
}

export function getFirstBeforeBusinessDay(referenceDate) {

    let pieces = referenceDate.split('/');
    let day = pieces[0];
    let month = pieces[1];
    let year = pieces[2];

    let hollidaysAnbima = hollidays(year);

    let firstRoll = true;

    console.log("MONTH: ", month);
    let dayToCompare = moment.utc(new Date(parseInt(year), parseInt(month) - 1, parseInt(day)));
    console.log("First day to compare: ", dayToCompare);

    let founded = null;
    if (dayToCompare.isValid()) {

        while (!founded && dayToCompare.format("MM") == month) {

            //Na primeira tentativa deve se comparar o primeiro dia do mês, nas demais vai se acrescentando 1 dia
            if (!firstRoll) {
                dayToCompare.subtract(1, 'days');
            }

            firstRoll = false;

            let formattedDay = dayToCompare.format('DD/MM/YYYY');

            console.log("Testando: ", formattedDay);

            if (!hollidaysAnbima.includes(formattedDay)
                && business.isWeekDay(dayToCompare)) {

                founded = formattedDay;

            }

        }

    }

    return founded;
}

//Verificar se o mês já está completo de cotas
//Convenciona-se a libera-lo a partir do quinto dia útil do mês seguinte
export function isMonthAbleToQuotas(month, year) {

    // console.log("MONTH: ", month);
    // console.log("YEAR: ", year);
    let fiveBusinessDaysNextMonth = getBusinessDay(5, month + 1, year);

    // console.log("DAY TO COMPARE AFTER 5 BUSINESS DAYS: ", fiveBusinessDaysNextMonth);
    // console.log("DAY TO COMPARE AFTER 5 BUSINESS DAYS: ", fiveBusinessDaysNextMonth.format('DD/MM/YYYY'));

    return moment(new Date()).isSameOrAfter(fiveBusinessDaysNextMonth);
}

//Retorna o próximo dia a liberar cotas a partir do mês e do ano
export function getNextDayAbleToQuotas(month, year) {

    const fiveBusinessDaysNextMonth = getBusinessDay(5, month + 1, year);
    return fiveBusinessDaysNextMonth;
}

export function getLastDateAbleToQuotas() {
    let referenceDate = getTheLastDayCompleteMonthPt();

    console.log("LAST DAY COMPLETE MONTH: ", referenceDate);

    const splittedreferenceDate = referenceDate.split('/');
    const m = parseInt(splittedreferenceDate[1]);
    const y = parseInt(splittedreferenceDate[2]);
    if (!isMonthAbleToQuotas(m, parseInt(y))) {
        //referenceDate = getMonthAgo(parseInt(splittedreferenceDate[1]), parseInt(splittedreferenceDate[2]), 1);
        if (m == 1) {
            referenceDate = moment(new Date(y - 1, 12, 0)).format('DD/MM/YYYY');
        } else {
            referenceDate = moment(new Date(y, m - 1, 0)).format('DD/MM/YYYY');
        }
    }

    return referenceDate;
}

export function getLastsMonthsInArray(referenceDate, numberMonths) {

    console.log("REFERENCE DATE: ", referenceDate);
    let dateAgo = getDayMonthsAgo(referenceDate, numberMonths + 1) //+1 para pegar o mês anterior
    console.log('dateAgo: ', dateAgo);

    let agoStringMonthYear = dateAgo.substring(3);

    console.log("DATE AGO: ", agoStringMonthYear);

    let listMonths = [];
    let countLoop = 0;
    while (countLoop < (numberMonths)) {

        let pieces = agoStringMonthYear.split("/");

        pieces[0] = parseInt(pieces[0]);

        listMonths.push(getShortMonthName(pieces[0]) + "/" + pieces[1].substring(2));

        pieces[0] += 1;

        if (pieces[0] == 13) {

            pieces[0] = 1;
            pieces[1] = parseInt(pieces[1]) + 1;

        }

        agoStringMonthYear = (pieces[0]).toString().padStart(2, "0") + "/" + pieces[1];

        countLoop++;
    }

    console.log("MONTHS LASTS: ", listMonths);

    return listMonths;

}

export function getDayMonthsAgo(day, monthsAgo) {

    console.log("DAY: ", day);
    console.log("ago: ", monthsAgo);

    let splitted = day.split('/');
    let dateAgo = moment.utc(new Date(splitted[2], parseInt(splitted[1]), splitted[0])).subtract(parseInt(monthsAgo), 'months').format('DD/MM/YYYY');
    //console.log(dateAgo);
    return dateAgo;
}


//Para o caso específico em que se pede as series do mês anterior, tendo que trazer do mês anterior ao anterior e por acaso existir cota pro último dia do mês
//O que faria existir um mês extra na lista que sempre deve conter 37 itens
export function getDayMonthsAgoWithOneDayMore(day, monthsAgo) {

    console.log("DAY: ", day);
    console.log("ago: ", monthsAgo);

    let splitted = day.split('/');
    let dateAgo = moment.utc(new Date(splitted[2], parseInt(splitted[1]), splitted[0])).subtract(parseInt(monthsAgo), 'months').add(1, 'days').format('DD/MM/YYYY');
    //console.log(dateAgo);
    return dateAgo;
}

export function getFirstDayYear(year) {

    let dateFirstInYear = moment.utc(new Date(year, 0, 1)).format('DD/MM/YYYY');
    //console.log(dateAgo);
    return dateFirstInYear;
}

//Se a data vier em formato timestamp (portfolio_init) faz-se 
//uma conversão diferente do caso em que ela vem no formato char(7) (portfolio_closed) Ex: 08/2019
export function formatClosedMonth(d) {

    if (d.toString().length == 7) { //Portfolio_closed
        let sSplitted = d.toString().split('/')
        return {
            month: sSplitted[0],
            year: sSplitted[1]
        }
    } else { //Portfolio_init
        let formatted = moment.utc(d).add(1, 'M');
        formatted = formatted.format("DD/MM/YYYY")
        let sSplitted = formatted.toString().split('/')
        return {
            month: sSplitted[1],
            year: sSplitted[2]
        }
    }
}

export function getMonthAndYearPortfolioClosed(d) {
    if (!d) {
        return null;
    }
    const sSplitted = d.toString().split('/')
    return {
        month: parseInt(sSplitted[0]),
        year: parseInt(sSplitted[1])
    }
}

/**
 * isValidEmail helper method
 * @param {string} email
 * @returns {Boolean} True or False
 */
export function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
};

export function formatJsonLabel(jsonLabel) {
    jsonLabel = jsonLabel.replace(/_/g, ' ');
    return jsonLabel;
};


/**
 * isValidCep helper method
 * @param {string} cep
 * @returns {Boolean} True or False
 */
export function isValidCep(cep) {

    cep = cep.replace(/[^\d]+/g, '');
    return /^[0-9]{8}$/.test(cep);
};

/**
 * Validação CPF
 * @param {string} strCPF
 * @returns {boolean} true/false
 */
export function isValidCpf(strCPF) {

    if (!strCPF) return false
    let Soma;
    let Resto;

    strCPF = strCPF.replace(/[^\d]+/g, '');

    Soma = 0;
    if (strCPF == "00000000000") return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;

    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
};

/**
 * JustNumbers
 * @param {string} str
 * @returns {string} apenas os números da string
 */
export function justNumbers(str) {

    str = str.toString();
    return str.replace(/[^\d]+/g, '');
};

/**
 * @param {string} str
 * @returns {string} string sem caracteres especiais
 */
export function removeEspecialChars(stringToReplace) {

    const desired = stringToReplace.replace(/[^\w\s]/gi, '')
    return desired;
};

export function hasOnlyNumbers(str) {
    let reg = new RegExp(/^\d+$/);
    let hasNumbersOnly = reg.test(str);
    return hasNumbersOnly ? true : false;
}

/**
 * Lista de meses a partir do dia informado até o mês atual
 * @param {string} str
 * @returns {string} Lista de meses a partir do dia informado até o mês atual
 */
export function getMonthsUntilNow(day) {

    let monthDay = moment.utc(day).format("DD/MM/YYYY");
    let splitted = monthDay.toString().split("/");
    let monthInt = parseInt(splitted[1] + 1); //Soma-se 1 pois começa no mês seguinte ao inicio da carteira
    let yearInt = parseInt(splitted[2])

    let today = moment.utc(new Date()).format("DD/MM/YYYY");
    let splittedToday = today.toString().split("/");
    let monthAfter = parseInt(splittedToday[1]);
    let yearAfter = parseInt(splittedToday[2]);

    let monthsUntilNow = [];
    for (let i = yearInt; i <= yearAfter; i++) {

        if (i == yearInt) {
            for (let j = monthInt; j <= 12; j++) {
                monthsUntilNow.push({
                    month: j,
                    year: i,
                })
            }
        } else if (i == yearAfter) {
            for (let j = 1; j <= monthAfter; j++) {
                monthsUntilNow.push({
                    month: j,
                    year: i,
                })
            }
        } else {
            for (let j = 1; j <= 12; j++) {
                monthsUntilNow.push({
                    month: j,
                    year: i,
                })
            }
        }


    }

    return monthsUntilNow;
};

/**
 * Lista de anos a partir do ano informado
 * @param {string} year
 * @param {int} extraYears número de anos extras
 * @returns {string} Lista de anos a partir do ano informado até o ano atual
 */
export function getYearsByYearUntilNow(year, extraYears) {

    let yearInt = parseInt(year);
    let todayYearInt = parseInt(moment.utc(new Date()).format("YYYY"));

    let years = [];
    for (let i = yearInt; i <= todayYearInt; i++) {
        years.push(i);
    }

    if (extraYears && years.length) {

        for (let index = 1; index <= extraYears; index++) {
            years.push(years[years.length - 1] + 1);
        }
    }

    return years;
};

/**
 * Lista de anos a partir do dia informado até o ano atual
 * @param {string} str
 * @returns {string} Lista de anos a partir do dia informado até o ano atual
 */
export function getYearsByDateUntilNow(day) {

    //let yearInt = parseInt(moment.utc(day).add(1, 'M').format("YYYY"));
    let yearInt = parseInt(moment.utc(day).format("YYYY"));
    let todayYearInt = parseInt(moment.utc(new Date()).format("YYYY"));

    let years = [];
    for (let i = yearInt; i <= todayYearInt; i++) {
        years.push(i);
    }

    return years;
};



export function getMonthsUntilPortfolioInit(portfolioInit, portfolioClosed, includeFirstMonth) {

    console.log('portfolioInit: ', portfolioInit);
    console.log('portfolioClosed: ', portfolioClosed);

    if (!portfolioClosed) {
        return {};
    }

    const mapYearsMonths = {};
    const years = [];
    let iterateDate = moment.utc(portfolioInit);
    const limitDate = moment.utc(portfolioClosedToDate(portfolioClosed))

    //Retirando o primeiro mês que equivale ao mês anterior ao início das operações
    if (!includeFirstMonth) {
        iterateDate.add(1, 'months');
    }

    if ((iterateDate.month() === limitDate.month()
        && iterateDate.year() === limitDate.year())) {

        if (!mapYearsMonths[iterateDate.year()]) {
            mapYearsMonths[iterateDate.year()] = [];
            years.push(iterateDate.year());
        }
        mapYearsMonths[iterateDate.year()].push(iterateDate.month() + 1);

        return {
            months: mapYearsMonths,
            years: years,
        };
    }

    let secureCount = 0;
    while (1) {

        secureCount++;

        if (!mapYearsMonths[iterateDate.year()]) {
            mapYearsMonths[iterateDate.year()] = [];
            years.push(iterateDate.year());
        }
        mapYearsMonths[iterateDate.year()].push(iterateDate.month() + 1);
        iterateDate.add(1, 'months');

        // console.log('iterateDate: ', iterateDate.format('DD/MM/YYYY'));
        // console.log('limitDate: ', limitDate.format('DD/MM/YYYY'));

        if ((iterateDate.month() === limitDate.month()
            && iterateDate.year() === limitDate.year()) || secureCount === 1000) {

            if (!mapYearsMonths[iterateDate.year()]) {
                mapYearsMonths[iterateDate.year()] = [];
                years.push(iterateDate.year());
            }
            mapYearsMonths[iterateDate.year()].push(iterateDate.month() + 1);
            break;
        }
    }

    console.log('mapYearsMonths: ', mapYearsMonths);

    return {
        months: mapYearsMonths,
        years: years,
    };
}

/**
 * Lista dos meses até a data informada (início da carteira)
 * @param {string} str
 * @returns {string} Lista dos meses até a data informada (início da carteira)
 */
export function getMonthsUntilPortfolioInit2(portfolio_init, portfolio_closed) {

    let monthDay = moment.utc(portfolio_init).format("DD/MM/YYYY");
    let splitted = monthDay.toString().split("/");
    let monthInt = parseInt(parseInt(splitted[1]) + 1); //Soma-se 1 pois começa no mês seguinte ao inicio da carteira
    let yearInt = parseInt(splitted[2])

    //Correção para caso monthInt ser o mês 12
    if (monthInt == 13) {
        monthInt = 1;
        yearInt++;
    }

    let today = portfolio_closed;
    let splittedToday = today.toString().split("/");
    let monthAfter = parseInt(splittedToday[0]);
    let yearAfter = parseInt(splittedToday[1]);

    let monthsUntilNow = [];

    console.log("MONTH INT: ", monthInt);
    console.log("MONTH AFTER: ", monthAfter);
    console.log("YEAR INT: ", yearInt);
    console.log("YEAR AFTER: ", yearAfter);

    for (let i = yearInt; i <= yearAfter; i++) {

        if (i == yearInt || i < yearAfter) {

            if (yearInt == yearAfter) {
                //No caso
                for (let j = monthInt; j <= monthAfter; j++) {
                    monthsUntilNow.push({
                        month: j,
                        year: i,
                    })
                }
            } else if (yearInt < yearAfter) {
                for (let j = monthInt; j <= 12; j++) {
                    monthsUntilNow.push({
                        month: j,
                        year: i,
                    })
                }
            }

        } else if (i == yearAfter) {

            for (let j = 1; j <= monthAfter; j++) {
                monthsUntilNow.push({
                    month: j,
                    year: i,
                })
            }
        }

        // if (i == yearInt) {
        //     //for (let j = monthInt; j <= 12; j++) {
        //     if (yearInt < yearAfter) {
        //         for (let j = monthInt; j <= 12; j++) {
        //             monthsUntilNow.push({
        //                 month: j,
        //                 year: i,
        //             })
        //         }
        //     } else {
        //         for (let j = monthInt; j <= monthAfter; j++) {
        //             monthsUntilNow.push({
        //                 month: j,
        //                 year: i,
        //             })
        //         }
        //     }

        // } else if (i == yearAfter) {
        //     for (let j = 1; j <= monthAfter; j++) {
        //         monthsUntilNow.push({
        //             month: j,
        //             year: i,
        //         })
        //     }
        // } else {
        //     for (let j = 1; j <= 12; j++) {
        //         monthsUntilNow.push({
        //             month: j,
        //             year: i,
        //         })
        //     }
        // }


    }

    console.log("MONTHS AEOH: ", monthsUntilNow)

    let yearsWithMonths = {}
    let years = [];
    monthsUntilNow.forEach(element => {
        let m = element.month.toString();
        let y = element.year.toString();

        if (!yearsWithMonths[y]) {
            yearsWithMonths[y] = [];
            years.push(parseInt(y));
        }

        if (!yearsWithMonths[y].includes(m)) {
            yearsWithMonths[y].push(parseInt(m));
            yearsWithMonths[y].sort(function sortfunction(a, b) {
                return (a - b)
            });
        }
    });

    years.sort(function sortfunction(a, b) {
        return (a - b)
    });

    console.log("======================");
    console.log("YEARS WITH MONTHS: ", yearsWithMonths);
    console.log("YEARS: ", years);
    console.log("======================");

    return {
        months: yearsWithMonths,
        years: years,
    };

};

/**
 * Formatar a data de fechamento da carteria do cliente
 * @param {string} str
 * @returns {string} 
 */
export function formatPortfolioClosed(dateString) {

    let splitted = dateString.split('/');
    if (splitted.length == 1) {
        return dateString;
    }
    return getMonthName(parseInt(splitted[0])) + " / " + splitted[1]
};

/**
 * isDateBeforeInit
 * @param {string} transactionDate
 * @param {string} portfolioInit
 * @returns {boolean} se é ou não permitido
 */
export function isDateBeforeInit(transactionDate, portfolioInit) {

    //Caso seja no mÊs de início da carteira também não é permitia a criação de movimentação
    if (moment.utc(portfolioInit).format("MM/YYYY") == transactionDate.substring(3)) {
        return true;
    }

    let pieces = transactionDate.split('/')

    let tDate = new Date(parseInt(pieces[2]), parseInt(pieces[1]) - 1, parseInt(pieces[0]));
    //console.log("t date: ", tDate);
    //console.log("port date: ", portfolioInit);

    let toReturn = moment(tDate).isBefore(portfolioInit);

    //console.log("TO RETURN IS BEFORE: ", toReturn);

    return toReturn;

};

export function getStartDateAndEndDateByMonthYear(month, year) {
    const startDate = moment.utc(new Date(year, parseInt(month) - 1, 1)).format('DD/MM/YYYY');
    const endDate = moment.utc(new Date(year, parseInt(month), 0)).format('DD/MM/YYYY');

    return { startDate, endDate }
}

/**
 * isDateAfterClosedMonth
 * @param {string} transaction_date
 * @param {string} portfolio_closed
 * @param {string} oneMoreMonth //indica se deve ser adicionado um mês a mais no portfolio month devido o ativo poder
 * ser criado com inicio para o último mês que a carteira foi fechada
 * @returns {boolean} se é ou não permitido
 */
export function isDateAfterClosedMonth(date, portfolio_closed, oneMoreMonth) {

    if (!portfolio_closed) {
        return true
    }

    // console.log("T DATE: ", date)
    // console.log("CLOSED: ", portfolio_closed)
    let splittedTDate = date.split('/');
    let splittedPortClosed = portfolio_closed.split('/');

    let month = parseInt(splittedTDate[1])
    let monthClosed = parseInt(splittedPortClosed[0])

    let year = parseInt(splittedTDate[2])
    let yearClosed = parseInt(splittedPortClosed[1])

    // console.log(month);
    // console.log(monthClosed);
    // console.log(year);
    // console.log(yearClosed);

    if (oneMoreMonth) {
        month++;
        if (month == 13) {
            month = 1;
            year++;
        }
    }

    if (year < yearClosed) {
        return false
    }

    if (year > yearClosed) {
        return true
    }

    if (year == yearClosed
        && month > monthClosed) {
        return true
    } else {
        return false
    }

};

/**
 * parseFloatByPtCurrency
 * @param {string} str
 * @returns {string} float a partir de string currency PT
 */
export function parseFloatByPtCurrency(str) {

    str = str.replace(/\./g, '')
    str = str.replace(/,/g, '.')
    str = parseFloat(str);
    return str;
};

/**
 * parseCurrencyByPtFloat
 * @param {string} str
 * @returns {string} float a partir de string currency PT
 */
export function parseCurrencyByFloat(str) {

    str = str.toString();
    console.log("STR: ", str);
    str = str.replace(/\./g, ',')
    return str;
};

/**
 * Gnerate Token
 * @param {string} cnpj
 * @returns {boolean} true/false
 */
export function isValidCnpj(cnpj) {

    if (!cnpj) return false

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
        return false;

    return true;
}

/**
 * applyCpfMask
 * @param {string} str
 * @returns {string} cpfmasked
 */
export function applyMaskCpf(value) {

    let resultValue = "CPF mal formado";
    if (value.length == 11) {
        resultValue = "";
        resultValue = value.substring(0, 3);
        resultValue += ".";
        resultValue += value.substring(3, 6);
        resultValue += ".";
        resultValue += value.substring(6, 9);
        resultValue += "-";
        resultValue += value.substring(9, 11);
    }

    return resultValue;
};

/**
 * applyCpfMask
 * @param {string} str
 * @returns {string} cpfmasked
 */
export function applyMaskHideCpf(value) {

    let resultValue = "CPF mal formado";
    let numbersCpf = justNumbers(value).padStart(11, '0');
    if (justNumbers(value).length == 11) {
        resultValue = "";
        resultValue = "***";
        resultValue += ".";
        resultValue += numbersCpf.substring(3, 6)
        resultValue += ".";
        resultValue += numbersCpf.substring(6, 9)
        resultValue += "-";
        resultValue += "**"
    }

    return resultValue;
};

/**
 * applyCnpjMask
 * @param {string} str
 * @returns {string} cpfmasked
 */
export function applyMaskCnpj(value) {

    let resultValue = "CNPJ mal formado";
    if (value.length == 14) {
        resultValue = "";
        resultValue = value.substring(0, 2);
        resultValue += ".";
        resultValue += value.substring(2, 5);
        resultValue += ".";
        resultValue += value.substring(5, 8);
        resultValue += "/";
        resultValue += value.substring(8, 12);
        resultValue += "-";
        resultValue += value.substring(12);
    }

    return resultValue;
};

/**
 * applyAccountMask
 * @param {string} str
 * @returns {string} accountmasked
 */
export function applyMaskAccount(value) {

    let resultValue = "";
    resultValue = value.substring(0, value.length - 1) + "-" + value.substring(value.length - 1)

    return resultValue;
};

/**
 * applyAmountMask
 * @param {string} value
 * @param {boolean} withCifra
 * @returns {string} amountmask
 */
export function applyMaskAmount(value, withCifra, isRentValue) {

    value = parseFloat(value);
    if (value > 10000000 && isRentValue) {

        value = value.toExponential(2);

        return value.replace('.', ',') + '';
    }

    value = value.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    if (value == '0') {
        value = '0,00'
    }

    return withCifra ? "R$ " + value : value;
};

export function parseFloatComdinheiro(value) {

    if (value == undefined) {
        return 0;
    }

    return parseFloat(value.toString().replace(',', '.'));
}

export function formatMillionNumber(number) {

    //Checar se o número é maior que 1 milhão
    let finalNumber = null;
    if (number > 1000000) {
        finalNumber = number / 1000000;
    } else {
        finalNumber = number;
    }

    return applyMaskAmountFreeDecimals(finalNumber, false, 0);

}

export function applyMaskAmountComdinheiro(value, withCifra) {

    if (value == null || value == "-") {
        return "-";
    }

    value = value.toString();
    value = value.replace(',', '.');
    value = parseFloat(value);
    value = value.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    //console.log("VALUE 2: ", value)

    if (value == '0') {
        value = '0,00'
    }

    return withCifra ? "R$ " + value : value;
};

export function applyMaskAmountComdinheiroFreeDecimals(value, withCifra, maxDecimals) {

    if (value == null || value == "-") {
        return "-";
    }

    value = value.toString();
    value = value.replace(',', '.');

    value = parseFloat(value);
    value = value.toLocaleString('pt-BR', {
        //maximumFractionDigits: 20,
        maximumFractionDigits: maxDecimals ? maxDecimals : 20,
        minimumFractionDigits: maxDecimals < 2 ? maxDecimals : 2
    });

    if (value == '0') {
        value = '0,00'
    }

    return withCifra ? "R$ " + value : value;
};

/**
 * applyMaskAmountFreeDecimals
 * @param {string} value
 * @param {boolean} withCifra
 * @returns {string}
 */
export function applyMaskAmountFreeDecimals(value, withCifra, maxDecimals) {

    if (value == null || value == undefined) {
        return '-';
    }

    value = parseFloat(value);
    value = value.toLocaleString('pt-BR', {
        //maximumFractionDigits: 20,
        maximumFractionDigits: maxDecimals ? maxDecimals : 5,
        minimumFractionDigits: maxDecimals < 2 ? maxDecimals : 2
    });

    return withCifra ? "R$ " + value : value;
};

/**
 * applyMaskPhone
 * @param {string} str
 * @returns {string} phonemasked
 */
export function applyMaskPhone(value) {

    let resultValue = "";
    if (value.length == 10) {

        resultValue += "(";
        resultValue += value.substring(0, 2);
        resultValue += ") " + value.substring(2, 6);
        resultValue += "-" + value.substring(6);

    } else if (value.length == 11) {
        resultValue += "(";
        resultValue += value.substring(0, 2);
        resultValue += ") " + value.substring(2, 3) + " ";
        resultValue += value.substring(3, 7);
        resultValue += "-" + value.substring(7);
    }

    return resultValue;
};

/**
 * month with names list
 * @param {Array} months
 * @returns {Array} months with names
 */
export function getArrayMonthsWithNames(months) {

    const ms = [
        {
            id: 1,
            name: 'Janeiro',
        },
        {
            id: 2,
            name: 'Fevereiro',
        },
        {
            id: 3,
            name: 'Março',
        },
        {
            id: 4,
            name: 'Abril',
        },
        {
            id: 5,
            name: 'Maio',
        },
        {
            id: 6,
            name: 'Junho',
        },
        {
            id: 7,
            name: 'Julho',
        },
        {
            id: 8,
            name: 'Agosto',
        },
        {
            id: 9,
            name: 'Setembro',
        },
        {
            id: 10,
            name: 'Outubro',
        },
        {
            id: 11,
            name: 'Novembro',
        },
        {
            id: 12,
            name: 'Dezembro',
        }

    ]

    return ms;
};

export function applyCurrencyMask(value) {

    value = value.toString()
    value = value.replace(/[^0-9]+/g, "");
    return VMasker.toMoney(value);
}

export function getMonthsByArray(arrayMonths) {

    let returnMonths = [];

    const ms = [
        {
            id: 1,
            name: 'Janeiro',
        },
        {
            id: 2,
            name: 'Fevereiro',
        },
        {
            id: 3,
            name: 'Março',
        },
        {
            id: 4,
            name: 'Abril',
        },
        {
            id: 5,
            name: 'Maio',
        },
        {
            id: 6,
            name: 'Junho',
        },
        {
            id: 7,
            name: 'Julho',
        },
        {
            id: 8,
            name: 'Agosto',
        },
        {
            id: 9,
            name: 'Setembro',
        },
        {
            id: 10,
            name: 'Outubro',
        },
        {
            id: 11,
            name: 'Novembro',
        },
        {
            id: 12,
            name: 'Dezembro',
        }

    ]

    if (arrayMonths) {
        ms.forEach(element => {
            if (arrayMonths.includes(element.id)) {
                returnMonths.push(element);
            }
        });
    }

    return returnMonths;
}


const LANBEL_TRIMESTRE_1 = '1º Trimestre (Jan - Mar)';
const LANBEL_TRIMESTRE_2 = '2º Trimestre (Abr - Jun)';
const LANBEL_TRIMESTRE_3 = '3º Trimestre (Jul - Set)';
const LANBEL_TRIMESTRE_4 = '4º Trimestre (Out - Dez)';

export function getQuartersByMonthsArray(arrayMonths) {

    console.log("MONTHS TO QUARTERS: ", arrayMonths);

    const returnQuarters = [];

    if (arrayMonths.includes(1)
        && arrayMonths.includes(2)
        && arrayMonths.includes(3)) {

        returnQuarters.push({ startMonth: 1, endMonth: 3, name: LANBEL_TRIMESTRE_1 })

    }

    if (arrayMonths.includes(4)
        && arrayMonths.includes(5)
        && arrayMonths.includes(6)) {

        returnQuarters.push({ startMonth: 4, endMonth: 6, name: LANBEL_TRIMESTRE_2 })

    }

    if (arrayMonths.includes(7)
        && arrayMonths.includes(8)
        && arrayMonths.includes(9)) {

        returnQuarters.push({ startMonth: 7, endMonth: 9, name: LANBEL_TRIMESTRE_3 })

    }

    if (arrayMonths.includes(10)
        && arrayMonths.includes(11)
        && arrayMonths.includes(12)) {

        returnQuarters.push({ startMonth: 10, endMonth: 12, name: LANBEL_TRIMESTRE_4 })

    }

    console.log('returnQuarters: ', returnQuarters);

    return returnQuarters;
}

const LANBEL_SEMESTRE_1 = '1º Semestre (Jan - Jun)';
const LANBEL_SEMESTRE_2 = '2º Semestre (Jul - Dez)';
export function getSemestersByMonthsArray(arrayMonths) {

    const returnSemesters = [];

    if (arrayMonths.includes(1)
        && arrayMonths.includes(2)
        && arrayMonths.includes(3)
        && arrayMonths.includes(4)
        && arrayMonths.includes(5)
        && arrayMonths.includes(6)) {

        returnSemesters.push({ startMonth: 1, endMonth: 6, name: LANBEL_SEMESTRE_1 })

    }

    if (arrayMonths.includes(7)
        && arrayMonths.includes(8)
        && arrayMonths.includes(9)
        && arrayMonths.includes(10)
        && arrayMonths.includes(11)
        && arrayMonths.includes(12)) {

        returnSemesters.push({ startMonth: 7, endMonth: 12, name: LANBEL_SEMESTRE_2 })

    }

    return returnSemesters;
}

export function getMonthName(numberMonth) {

    const ms = [

        'Janeiro',

        'Fevereiro',

        'Março',

        'Abril',

        'Maio',

        'Junho',

        'Julho',

        'Agosto',

        'Setembro',

        'Outubro',

        'Novembro',

        'Dezembro',
    ];

    return ms[parseInt(numberMonth) - 1];
};

export function getShortMonthName(numberMonth) {

    const ms = [

        'Jan',

        'Fev',

        'Mar',

        'Abr',

        'Mai',

        'Jun',

        'Jul',

        'Ago',

        'Set',

        'Out',

        'Nov',

        'Dez',
    ]

    return ms[parseInt(numberMonth) - 1];
};

export function getMonths() {

    const ms = [

        'Janeiro',

        'Fevereiro',

        'Março',

        'Abril',

        'Maio',

        'Junho',

        'Julho',

        'Agosto',

        'Setembro',

        'Outubro',

        'Novembro',

        'Dezembro',
    ]

    return ms;
};

export function getShortMonths() {

    const ms = [

        'Jan',

        'Fev',

        'Mar',

        'Abr',

        'Mai',

        'Jun',

        'Jul',

        'Ago',

        'Set',

        'Out',

        'Nov',

        'Dez',
    ]

    return ms;
};



/**
 * month with names list
 * @param {Array} months
 * @returns {Array} months with names
 */
export function getMonthNumberByMonthName(m) {

    const ms = [
        {
            id: 1,
            name: 'Janeiro',
        },
        {
            id: 2,
            name: 'Fevereiro',
        },
        {
            id: 3,
            name: 'Março',
        },
        {
            id: 4,
            name: 'Abril',
        },
        {
            id: 5,
            name: 'Maio',
        },
        {
            id: 6,
            name: 'Junho',
        },
        {
            id: 7,
            name: 'Julho',
        },
        {
            id: 8,
            name: 'Agosto',
        },
        {
            id: 9,
            name: 'Setembro',
        },
        {
            id: 10,
            name: 'Outubro',
        },
        {
            id: 11,
            name: 'Novembro',
        },
        {
            id: 12,
            name: 'Dezembro',
        }

    ]

    let month = ms.filter(el => el.name.toUpperCase() == m)

    return month.id;
};

/**
 * month with names list
 * @param {Array} months
 * @returns {Array} months with names
 */
export function getMonthNumberByMonthShortName(m) {

    const ms = [
        {
            id: 1,
            name: 'Jan',
        },
        {
            id: 2,
            name: 'Fev',
        },
        {
            id: 3,
            name: 'Mar',
        },
        {
            id: 4,
            name: 'Abr',
        },
        {
            id: 5,
            name: 'Mai',
        },
        {
            id: 6,
            name: 'Jun',
        },
        {
            id: 7,
            name: 'Jul',
        },
        {
            id: 8,
            name: 'Ago',
        },
        {
            id: 9,
            name: 'Set',
        },
        {
            id: 10,
            name: 'Out',
        },
        {
            id: 11,
            name: 'Nov',
        },
        {
            id: 12,
            name: 'Dez',
        }

    ]

    const month = ms.find(el => el.name.toUpperCase() == m)

    return month?.id;
};



/**
 * years start
 * @param {int} start
 * @returns {Array} years
 */
export function getArrayYears(start) {

    const ys = [];

    let currentYear = new Date().getFullYear();

    for (let i = start; i <= currentYear; i++) {
        console.log("Adicionando: ", start);
        ys.push(i);
    }

    ys.reverse();

    return ys;
};

/**
 * test abreviação nome da instituição, retirando o código
 * @param {String} instituitionName
 * @returns {String} abreviation
 */
export function getInstituitionAbreviation(instituitionName) {

    let abreviation = "";

    abreviation = instituitionName.substring(5)

    return abreviation;
};

export function formatDataToPieChart(data) {

    let colors = unoTheme.chartColors.slice(0, data.length);

    let labels = [];
    let values = [];

    data.forEach(element => {
        labels.push(element.label);
        values.push(element.value);
    });

    //console.log("data: ", data);

    return {
        labels: labels,
        datasets: [{
            data: values,
            backgroundColor: colors,
            hoverBackgroundColor: colors
        }]
    };
};

/**
 * Formatar informações pro PieChart
 * @param {Object} data {label: x, value: y}
 * @param {Number} chartCount
 * @returns {Object} dataFormatted per size with onClick with dispatch function to modify rightContent
 */
export function formatDataToPieChartPerSizeRightContent(data, chartCount) {

    if (chartCount > unoTheme.chartColors.length) {
        return console.error('chartCount is bigger than total number of unoTheme colors!');
    }

    let colors = unoTheme.chartColors.slice(0, chartCount);

    let labels = [];
    let values = [];
    let rankingFunds = {
        data: []
    };

    for (let i = 0; i < chartCount; i++) {
        labels[i] = data[i].fund_name;
        values[i] = data[i].number_of_clients;
        rankingFunds.data[i] = [{
            number_of_clients: values[i],
            fund_id: data[i].fund_id,
            fund_cnpj: data[i].fund_cnpj,
            fund_name: labels[i]
        }];
    }

    return {
        labels,
        datasets: [{
            rankingFunds,
            data: values,
            backgroundColor: colors,
            hoverBackgroundColor: colors
        }]
    };
}

/**
 * Agrupando possíveis múltiplas políticas para o mesmo ano de um artigo de resolução
 * @param {array} articles
 * @returns {array} artigos agrupados
 */
export function groupArticlePolicys(articles) {

    let articlesReturn = [];
    articles.forEach(element => {

        //Verificando se o artigo já está na lista de retorno
        //Caso não esteja ele simplesmente é adicionado a ela        
        let articlesFiltered = articlesReturn.filter(article => article.id == element.id);
        if (articlesFiltered.length == 0) {

            articlesReturn.push(element);

        } else if (articlesFiltered && articlesFiltered.length == 1) {

            //Caso já exista registro pra esse artigo ele é adicionado ao mesmo artigo
            let articleToGroup = articlesFiltered[0];

            if (!articleToGroup.extrasPolicys) {
                articleToGroup.extrasPolicys = {}
            }

            //Apenas se adiciona o elemento como uma política extra
            articleToGroup.extrasPolicys[element.month_policy] = element;

            //Atualizando targets a partir da última extra_policy caso exista
            articleToGroup.lower_target = element.lower_target;
            articleToGroup.target = element.target;
            articleToGroup.top_target = element.top_target;

        }


    });

    return articlesReturn;
}


/**
 * Relacionando artigos ao cliente
 * @param {array} articles
 * @param {array} clientAssets
 * @returns {array} ativos formatados e agrupados
 */
export function markArticlesInPortfolio(articles, assets, segments, articlesRules, promanagementCode) {

    console.log('articlesRules: ', articlesRules);

    groupArticlePolicys(articles);

    let totalPortfolioValue = 0.0;
    let totalArticlesValue = 0.0;

    let mapSegmentArticlesTotal = {};

    //Inicializando total de segmentos
    if (segments) {

        segments.forEach(element => {
            mapSegmentArticlesTotal[element.id] = 0;
        });
    }

    assets.forEach(asset => {

        if (!asset.label_title_type || (asset.label_title_type && asset.label_title_type != GENERIC_ASSET_TITLES_TYPE)) { //Removendo imóveis
            const article = articles.filter(article => article.id == asset.legislation_article_id)[0]
            if (article) {

                if (!article.groupByFund) {
                    article.groupByFund = {}
                }

                article.isInPortfolio = true;
                if (!article.assets) {
                    article.assets = []
                }

                if (asset.cnpj && !article.groupByFund[asset.cnpj]) {
                    article.groupByFund[asset.cnpj] = {
                        asset: asset,
                        balance: 0,
                        article_id: asset.article_id,
                    };
                }

                if (!mapSegmentArticlesTotal[article.legislation_segment_id]) {
                    mapSegmentArticlesTotal[article.legislation_segment_id] = 0
                }

                mapSegmentArticlesTotal[article.legislation_segment_id] += parseFloat(asset.balance_now);

                //Incluindo asset para possíveis cálculos
                article.assets.push(asset);
                if (asset.cnpj) {
                    article.groupByFund[asset.cnpj].balance += parseFloat(asset.balance_now);
                }

                //Calculando o valor total entre todos os assets
                totalArticlesValue += parseFloat(asset.balance_now);
            }

            totalPortfolioValue += parseFloat(asset.balance_now);
        }



    });

    articles.forEach(element => {

        const isResolution4963 = element.legislation_segment_id >= 4;

        //Ajustando limite da resolução a partir da progestão
        if (promanagementCode) {
            element.limit_resolution = element['limit_pg' + promanagementCode];
        }

        //Realizar cálculos
        let totalArticleValue = 0.0;
        if (element.assets && element.assets.length > 0) {
            element.assets.forEach(asset => {

                totalArticleValue += parseFloat(asset.balance_now);

            });

        }

        element.totalArticleValue = totalArticleValue;
        element.percentInPortfolio = element.totalArticleValue / totalPortfolioValue * 100;

        if (element.groupByFund) {

            // console.log("LOG_ARTICLES");
            // console.log("element.groupByFund: ", element.groupByFund);
            // console.log("Element: ", element);
            // console.log("Art13: ", element.limit_art13);
            // console.log("Art14: ", element.limit_art14);

            //Verificando limites art 13 e 14
            Object.entries(element.groupByFund).map(([key, fund]) => {


                if (!isResolution4963) {
                    //Artigo 13
                    //Saldo do fundo no mês não pode ultrapassar o limite estabelecido no campo limit_art13
                    //Em relação ao saldo total da carteira
                    if (fund.balance / totalPortfolioValue * 100 > parseFloat(element.limit_art13)) {

                        if (!element.assetsOutArt13) {
                            element.assetsOutArt13 = [];
                        }

                        element.assetsOutArt13.push(fund.asset);

                    }

                    //Artigo 14
                    //Saldo do fundo no mês não pode ultrapassar o limite estabelecido no campo limit_art14
                    //Em relação ao pl obtido do fundo [comdinheiro]
                    if (fund.asset['dados_cadastrais']) {

                        if (fund.balance / fund.asset['dados_cadastrais'].pl_anbima * 100 > parseFloat(element.limit_art14)) {

                            if (!element.assetsOutArt14) {
                                element.assetsOutArt14 = [];
                            }

                            element.assetsOutArt14.push(fund.asset);

                        }

                    }

                } else {

                    //Artigo 18
                    console.log('fund.article_id: ', fund.article_id);
                    if (fund.balance / totalPortfolioValue * 100 > parseFloat(ArticleParams.limitsByResolution.resolution_4963.limiteArt18)
                        && !ArticleParams.limitsByResolution.resolution_4963.offLimitArt18Ids.includes(fund.article_id)) {

                        if (!element.assetsOutArt18) {
                            element.assetsOutArt18 = [];
                        }

                        element.assetsOutArt18.push(fund.asset);

                    }

                    //Art 19
                    const { asset: ass } = fund;

                    if (fund.asset['dados_cadastrais'] && fund.asset['dados_cadastrais'].pl_anbima) {
                        const participationAssetInFundPl = fund.balance / fund.asset['dados_cadastrais'].pl_anbima * 100;

                        if (!element.assetsOutArt19) {
                            element.assetsOutArt19 = [];
                        }

                        fund.asset.participationAssetInFundPl = participationAssetInFundPl;

                        //Resoluções de excessão do artigo 19
                        if (!ArticleParams.limitsByResolution.resolution_4963.fullOffLimitArt19.includes(ass.article_id)) {
                            if (ArticleParams.limitsByResolution.resolution_4963.offLimitArt19Ids.includes(ass.article_id)) {
                                if (participationAssetInFundPl >= ArticleParams.limitsByResolution.resolution_4963.extraLimiteArt19) {


                                    element.assetsOutArt19.push(fund.asset);
                                }
                            } else {
                                if (participationAssetInFundPl >= ArticleParams.limitsByResolution.resolution_4963.limiteArt19) {
                                    element.assetsOutArt19.push(fund.asset);
                                }
                            }
                        }

                    }


                }




            })

        }

        //Verificar se está dentro dos targets
        // console.log('element.percentInPortfolio: ', element.percentInPortfolio);
        // console.log('element.lower_target: ', element.lower_target);
        // console.log('element: ', element);
        // console.log('test: ', element.percentInPortfolio < parseFloat(element.lower_target));
        // console.log('test: ', element.percentInPortfolio > parseFloat(element.top_target));
        if (element.percentInPortfolio < parseFloat(element.lower_target)) {
            element.outLowerTarget = true;
        }
        if (element.percentInPortfolio > parseFloat(element.top_target)) {
            element.outTopTarget = true;
        }

    });


    //Agrupando fundos por regra
    const assetsByRule = {
        outRule: false,
    };
    if (articlesRules) {
        articlesRules.forEach(rule => {
            if (!assetsByRule[rule.id]) {
                assetsByRule[rule.id] = {
                    rule: { ...rule },
                    totalBalance: 0,
                    assets: [],
                }
            }

            rule.articles.forEach(articleInRule => {
                const toConcat = assets.filter(el => el.article_id == articleInRule.id);

                assetsByRule[rule.id].totalBalance += toConcat.reduce(
                    (accumulate, current) => accumulate + parseFloat(current.balance_now),
                    0
                )

                assetsByRule[rule.id].assets.push(...toConcat);
            });

            let compareLimit = null;
            switch (promanagementCode) {
                case 1:
                    compareLimit = rule.limit_pg1;
                    break;
                case 2:
                    compareLimit = rule.limit_pg2;
                    break;
                case 3:
                    compareLimit = rule.limit_pg3;
                    break;
                case 4:
                    compareLimit = rule.limit_pg4;
                    break;
                default:
                    compareLimit = rule.limit_rule;
            }

            assetsByRule[rule.id].outRule = false;
            if (compareLimit) {

                if (assetsByRule[rule.id].totalBalance / totalPortfolioValue * 100 > parseFloat(compareLimit)) {
                    assetsByRule[rule.id].outRule = true;
                    assetsByRule.outRule = true;
                }
            }

        });
    }

    return {
        articles: articles,
        totalPortfolioValue: totalPortfolioValue,
        totalArticlesValue: totalArticlesValue,
        mapSegmentArticlesTotal: mapSegmentArticlesTotal,
        assetsByRule: assetsByRule,
    }

}


//Remover acentos
export function removeAccents(str) {
    let accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    let accentsOut = "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
    str = str.split('');
    str.forEach((letter, index) => {
        let i = accents.indexOf(letter);
        if (i != -1) {
            str[index] = accentsOut[i];
        }
    })
    return str.join('');
}

function businessDaysBetween(startDate, endDate) {

    return business.weekDays(startDate, endDate);

    // const difference = endDate.getTime() - startDate.getTime()
    // return Math.ceil(difference / (1000 * 3600 * 24));
}

function prepareAssetsToDisponibilizationDistribuition(assets, endDayPeriod) {

    const otherCases = [];

    const getDaysDisponibilizationByAsset = (asset) => {


        if (asset.local_liquidity) {

            //Local liquidity
            //1. D+X (onde X é um número)
            //2. X (onde X é um número)
            //3. Quando há a presençã de carência o cálculo é combinado com o local_liquidity

            if (asset.local_liquidity.startsWith('D+')) {
                if (asset.lack_liquidity_date) {

                    const today = moment.utc(endDayPeriod);
                    const resultDays = businessDaysBetween(today, moment.utc(asset.lack_liquidity_date));
                    return resultDays;

                } else {
                    return parseInt(asset.local_liquidity.trim().toUpperCase().substring(2));
                }
            } else { return asset.local_liquidity }
        } else {

            if (asset.dados_cadastrais && asset.dados_cadastrais.disponibilization) {
                const preFormattedDisponibilization = asset.dados_cadastrais.disponibilization.trim().toUpperCase();
                if (preFormattedDisponibilization.startsWith('D+')) {
                    return parseInt(preFormattedDisponibilization.substring(2));
                } else {
                    return asset.dados_cadastrais.disponibilization;
                    //otherCases.push(asset.dados_cadastrais.disponibilization);
                }
            } else {
                otherCases.push(asset);
                //verificando se é um título
                if (asset.title_id) {

                    console.log("endDayPeriod: ", endDayPeriod);
                    const today = moment.utc(endDayPeriod ? endDayPeriod : new Date());
                    if (asset.sector = "PUBLICO") {
                        //se marcado no MERCADO é representado por d+1
                        if (asset.marking == "MERCADO") {
                            return 1;
                        } else {
                            if (asset.label_title_type == GENERIC_ASSET_TITLES_TYPE) {//imóveis
                                return 0;
                            } else {
                                return businessDaysBetween(today, moment.utc(asset.due_date));
                            }
                        }
                    } else if (asset.sector = "PRIVADO") {
                        return businessDaysBetween(today, moment.utc(asset.asset_title_due_date));
                    }

                }
                return 0;
            }
        }

    }


    assets.forEach(assetLoop => {

        const daysDisponibilization = getDaysDisponibilizationByAsset(assetLoop);
        //console.log('daysDisponibilization: ', daysDisponibilization);
        const labelLiquidityDistribuition = getLabelLiquidityDistribuition(daysDisponibilization);
        //console.log('labelLiquidityDistribuition: ', labelLiquidityDistribuition);
        assetLoop.disponibilization_distribuition = labelLiquidityDistribuition;
    });

    console.log("Distribuition other cases: ", otherCases);

}

export function groupByDistributionOptions(assets, selectedDistributionOptionLabel, justTwoWords, columnOrder, endDayPeriod) {
    let assetClasses = {};
    let totalAmount = 0;
    const UNINFORMED_LABEL = "Não informado";

    //Caso seja por distribuição preparar disponibilization_distribuition
    //console.log('selectedDistributionOptionLabel: ', selectedDistributionOptionLabel);
    if (selectedDistributionOptionLabel == disponibilization) {
        prepareAssetsToDisponibilizationDistribuition(assets, endDayPeriod);
    }

    assets.forEach(asset => {

        let isBenchmarkFromExternalAPI = () => {
            let isDistributionOptionAssetNull = asset[selectedDistributionOptionLabel] == null;
            let isSelectedDistributionOptionLabelBenchmarkAnbima = selectedDistributionOptionLabel == benchmark_anbima;

            if (isDistributionOptionAssetNull && isSelectedDistributionOptionLabelBenchmarkAnbima) {
                return true;
            }

            return false;
        }

        if (isBenchmarkFromExternalAPI()) {
            const isBenchmarkExists = asset.benchmark ? true : false;
            let benchmark = UNINFORMED_LABEL;

            if (isBenchmarkExists) {
                benchmark = asset.benchmark;
            }

            asset[selectedDistributionOptionLabel] = benchmark;
        }

        let isSpecialFund = () => {
            let isDistributionOptionAssetUndefined = asset[selectedDistributionOptionLabel] == undefined;
            if (isDistributionOptionAssetUndefined) return true;
            return false;
        };

        if (isSpecialFund()) asset[selectedDistributionOptionLabel] = UNINFORMED_LABEL;

        if (!assetClasses[asset[selectedDistributionOptionLabel]]) {

            assetClasses[asset[selectedDistributionOptionLabel]] = {
                amount: 0,
                percent: 0,
            }

        }

        //Evitando que seja somado um saldo NaN ou NULO (Quando não foi informado saldo de um ativo, geralmente um ativo que foi resgatado totalmente)
        if (!Number.isNaN(asset.balance_now) && asset.balance_now != null) {
            assetClasses[asset[selectedDistributionOptionLabel]].amount += asset.balance_now;

            //Para cálculo da participação
            totalAmount += asset.balance_now;
        }

    });

    let dataToChart = [];
    Object.entries(assetClasses).map(([label, row]) => {
        let formatedLabel = justTwoWords ? formatTextForValidDisplay(label, 2) : label;

        dataToChart.push({
            label: checkFinalLabelDistribuitionTable(formatedLabel),
            value: row.amount,
        })

        row.label = label;
        let valuePercent = row.amount / totalAmount * 100;
        row.percent = parseFloat(parseFloat(valuePercent).toFixed(2));
    });

    dataToChart = formatDataToPieChart(dataToChart);

    let getMainColumnLabel = () => {
        switch (selectedDistributionOptionLabel) {
            case classe:
                return 'Classe';
            case device:
                return 'Enquadramento';
            case manager_anbima:
                justTwoWords = true;
                return 'Gestor';
            case administrator_anbima:
                justTwoWords = true;
                return 'Administrador';
            case benchmark_anbima:
                return 'Benchmark';
            case disponibilization:
                return 'Liquidez';
        }
    }

    let mainLabel = getMainColumnLabel();

    return {
        dataToFormat: {
            assetClasses,
            totalAmount
        },
        dataToTable: {
            mainLabel,
            data: []
        },
        dataToChart,
    };
}

export function makeChartEvolutionPL(mapAssetsBalances, mapAccountsBalances, periodToRemove) {
    let months = [];
    let values = [];

    Object.entries(mapAssetsBalances).map(([key, row]) => {

        //removendo inicio de carteira do gráfico initPortfolioPeriod 
        if (key != periodToRemove) {
            let d = key;
            let splittedDate = d.split('/');
            months.push(getShortMonthName(parseInt(splittedDate[0])) + "/" + splittedDate[1]);
            values.push({
                y: (mapAssetsBalances[d] ? mapAssetsBalances[d] : 0)
                    + (mapAccountsBalances[d] ? mapAccountsBalances[d] : 0), //Adicionando saldos das disponibilidades
            })
        }

    })

    let data = {
        labels: months,
        type: 'bar',
        datasets: [
            {
                label: false,
                fill: false,
                lineTension: 0.1,
                backgroundColor: unoTheme.hoverMainColor,
                borderColor: unoTheme.mainColor,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: values,
                barPercentage: 0.5
            }
        ]
    }
    return data;
}

export function makeChartEvolutionPLNEW(mapAssetsBalances, mapAccountsBalances) {
    let months = [];
    let values = [];
    Object.entries(mapAssetsBalances).map(([key, row]) => {

        // if (row.diarys[row.diarys.length - 1].new_pl) {
        let d = key;
        let splittedDate = d.split('/');
        months.push(getShortMonthName(parseInt(splittedDate[0])) + "/" + splittedDate[1]);
        values.push({
            //y: parseFloat(row.diarys[row.diarys.length - 1].new_pl),
            // y: parseFloat(row.diarys[row.diarys.length - 1].new_pl)
            //     + (mapAccountsBalances[d] ? mapAccountsBalances[d] : 0), //Adicionando saldos das disponibilidades
            y: (mapAssetsBalances[d] ? mapAssetsBalances[d] : 0)
                + (mapAccountsBalances[d] ? mapAccountsBalances[d] : 0), //Adicionando saldos das disponibilidades
        })
        // }

    })



    const optionsChartEvolutionPLChart = getEvolutionPlOptionsChart(values)

    const dataEvolutionPL = {
        labels: months,
        type: 'bar',
        datasets: [
            {
                label: false,
                fill: false,
                lineTension: 0.1,
                backgroundColor: unoTheme.hoverMainColor,
                borderColor: unoTheme.mainColor,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: values,
                barPercentage: 0.5,
            }
        ]
    }
    return { dataEvolutionPL, optionsChartEvolutionPLChart };
}

export function makeChartCompare(rents, targets, type) {


    let months = [];
    let valuesFundMonth = [];
    let valuesFundAcum = [];
    let valuesBenchmarkMonth = [];
    let valuesBenchmarkAcum = [];

    for (let i = 0; i < rents.length; i++) {

        const rentLoop = rents[i];
        //console.log('RENT LOOP: ', rentLoop);
        //let targetLoop = targets[i];
        const splittedMonthYear = rentLoop.monthYear.split('/');
        const month = splittedMonthYear[0];
        const year = splittedMonthYear[1];
        const targetLoop = targets.find(el => el.month == month && el.year == year);
        // console.log('TARGET LOOP: ', targetLoop);
        // console.log('rentLoop: ', rentLoop);

        if (rentLoop.lastDiaryBeforeMonth
            && (
                rentLoop.lastDiaryBeforeMonth.id
                ||
                (!rentLoop.lastDiaryBeforeMonth.id && rentLoop.lastDiaryBeforeMonth.quota_value === 1)
            )) { //Evitar entrar o mês de início da carteira                

            let splittedDate = rentLoop.monthYear.split('/');
            months.push(getShortMonthName(parseInt(splittedDate[0])) + "/" + splittedDate[1]);

            valuesFundMonth.push({

                y: parseFloat(rentLoop.rent),

            })

            valuesFundAcum.push({

                y: parseFloat(rentLoop.rentAcum),

            })

            if (targetLoop != null) {
                valuesBenchmarkMonth.push(
                    targetLoop.use_ipca ? targetLoop.taxMonthIpca : targetLoop.taxMonthInpc
                );

                valuesBenchmarkAcum.push(
                    targetLoop.use_ipca ? targetLoop.taxAcumIpca : targetLoop.taxAcumInpc
                );
            } else {
                valuesBenchmarkMonth.push(
                    null
                );

                valuesBenchmarkAcum.push(
                    null
                );
            }


        }

    }

    // console.log('valuesFundMonth: ', valuesFundMonth)
    // console.log('valuesBenchmarkMonth: ', valuesBenchmarkMonth)

    let data = {
        labels: months,
        datasets: [
            {
                label: 'Rentabilidade',
                backgroundColor: "transparent",
                borderColor: unoTheme.chartColors[1],
                pointBackgroundColor: unoTheme.chartColors[1],
                pointBorderColor: unoTheme.chartColors[1],
                pointHoverBackgroundColor: unoTheme.chartColors[1],
                pointHoverBorderColor: unoTheme.chartColors[1],
                data: type == 0 ? valuesFundMonth : valuesFundAcum,
                type: 'line',
            },

            {
                label: 'Meta',
                backgroundColor: unoTheme.chartColors[0],
                data: type == 0 ? valuesBenchmarkMonth : valuesBenchmarkAcum
            },

        ]
    }

    return data;
}

export function formatTargetsAndRentsToShow(rowsInflates, rentMonths, mapAccountsBalances, mapAssetsBalances, clientTransactions) {

    let inflationRatesToShow = {};

    rowsInflates.forEach(ir => {

        if (!inflationRatesToShow[ir.year]) {

            console.log("Incluindo: ", ir.year);
            inflationRatesToShow[ir.year] = {
                id: ir.id,
                year: ir.year,
                tax: ir.tax,
                taxMonth: (Math.pow((1 + (parseFloat(ir.tax) / 100)), 1 / 12) - 1) * 100,
                expected_rent: ir.expected_rent,
                liability_duration: ir.liability_duration,
                actuary: ir.actuary,
                use_ipca: ir.use_ipca,
                use_inpc: ir.use_inpc,
            };
            inflationRatesToShow[ir.year].inflateMonths = [];

        }

        inflationRatesToShow[ir.year].inflateMonths.push(ir);
    });

    console.log("Formatado inflationRatesToShow: ", inflationRatesToShow);
    let arrayToShow = [];

    let currentYear = null;

    let ipcaAcum = 1;
    let ipcaAcumYear = 1;

    let inpcAcum = 1;
    let inpcAcumYear = 1;

    let taxAcumIpca = 1;
    let taxAcumIpcaYear = 1;

    let taxAcumInpc = 1;
    let taxAcumInpcYear = 1;

    let rentMoneyAcumYear = 0;

    //Para cada indice mensal de inflação, fazendo os valores acumulados
    //e atribuindo a rentabilidade e o gap

    //console.log('mapAssetsBalances targets: ', mapAssetsBalances);

    const clientAmortizations = clientTransactions.filter(el => el.operation_id == 3);
    const clientApplications = clientTransactions.filter(el => el.operation_id == 1);
    const clientRescues = clientTransactions.filter(el => el.operation_id == 2);
    console.log('clientAmortizations: ', clientAmortizations);

    //Organizando aplicações por período
    const applicationsByPeriod = {};
    if (clientApplications) {
        clientApplications.forEach(item => {

            const itemPeriod = moment.utc(item.transaction_date).format('M/YYYY')
            if (!applicationsByPeriod[itemPeriod]) {
                applicationsByPeriod[itemPeriod] = [];
            }
            applicationsByPeriod[itemPeriod].push(item);
        });

    }

    //Organizando aplicações por período
    const rescuesByPeriod = {};
    if (clientRescues) {
        clientRescues.forEach(item => {

            const itemPeriod = moment.utc(item.transaction_date).format('M/YYYY')
            if (!rescuesByPeriod[itemPeriod]) {
                rescuesByPeriod[itemPeriod] = [];
            }
            rescuesByPeriod[itemPeriod].push(item);
        });

    }

    //Organizando amortizações por período
    const amortizationsByPeriod = {};
    if (clientAmortizations) {
        clientAmortizations.forEach(amortization => {

            const amortizationPeriod = moment.utc(amortization.transaction_date).format('M/YYYY')
            if (!amortizationsByPeriod[amortizationPeriod]) {
                amortizationsByPeriod[amortizationPeriod] = [];
            }
            amortizationsByPeriod[amortizationPeriod].push(amortization);
        });

    }

    // console.log('applicationsByPeriod: ', applicationsByPeriod);
    // console.log('rescuesByPeriod: ', rescuesByPeriod);
    // console.log('amortizationsByPeriod: ', amortizationsByPeriod);

    Object.entries(inflationRatesToShow).map(([key, row], index) => {

        let finalTargetsToShow = [];

        row.inflateMonths.reverse();

        row.inflateMonths.forEach((ir) => {

            const monthBefore = ir.month - 1 == 0 ? 12 : ir.month - 1;
            const yearBefore = monthBefore == 12 ? ir.year - 1 : ir.year;

            const periodBefore = monthBefore + "/" + yearBefore;
            const period = ir.month + "/" + ir.year;
            const rentM = rentMonths[ir.month + "/" + ir.year]

            console.log('PERIOD: ', period)
            //console.log('ir.result_app_rescues: ', ir.result_app_rescues);


            if (rentM) {

                //Calculando aplicações e resgates do mês
                if (!ir.result_app_rescues) {
                    ir.result_app_rescues = rentM.diarys.reduce(
                        (previous, current) => previous + parseFloat(current.result_app_rescues),
                        0
                    )
                }

                //Calculando aplicações
                if (applicationsByPeriod[period]) {
                    ir.applications_amount = applicationsByPeriod[period].reduce(
                        (previous, current) => previous + parseFloat(current.amount),
                        0
                    )
                } else {
                    ir.applications_amount = 0;
                }

                //Calculando resgates
                if (rescuesByPeriod[period]) {
                    ir.rescues_amount = rescuesByPeriod[period].reduce(
                        (previous, current) => previous + parseFloat(current.amount),
                        0
                    )
                } else {
                    ir.rescues_amount = 0;
                }

                //Calculando amortizações
                if (amortizationsByPeriod[period]) {
                    ir.amortization_amount = amortizationsByPeriod[period].reduce(
                        (previous, current) => previous + parseFloat(current.amount),
                        0
                    )
                } else {
                    ir.amortization_amount = 0;
                }

                rentM.period = period;

                if (currentYear != ir.year) {
                    currentYear = ir.year;
                    ipcaAcumYear = 1;
                    inpcAcumYear = 1;
                    taxAcumIpcaYear = 1;
                    taxAcumInpcYear = 1;

                    rentMoneyAcumYear = 0;

                }

                //ir.rentAmount = balanceMonth.rent;
                ir.rentPercent = rentM.rent;
                ir.rentAcum = rentM.rentAcum;
                ir.rentAcumYear = rentM.rentAcumYear;

                ir.balance_before =
                    //(mapAccountsBalances && mapAccountsBalances[periodBefore] ? mapAccountsBalances[periodBefore] : 0) +
                    (mapAssetsBalances && mapAssetsBalances[periodBefore] ? mapAssetsBalances[periodBefore] : 0);
                ir.balance_now =
                    //(mapAccountsBalances && mapAccountsBalances[period] ? mapAccountsBalances[period] : 0) +
                    (mapAssetsBalances && mapAssetsBalances[period] ? mapAssetsBalances[period] : 0);

                // if (period == '05/2022') {
                //     console.log("rentM.diarys: ", rentM.diarys);
                //     console.log("BALANCE NOW: ", ir.balance_now);
                //     console.log("RESULT APP RESCUES: ", ir.result_app_rescues);
                //     console.log("BALANCE BEFORE: ", ir.balance_before);
                //     console.log("RESULT 1: ", ir.balance_now - ir.balance_before);
                //     console.log("RESULT 2: ", ir.balance_now - ir.result_app_rescues - ir.balance_before);
                // }

                //Rentabilidades em dinheiro
                //ir.rentMonthMoney = ir.balance_now - ir.result_app_rescues + ir.amortization_amount - ir.balance_before;
                ir.rentMonthMoney = ir.balance_now - ir.applications_amount + ir.rescues_amount + ir.amortization_amount - ir.balance_before;
                rentMoneyAcumYear += ir.rentMonthMoney;
                ir.rentMoneyAcumYear = rentMoneyAcumYear;

                //Somando disponibilidades
                ir.balance_before_investments = ir.balance_before;
                ir.balance_before += (mapAccountsBalances && mapAccountsBalances[periodBefore] ? mapAccountsBalances[periodBefore] : 0);
                ir.balance_now_investments = ir.balance_now;
                ir.balance_now += (mapAccountsBalances && mapAccountsBalances[period] ? mapAccountsBalances[period] : 0);

                ir.account_before = (mapAccountsBalances && mapAccountsBalances[periodBefore] ? mapAccountsBalances[periodBefore] : 0)
                ir.account_now = (mapAccountsBalances && mapAccountsBalances[period] ? mapAccountsBalances[period] : 0)

                //Só deve ser incluida na lista as metas que já possuem lançamento
                //Calcular o ipcaAcumulado
                ipcaAcum *= ir.ipca ? (1 + (parseFloat(ir.ipca) / 100)) : 1;
                inpcAcum *= ir.inpc ? (1 + (parseFloat(ir.inpc) / 100)) : 1;
                //Por ano
                ipcaAcumYear *= ir.ipca ? (1 + (parseFloat(ir.ipca) / 100)) : 1;
                inpcAcumYear *= ir.inpc ? (1 + (parseFloat(ir.inpc) / 100)) : 1;

                let fatorIpca = (1 + (parseFloat(ir.ipca) / 100))
                let fatorInpc = (1 + (parseFloat(ir.inpc) / 100))
                let fatorTax = parseFloat(parseFloat((1 + (parseFloat(row.taxMonth) / 100))).toFixed(4))

                ir.taxMonthIpca = row.taxMonth + parseFloat(ir.ipca);
                ir.taxMonthInpc = row.taxMonth + parseFloat(ir.inpc);

                taxAcumIpca = (taxAcumIpca * fatorIpca * fatorTax);
                taxAcumInpc = (taxAcumInpc * fatorInpc * fatorTax);
                //Por ano
                taxAcumIpcaYear = (taxAcumIpcaYear * fatorIpca * fatorTax);
                taxAcumInpcYear = (taxAcumInpcYear * fatorInpc * fatorTax);

                ir.ipcaAcum = parseFloat(parseFloat((ipcaAcum - 1) * 100).toFixed(2));
                ir.inpcAcum = parseFloat(parseFloat((inpcAcum - 1) * 100).toFixed(2));
                ir.taxAcumIpca = parseFloat(parseFloat((taxAcumIpca - 1) * 100).toFixed(2));
                ir.taxAcumInpc = parseFloat(parseFloat((taxAcumInpc - 1) * 100).toFixed(2));

                //Por ano
                ir.ipcaAcumYear = parseFloat(parseFloat((ipcaAcumYear - 1) * 100).toFixed(2));
                ir.inpcAcumYear = parseFloat(parseFloat((inpcAcumYear - 1) * 100).toFixed(2));
                ir.taxAcumIpcaYear = parseFloat(parseFloat((taxAcumIpcaYear - 1) * 100).toFixed(2));
                ir.taxAcumInpcYear = parseFloat(parseFloat((taxAcumInpcYear - 1) * 100).toFixed(2));

                finalTargetsToShow.push(ir);
            }

        })
        row.inflateMonths.reverse();
        //finalTargetsToShow.reverse();
        row.finalTargetsToShow = finalTargetsToShow;

        arrayToShow.push(row);

    })

    arrayToShow.reverse();

    console.log("ARRAY TO SHOW: ", arrayToShow);

    return arrayToShow;

}

function checkWholeRescue(asset) {

    //Caso o saldo atual seja 0 ou nulo 
    //Não existam transações nesse Mês
    //E o saldo anterior também seja 0, pois caso seja diferente de zero há a necessidade de informar resgate
    return (asset.balance_now == 0 || asset.balance_now == null)
        && (!asset.transactions || asset.transactions.length == 0)
        && (asset.balance_before <= 0.0001 && asset.quota_amount_before <= 0.0001)
}

export function removeWholeRescuesAssetsTitles(assetsTitles) {

    const returnAssets = [];
    const removedAssets = [];
    assetsTitles.forEach(element => {

        //console.log("TITLE TO TEST: ", element);
        if (checkWholeRescue(element)) {

            removedAssets.push(element);
        } else {
            returnAssets.push(element)
        }
    });

    console.log("Títulos totalmente resgatados: ", removedAssets);

    return returnAssets;

}

//Remove os ativos que não estão ativos
//Em casos gerais os que sofreram resgate total
export function removeWholeRescuesAssets(assets) {

    console.log("ASSETS SIMPLE: ", assets);

    let newAssets = [];
    let removeds = [];
    assets.forEach(asset => {

        //Percorrendo subAssets
        if (asset.subAssets && asset.subAssets.length > 0) {

            let newSubAssets = [];
            asset.subAssets.forEach(subAsset => {

                if (checkWholeRescue(subAsset)) {
                    //if (subAsset.balance_before == 0 && subAsset.balance_now == 0 && (!subAsset.transactions || subAsset.transactions.length == 0)) {
                    //deve ser removido dos assets

                    //Adicionando pl = 0 pois ele não passa pelo calculatePlRent
                    subAsset.pl = subAsset.balance_now;
                    removeds.push(subAsset);
                } else {
                    newSubAssets.push(subAsset);
                }
            })

            //Caso haja só um subAsset no ativo é removido o ativo e adicionado apenas esse subAsset
            if (newSubAssets && newSubAssets.length == 1) {

                asset = newSubAssets[0];
                delete asset.subAssets;

                newAssets.push(asset);

            } else {

                asset.subAssets = newSubAssets;
                if (asset.subAssets && asset.subAssets.length > 0) {

                    newAssets.push(asset);

                }

            }

            //asset.subAssets = newSubAssets;

        } else {

            if (checkWholeRescue(asset)) {
                //if (asset.balance_before == 0 && asset.balance_now == 0 && (!asset.transactions || asset.transactions.length == 0)) {
                //deve ser removido dos assets

                //Adicionando pl = 0 pois ele não passa pelo calculatePlRent
                asset.pl = asset.balance_now;
                removeds.push(asset);
            } else {

                newAssets.push(asset);
            }
        }
    });

    console.log("REMOVED: ", removeds);
    console.log("newAssets: ", newAssets);

    return {
        newAssets: newAssets,
        offAssets: removeds,
    }
}

/**
 * Verifica se o cliente logado possui segregação de carteira
 * @param {ClientOn} clientOn
 * @returns {Boolean} 
 */
export function verifyClientPortfolioIsSgregated(clientOn) {

    if (clientOn && clientOn.client && clientOn.client.portfolio_segregated) {
        return true;
    } else {
        return false;
    }
}

/**
 * Draws a rounded rectangle using the current state of the canvas.
 * If you omit the last three params, it will draw a rectangle
 * outline with a 5 pixel border radius
 * @param {CanvasRenderingContext2D} ctx
 * @param {Number} x The top left x coordinate
 * @param {Number} y The top left y coordinate
 * @param {Number} width The width of the rectangle
 * @param {Number} height The height of the rectangle
 * @param {Number} [radius = 5] The corner radius; It can also be an object 
 *                 to specify different radii for corners
 * @param {Number} [radius.tl = 0] Top left
 * @param {Number} [radius.tr = 0] Top right
 * @param {Number} [radius.br = 0] Bottom right
 * @param {Number} [radius.bl = 0] Bottom left
 * @param {Boolean} [fill = false] Whether to fill the rectangle.
 * @param {Boolean} [stroke = true] Whether to stroke the rectangle.
 */
export function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
    if (typeof stroke === 'undefined') {
        stroke = true;
    }
    if (typeof radius === 'undefined') {
        radius = 5;
    }
    if (typeof radius === 'number') {
        radius = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
        var defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
        for (var side in defaultRadius) {
            radius[side] = radius[side] || defaultRadius[side];
        }
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    if (fill) {
        ctx.fill();
    }
    if (stroke) {
        ctx.stroke();
    }

}

export function getTitleCod(title, noTradingNote) {

    let cod = "";

    if (title.label_title_type == GENERIC_ASSET_TITLES_TYPE) {
        cod = title.title_name;
    } else {
        if (title.sector == 'PRIVADO') {

            cod = title.title_name + (isPrivateIpcaTitle(title) ? " [ " + moment.utc(title.asset_title_due_date).format('DD/MM/YYYY') + " ]" : "");

        } else {

            if (title == null || title.label_title_type == null || title.issue_date == null || title.due_date == null) {
                cod = title.title_name;
            } else {
                cod = removeEspecialChars(title.label_title_type) + "_" + moment.utc(title.issue_date).format("DDMMYYYY") + "_" + moment.utc(title.due_date).format("DDMMYYYY")

            }

        }
    }


    if (noTradingNote) {
        return cod
    }

    return cod + (title.trading_note ? ' - ' + title.trading_note : '')

}

export function getLastDayInMonthByMonthAndYear(month, year) {


    const jsDate = new Date(parseInt(year), parseInt(month), 0);

    // const jsDate = new Date(parseInt(month), parseInt(year), 0)
    // console.log('jsDate: ', jsDate)
    return moment.utc(jsDate).format('DD/MM/YYYY');

}

export function portfolioClosedToDate(portfolioClosed) {

    if (!portfolioClosed) {
        return null;
    }

    const splittedDate = portfolioClosed.trim().split('/');
    const lastDayMonthDate = new Date(parseInt(splittedDate[1]), parseInt(splittedDate[0]), 0);

    return lastDayMonthDate;
}

export function isDateBeforePortfolioClosed(date, portfolioClosed) {

    // console.log("DATE: ", date);
    // console.log("portfolioClosed: ", portfolioClosedToDate(portfolioClosed));
    //return moment.utc(date).isBefore(potfolioClosedToDate(portfolioClosed), 'month');
    return stringPtDateToMoment(date).isBefore(portfolioClosedToDate(portfolioClosed), 'month');

}

//Verificando se ativos a serem criados tem inicio para antes do fechamento da carteira
//O ativo pode começar no mês de fechamento da carteira, pois cadastra-se apenas o saldo inicial dele
export function checkAssetCreatedAfterPortfolioClosed(assets, portfolioClosed) {

    if (portfolioClosed === null) {
        return [];
    }

    let invalidInitAssets = [];
    assets.forEach(ass => {



        if (isDateBeforePortfolioClosed(ass.asset_init, portfolioClosed)) {

            console.log("INIT: ", ass.asset_init);
            console.log("CLOSED: ", portfolioClosed);
            console.log("ASS: ", ass);
            console.log("isDateBeforePortfolioClosed(ass.asset_init, portfolioClosed): ", isDateBeforePortfolioClosed(ass.asset_init, portfolioClosed));
            invalidInitAssets.push(ass);
        }

    });

    return invalidInitAssets;

}

export function normalizeCityAndUfClient(client) {

    return (client.city_name + '_' + client.uf).toUpperCase();

}

//Transformando em JSON as cotas que até então eram string
export async function toJsonQuotasAssets(assets) {

    assets.forEach(element => {
        if (element.quotas_anbima_before && !Array.isArray(element.quotas_anbima_before)) {
            element.quotas_anbima_before = JSON.parse(element.quotas_anbima_before);
            element.quotas_anbima_before = getClientJsonQuotas(element.quotas_anbima_before, element.client_id);
        }
        if (element.quotas_anbima && !Array.isArray(element.quotas_anbima)) {
            element.quotas_anbima = JSON.parse(element.quotas_anbima);
            element.quotas_anbima = getClientJsonQuotas(element.quotas_anbima, element.client_id);
        }
        element.average_quota_before = element.average_quota_before ? JSON.parse(element.average_quota_before) : null;
        element.average_quota_now = element.average_quota_now ? JSON.parse(element.average_quota_now) : null;
    });
}

/**
 * Verifica se o arquivo importado está sendo importado pro cliente corretamente 
 * a verificação é feita pelo título do arquivo xlsx
 * @param {File} file arquivo importado 
 * @param {Object} clientOn cliente selecionado
 * @param {String} prefix prefixo que indica se é SALDOS (SALDOS) ou MOVS (MOVIMENTAÇÕES)
 */
export function isFileForClientOn(file, clientOn, prefix) {

    const fileName = file.name.substring(0, file.name.indexOf('.'))

    const cityAndUf = normalizeCityAndUfClient(clientOn);

    console.log("cityAndUf: ", cityAndUf);

    if (fileName !== (prefix + '_' + cityAndUf)) {

        return {
            success: false,
            rightName: (prefix + '_' + cityAndUf)
        }

    } else {
        return {
            success: true,
        }
    }
}

//Fundo na carteira histórico
export function getHistoricRentFundInPortfolio(fund) {

    if (fund.quotas_anbima && fund.quotas_anbima.length >= 1 && fund.average_quota_now && fund.average_quota_now.length >= 1) {

        const preLastNowQuotaMonth = fund.quotas_anbima[fund.quotas_anbima.length - 1].valor_cota;
        const lastQuotaMonth = preLastNowQuotaMonth == 0 ? getRealLastValueQuotaMonth(fund) : preLastNowQuotaMonth;;

        const lastQuotaHistoric = fund.average_quota_now[fund.average_quota_now.length - 1].averageQuota;

        // console.log('lastQuotaMonth: ', lastQuotaMonth);
        // console.log('lastQuotaHistoric: ', lastQuotaHistoric);
        // console.log('result: ', ((lastQuotaMonth
        //     /
        //     lastQuotaHistoric) - 1) * 100);

        return ((lastQuotaMonth
            /
            lastQuotaHistoric) - 1) * 100

    } else {
        console.log('NO HISTORIC: ', fund);
    }


}

// export function getHistoricRentFundInPortfolio(fund) {

//     if (!fund.special) {
//         return ((fund.quotas_anbima[fund.quotas_anbima.length - 1].valor_cota
//             /
//             fund.average_quota_now[fund.average_quota_now.length - 1].averageQuota) - 1) * 100
//     } else {
//         return null;
//     }



// }

export function getHistoricRentFundInPortfolioOLD(fund) {

    if (fund.quotas_anbima && fund.quotas_anbima.length >= 1 && fund.arrayToAverageQuotas && fund.arrayToAverageQuotas.length >= 1) {
        return ((fund.quotas_anbima[fund.quotas_anbima.length - 1].valor_cota
            /
            fund.arrayToAverageQuotas[fund.arrayToAverageQuotas.length - 1].averageQuota) - 1) * 100
    } else {
        console.log('NO HISTORIC: ', fund);
    }


}

function capAverageQuota(fundRent, cnpj) {

    let capRent = 1;

    if (!fundRent.quotas_anbima) {
        fundRent.quotas_anbima = fundRent.quotas_anbima_now;
    }
    if (!fundRent.json_average_quotas) {
        fundRent.json_average_quotas = fundRent.average_quota_now;
    }

    const lastValueQuotaMonth = fundRent.quotas_anbima[fundRent.quotas_anbima.length - 1].valor_cota;

    console.log("fundRent.json_average_quotas: ", fundRent.json_average_quotas);
    for (let i = 0; i < fundRent.json_average_quotas.length; i++) {
        const averaQuotaNow = fundRent.json_average_quotas[i];
        if (averaQuotaNow.balanceQuotas <= 0) {
            if (averaQuotaNow.quotaInDayAverage) {
                const factor = (1 + ((averaQuotaNow.quotaInDayAverage / averaQuotaNow.averageQuotaInMonth) - 1));
                console.log("capRent cota 0: ", capRent);
                capRent *= factor;
            }
        } else {
            const factor = (1 + ((lastValueQuotaMonth / averaQuotaNow.averageQuotaInMonth) - 1));
            console.log("capRent normal: ", capRent);
            capRent *= factor;
        }

    }
    console.log("capRent: ", capRent);
    return capRent;
}

const cnpjTest = '14.508.643/0001-55';

//Fundo na carteira no ano
export function getYearRentFundInPortfolio(fundRents, year, cnpj) {

    if (!fundRents || (fundRents && fundRents.length == 0)) {
        return null;
    }


    const fundsRentsYear = fundRents.filter(el => el.year == year);

    let capYear = 1;
    if (cnpj == cnpjTest) {
        console.log('######## YEAR RENT ################');
        console.log('fundsRentsYear: ', fundsRentsYear);
    }

    fundsRentsYear.forEach(fundRent => {

        if (cnpj == cnpjTest) console.log('referenceDate: ', fundRent.aReferenceDate);

        if (fundRent.json_average_quotas.length == 1) {
            const lastAverageQuota = fundRent.json_average_quotas[fundRent.json_average_quotas.length - 1].averageQuotaInMonth;

            //console.log('fundRent: ', fundRent);

            let lastQuotaMonth = 0;
            if (fundRent.quotas_anbima_now && fundRent.quotas_anbima_now.length > 0) {
                lastQuotaMonth = fundRent.quotas_anbima_now[fundRent.quotas_anbima_now.length - 1].valor_cota;
            }

            const rentInMonth = lastQuotaMonth != 0 && lastAverageQuota != 0 ? lastQuotaMonth / lastAverageQuota : 0;

            if (cnpj == cnpjTest) {
                console.log('lastAverageQuota: ', lastAverageQuota);
                console.log('lastQuotaMonth: ', lastQuotaMonth);
                console.log('rentInMonth: ', rentInMonth);
            }
            if (rentInMonth != 0) {

                const factor = (1 + (rentInMonth - 1));
                if (cnpj == cnpjTest) console.log("FACTOR capYear 1: ", factor)

                capYear *= factor;
            }



        } else {


            let capRent = capAverageQuota(fundRent, cnpj);

            if (cnpj == cnpjTest) {
                console.log("ENTRANDO CAPTALIZACAO COTA MEDIA");
                console.log("fundRent: ", fundRent);
                console.log('capAverageQuota: ', capRent);
            }

            if (capRent != 0) {

                capYear *= (1 + (capRent - 1));
            }

        }


    });

    capYear = (capYear - 1) * 100;

    if (cnpj == '13.077.415/0001-05') {
        console.log("capYear: ", capYear);
        console.log('########################');
    }

    return capYear;

}

//Fundo na carteira últimos 12 meses
export function getPeriodRentFundInPortfolio(fundRents, period, forcePeriod, cnpj) { //forcePeriod para manter capitalização mesmo em casos de não haver retorno todos os meses

    if (!fundRents || (fundRents && fundRents.length == 0)) {
        return null;
    }

    const fundsRentsInPeriod = fundRents.reverse().slice(period * (-1));

    if (cnpj == '17.098.794/0001-70') {

        console.log('fundsRentsInPeriod: ', fundsRentsInPeriod);
    }
    if (!forcePeriod && fundsRentsInPeriod.length < period) {
        return null;
    }

    let capRent = 1;
    //console.log('########################: ', fundsRentsInPeriod);
    fundsRentsInPeriod.forEach(fundRent => {

        if (fundRent) {
            if (fundRent.json_average_quotas.length == 1) {
                const lastAverageQuota = fundRent.json_average_quotas[fundRent.json_average_quotas.length - 1].averageQuotaInMonth;


                let lastQuotaMonth = 0;
                if (fundRent.quotas_anbima_now && fundRent.quotas_anbima_now.length > 0) {
                    lastQuotaMonth = fundRent.quotas_anbima_now[fundRent.quotas_anbima_now.length - 1].valor_cota;
                }

                const rentInMonth = lastQuotaMonth != 0 && lastAverageQuota != 0 ? lastQuotaMonth / lastAverageQuota : 0;

                if (rentInMonth != 0) {
                    capRent *= (1 + (rentInMonth - 1));
                }

            } else {
                //Capitalizar a cota média no mês que
                let capRentMonth = capAverageQuota(fundRent);

                if (capRentMonth != 0) {

                    capRent *= (1 + (capRentMonth - 1));
                }
            }
        }



    });

    capRent = (capRent - 1) * 100;
    //console.log('########################');

    return capRent;

}



//Fundo na carteira no mês
export function getInMonthRentFundInPortfolio(fund) {

    if (fund.cnpj == '13.077.415/0001-05') {

        console.log("getInMonthRentFundInPortfolio: ", fund);
    }

    if (fund.is_poupanca) {

        const balanceNow = fund.balance_now;
        const balanceBefore = fund.balance_before;
        if (fund.transactions && fund.transactions.length) {

            const { totalApplications, totalRescues } = getTotalApplicationsAndRescuesByTransactions(fund.transactions);
            return (((balanceNow + totalRescues) / (balanceBefore + totalApplications)) - 1) * 100

        } else {

            return (((balanceNow) / (balanceBefore)) - 1) * 100

        }
    }

    if (fund.isNegativeAsset) {
        return 0;
    }

    if (!fund.average_quota_now && fund.json_average_quotas) {
        fund.average_quota_now = fund.json_average_quotas;
    }
    if (!fund.quotas_anbima && fund.quotas_anbima_now) {
        fund.quotas_anbima = fund.quotas_anbima_now;
    }

    if (fund.cnpj == '13.077.415/0001-05') {


        console.log("LALALA");
        console.log("fund.quotas_anbima: ", fund.quotas_anbima)
        console.log("fund.average_quota_now: ", fund.average_quota_now)
        console.log("fund.quotas_anbima.length: ", fund.quotas_anbima.length)
        console.log("fund.average_quota_now.length: ", fund.average_quota_now.length)
    }

    if (fund.quotas_anbima
        && fund.average_quota_now
        && fund.quotas_anbima.length >= 1
        && fund.average_quota_now.length >= 1) {

        if (fund.cnpj == '13.077.415/0001-05') console.log("XXXXXX");

        const preLastQuotaMonth = fund.quotas_anbima[fund.quotas_anbima.length - 1].valor_cota;
        const lastValueQuotaMonth = preLastQuotaMonth == 0 ? getRealLastValueQuotaMonth(fund) : preLastQuotaMonth;
        //console.log('lastValueQuotaMonth: ', lastValueQuotaMonth);

        if (fund.average_quota_now.length == 1) {

            const lastAverageQuota = fund.average_quota_now[fund.average_quota_now.length - 1];

            if (fund.cnpj == '13.077.415/0001-05') console.log("NANA NANA");

            if (lastAverageQuota.averageQuotaInMonth == 0) {
                return 0;
            }

            if (fund.cnpj == '13.077.415/0001-05') console.log("AEOH AEOH");

            if (fund.totalAmortizationAmount && fund.totalAmortizationAmount != 0) {

                console.log("ENTRANDO CALCULO DE qtdQuotasOnAmortization: ", fund);
                fund.amortizationByQuota = fund.totalAmortizationAmount / fund.qtdQuotasOnAmortization;
            }

            if (fund.cnpj == '13.077.415/0001-05') {
                console.log('lastValueQuotaMonth: ', lastValueQuotaMonth);
                console.log('fund.average_quota_now: ', fund.average_quota_now);
                console.log('lastAverageQuota: ', lastAverageQuota.averageQuotaInMonth);
                console.log('fund: ', fund);
                console.log('fund.amortizationByQuota: ', fund.amortizationByQuota);
                console.log('lastAverageQuota.averageQuotaInMonth: ', lastAverageQuota.averageQuotaInMonth);
            }

            const finalRent = ((
                //Caso haja amortização deve ser considerado o valor da cota ajustado
                (
                    fund.amortizationByQuota ?
                        lastValueQuotaMonth
                        + fund.amortizationByQuota
                        :
                        lastValueQuotaMonth
                )
                /
                lastAverageQuota.averageQuotaInMonth
            ) - 1) * 100;

            return lastAverageQuota.averageQuotaInMonth < 0 ? finalRent * (-1) : finalRent

        } else {

            let capRent = capAverageQuota(fund);

            capRent = (capRent - 1) * 100;

            return capRent;

        }




    } else {

        console.log('passou cálculo errado: ', fund);
        return getRentMonthAsset(fund);
    }

}
//Fundo na carteira no mês
export function getInMonthRentFundInPortfolioOLD(fund) {

    if (!fund.arrayToAverageQuotas && fund.average_quota_now) {
        fund.arrayToAverageQuotas = fund.average_quota_now;
    }

    if (fund.quotas_anbima
        && fund.arrayToAverageQuotas
        && fund.quotas_anbima.length >= 1
        && fund.arrayToAverageQuotas.length >= 1) {


        const lastAverageQuota = fund.arrayToAverageQuotas[fund.arrayToAverageQuotas.length - 1];

        if (lastAverageQuota.balanceQuotas > 0) {

            return ((fund.quotas_anbima[fund.quotas_anbima.length - 1].valor_cota
                /
                lastAverageQuota.averageQuotaInMonth) - 1) * 100

        } else {

            return ((lastAverageQuota.valueQuota
                /
                lastAverageQuota.averageQuotaInMonth) - 1) * 100
        }


    } else {

        return getRentMonthAsset(fund);
    }

}

export function getMonthFundRentInMoney(fund) {

    if (fund.isNegativeAsset) return 0;
    return fund.balance_now - fund.totalApplicationsAmount + fund.totalRescuesAmount + fund.totalAmortizationAmount - fund.balance_before;
}

export function getPortfolioMonthRentInMoney(balanceNow, resultAppRecues, balanceBefore) {

    console.log("BALANCE NOW: ", balanceNow);
    console.log("RESULT APP RESCUES: ", resultAppRecues);
    console.log("BALANCE BEFORE: ", balanceBefore);
    return balanceNow - resultAppRecues - balanceBefore;
}

//Retorna a rentabilidade do fundo baseado nas contas
//Cálculo: cota final do mês atual / cota final do mês anterior
export function getRentMonthAsset(asset) {

    if (!asset.quotas_anbima || asset.quotas_anbima.length === 0) {

        return 0;

    } else {

        //Quando o saldo ANTERIOR for 0, o que fazer?
        //1. Pegar a cota do mês anterior ou atribuir 1
        let quotaValueBefore = 1
        if (asset.quotas_anbima_before && asset.quotas_anbima_before.length >= 1) {

            const preLastBeforeQuotaMonth = asset.quotas_anbima_before[asset.quotas_anbima_before.length - 1].valor_cota;
            quotaValueBefore = preLastBeforeQuotaMonth == 0 ? getRealLastValueQuotaMonth(asset) : preLastBeforeQuotaMonth;

        } else {

            //Fundos especiais e fundos com saldo anterior zerados
            if (asset.quota_amount_before != 0) {

                quotaValueBefore = (asset.balance_before / asset.quota_amount_before)

            } else {

                //Calcular no caso de saldo anterior zerado


            }

        }

        let quotaValueNow = 1;
        if (asset.quotas_anbima.length >= 1) {

            const preLastNowQuotaMonth = asset.quotas_anbima[asset.quotas_anbima.length - 1].valor_cota;
            quotaValueNow = preLastNowQuotaMonth == 0 ? getRealLastValueQuotaMonth(asset) : preLastNowQuotaMonth;

        } else {

            if (asset.quota_amount_now !== 0) {

                quotaValueNow = (asset.balance_now / asset.quota_amount_now)

            } else {

                //Calcular no caso de saldo anterior zerado

            }
        }

        return ((quotaValueNow / quotaValueBefore) - 1) * 100;
    }


}

/// Original C++ implementation found at http://www.wilmott.com/messageview.cfm?catid=10&threadid=38771
/// C# implementation found at http://weblogs.asp.net/esanchez/archive/2010/07/29/a-quick-and-dirty-implementation-of-excel-norminv-function-in-c.aspx
/*
 *     Compute the quantile function for the normal distribution.
 *
 *     For small to moderate probabilities, algorithm referenced
 *     below is used to obtain an initial approximation which is
 *     polished with a final Newton step.
 *
 *     For very large arguments, an algorithm of Wichura is used.
 *
 *  REFERENCE
 *
 *     Beasley, J. D. and S. G. Springer (1977).
 *     Algorithm AS 111: The percentage points of the normal distribution,
 *     Applied Statistics, 26, 118-121.
 *
 *      Wichura, M.J. (1988).
 *      Algorithm AS 241: The Percentage Points of the Normal Distribution.
 *      Applied Statistics, 37, 477-484.
 */

export function normsInv(p, mu, sigma) {
    if (p < 0 || p > 1) {
        throw "The probality p must be bigger than 0 and smaller than 1";
    }
    if (sigma < 0) {
        throw "The standard deviation sigma must be positive";
    }

    if (p == 0) {
        return -Infinity;
    }
    if (p == 1) {
        return Infinity;
    }
    if (sigma == 0) {
        return mu;
    }

    var q, r, val;

    q = p - 0.5;

    /*-- use AS 241 --- */
    /* double ppnd16_(double *p, long *ifault)*/
    /*      ALGORITHM AS241  APPL. STATIST. (1988) VOL. 37, NO. 3
            Produces the normal deviate Z corresponding to a given lower
            tail area of P; Z is accurate to about 1 part in 10**16.
    */
    if (Math.abs(q) <= .425) {/* 0.075 <= p <= 0.925 */
        r = .180625 - q * q;
        val =
            q * (((((((r * 2509.0809287301226727 +
                33430.575583588128105) * r + 67265.770927008700853) * r +
                45921.953931549871457) * r + 13731.693765509461125) * r +
                1971.5909503065514427) * r + 133.14166789178437745) * r +
                3.387132872796366608)
            / (((((((r * 5226.495278852854561 +
                28729.085735721942674) * r + 39307.89580009271061) * r +
                21213.794301586595867) * r + 5394.1960214247511077) * r +
                687.1870074920579083) * r + 42.313330701600911252) * r + 1);
    }
    else { /* closer than 0.075 from {0,1} boundary */

        /* r = min(p, 1-p) < 0.075 */
        if (q > 0)
            r = 1 - p;
        else
            r = p;

        r = Math.sqrt(-Math.log(r));
        /* r = sqrt(-log(r))  <==>  min(p, 1-p) = exp( - r^2 ) */

        if (r <= 5) { /* <==> min(p,1-p) >= exp(-25) ~= 1.3888e-11 */
            r += -1.6;
            val = (((((((r * 7.7454501427834140764e-4 +
                .0227238449892691845833) * r + .24178072517745061177) *
                r + 1.27045825245236838258) * r +
                3.64784832476320460504) * r + 5.7694972214606914055) *
                r + 4.6303378461565452959) * r +
                1.42343711074968357734)
                / (((((((r *
                    1.05075007164441684324e-9 + 5.475938084995344946e-4) *
                    r + .0151986665636164571966) * r +
                    .14810397642748007459) * r + .68976733498510000455) *
                    r + 1.6763848301838038494) * r +
                    2.05319162663775882187) * r + 1);
        }
        else { /* very close to  0 or 1 */
            r += -5;
            val = (((((((r * 2.01033439929228813265e-7 +
                2.71155556874348757815e-5) * r +
                .0012426609473880784386) * r + .026532189526576123093) *
                r + .29656057182850489123) * r +
                1.7848265399172913358) * r + 5.4637849111641143699) *
                r + 6.6579046435011037772)
                / (((((((r *
                    2.04426310338993978564e-15 + 1.4215117583164458887e-7) *
                    r + 1.8463183175100546818e-5) * r +
                    7.868691311456132591e-4) * r + .0148753612908506148525)
                    * r + .13692988092273580531) * r +
                    .59983220655588793769) * r + 1);
        }

        if (q < 0.0) {
            val = -val;
        }
    }

    return mu - (mu + sigma * val);
}

export default function normalinvOk(p, m, s) {
    function erfcinv(p) {
        var j = 0;
        var x, err, t, pp;
        if (p >= 2)
            return -100;
        if (p <= 0)
            return 100;
        pp = (p < 1) ? p : 2 - p;
        t = Math.sqrt(-2 * Math.log(pp / 2));
        x = -0.70711 * ((2.30753 + t * 0.27061) /
            (1 + t * (0.99229 + t * 0.04481)) - t);
        for (; j < 2; j++) {
            err = erfc(x) - pp;
            x += err / (1.12837916709551257 * Math.exp(-x * x) - x * err);
        }
        return (p < 1) ? x : -x;
    }

    function erfc(x) {
        return 1 - erf(x);
    }

    function erf(x) {
        var cof = [-1.3026537197817094, 6.4196979235649026e-1, 1.9476473204185836e-2,
        -9.561514786808631e-3, -9.46595344482036e-4, 3.66839497852761e-4,
            4.2523324806907e-5, -2.0278578112534e-5, -1.624290004647e-6,
            1.303655835580e-6, 1.5626441722e-8, -8.5238095915e-8,
            6.529054439e-9, 5.059343495e-9, -9.91364156e-10,
        -2.27365122e-10, 9.6467911e-11, 2.394038e-12,
        -6.886027e-12, 8.94487e-13, 3.13092e-13,
        -1.12708e-13, 3.81e-16, 7.106e-15,
        -1.523e-15, -9.4e-17, 1.21e-16,
        -2.8e-17
        ];
        var j = cof.length - 1;
        var isneg = false;
        var d = 0;
        var dd = 0;
        var t, ty, tmp, res;

        if (x < 0) {
            x = -x;
            isneg = true;
        }

        t = 2 / (2 + x);
        ty = 4 * t - 2;

        for (; j > 0; j--) {
            tmp = d;
            d = ty * d - dd + cof[j];
            dd = tmp;
        }

        res = t * Math.exp(-x * x + 0.5 * (cof[0] + ty * d) - dd);
        return isneg ? res - 1 : 1 - res;
    }

    return (0.0324 / 100) - ((-1.41421356237309505 * s * erfcinv(2 * p) + m) * (0.0271 / 100) * Math.sqrt(1));

}

/**
 * Cálculo do desvio padrão com confiança de 5%
 * @param {Array} rents rentabilidades historicas diárias (até no máximo 252)
 */
export function var5(rents, days, isPureRents) {

    console.log('RENTS VAR: ', rents);
    /////////////////////////////
    //1. Constante de confiança//
    /////////////////////////////
    const zValue95percent = -1.64485362695;

    let totalRent = 0;
    const countRents = rents.length;

    rents.forEach(r => {

        if (isPureRents) {
            totalRent += r;
        } else {
            if (r.rent_day !== null) {//Retirando primeira rent que é nula
                r.rent_day = parseFloat(r.rent_day);
                totalRent += r.rent_day;
            }
        }


    });

    /////////////////////////////////////////////////
    //2. Média das últimas 252 rentabilidades diárias
    /////////////////////////////////////////////////
    const mean = totalRent / countRents;

    /////////////////////////////
    //3. Cálculo da variância////
    /////////////////////////////
    let totalVariances = 0;
    rents.forEach(r => {
        if (isPureRents) {

            const x = (r - mean) * (r - mean);
            totalVariances += x;
        } else {
            const y = (r.rent_day - mean) * (r.rent_day - mean);
            totalVariances += y;
        }
    });

    /////////////////////////////
    //4. Cálculo do desvio padrão
    /////////////////////////////
    const desvioPadrao = Math.sqrt(totalVariances / countRents);

    ////////////////////////////////
    //5. Aplicação da fórmula do VaR
    ////////////////////////////////

    console.log("XFACTOR [" + rents.length + "] : ", (mean - (zValue95percent * desvioPadrao)));
    return (mean - (zValue95percent * desvioPadrao)) * Math.sqrt(days ? days : 1);

}


export function getMonthAndYearBefore(date) {

    const dateSplit = date.split('/');

    const monthNow = parseInt(dateSplit[1]);
    const yearNow = parseInt(dateSplit[2]);

    let monthBefore = monthNow - 1;
    let yearBefore = yearNow;
    if (monthBefore == 0) {
        monthBefore = 12;
        yearBefore = yearNow - 1;
    }

    return { monthBefore, yearBefore };
}

export function getUrlLogo(client) {

    if (!client || (client && !client.logo_path)) {
        return null;
    }


    const logoPath = LOGOS_URL + client.id + '/' + client.logo_path;
    return logoPath;
}

export function getDayMonthYearByStringDate(date) {

    const splittedDate = date.split('/');
    const day = parseInt(splittedDate[0]);
    const month = parseInt(splittedDate[1]);
    const year = parseInt(splittedDate[2]);
    return { day, month, year };
}

export function getLastMonthAfterDay15() {


}


//Ordena objetos que possuem o atributo 
// {
//     period: '1/2025'
// }
export function sortObjsByPeriod(objs) {
    return objs.sort((a, b) => {
        const [monthA, yearA] = a.period.split('/').map(Number);
        const [monthB, yearB] = b.period.split('/').map(Number);
        return yearA - yearB || monthA - monthB;
    });
}

/**
 * 
 * @param {Boolean} selectedColumnDirection 
 */
export function isAssetInitByMonthAndYear(assetInit, month, year) {

    return stringPtDateToMoment('01/' + month.toString().padStart(2, '0') + '/' + year, true).isSameOrBefore(assetInit, 'month');
}

export function getInClauseByAttr(array, attr) {

    if (!array.length) {
        return '()';
    }

    const isNumber = Number.isInteger(array[0][attr]);
    let inClause = array.reduce(
        (previous, current) => previous + (isNumber ? "" : "'") + current[attr] + (isNumber ? "" : "'") + ", ",
        "("
    )

    inClause = inClause.slice(0, -2) + ')';

    return inClause;
}

export function greenOrRed(value) {
    if (!value) {
        return unoTheme.defaultTextColor
    }
    if (value < 0) {
        return unoTheme.redMoney
    } else {
        return unoTheme.greenMoney
    }
}


export function getReferenceDate() {
    let referenceDate = getTheLastDayCompleteMonthPt();

    const splittedreferenceDate = referenceDate.split('/');
    const m = parseInt(splittedreferenceDate[1]);
    const y = parseInt(splittedreferenceDate[2]);
    if (!isMonthAbleToQuotas(m, parseInt(y))) {
        //referenceDate = getMonthAgo(parseInt(splittedreferenceDate[1]), parseInt(splittedreferenceDate[2]), 1);
        if (m == 1) {
            referenceDate = moment(new Date(y - 1, 12, 0)).format('DD/MM/YYYY');
        } else {
            referenceDate = moment(new Date(y, m - 1, 0)).format('DD/MM/YYYY');
        }
    }

    referenceDate = getFirstBeforeBusinessDay(referenceDate);
    return referenceDate;
}

export const CONSULTINGS_IDS = {
    LEMA: 1,
    SETE: 2
};

export function getConsultingId(currentUser) {
    return currentUser && currentUser.user ? currentUser.user.consulting_id : null
}

export function getConsultingNameById(consultingId) {
    const consults = {
        1: "LEMA",
        2: "SETE",
    };

    return consults[consultingId];
}

export function getPrettyRole(role) {
    const prettyRoles = {
        [roles.admin.title]: 'ADMINISTRADOR',
        [roles.advisor.title]: 'CONSULTOR',
        [roles.manager.title]: 'GESTOR',
        [roles.submanager.title]: 'SUBGESTOR',
        [roles.viewer.title]: 'VISUALIZADOR',
        [roles.superadmin.title]: 'SUPER ADMIN'
    };

    return prettyRoles[role] || '-';
};

export function getLastMonthYearAbleToQuotas() {

    let referenceDate = getTheLastDayCompleteMonthPt();
    const splittedreferenceDate = referenceDate.split('/');

    const m = parseInt(splittedreferenceDate[1]);
    const y = parseInt(splittedreferenceDate[2]);

    if (!isMonthAbleToQuotas(m, parseInt(y))) {

        return {
            month: m == 1 ? 12 : m - 1,
            year: m == 1 ? y - 1 : y
        };

    } else {

        return {
            month: m,
            year: y,
        };

    }
}

export function getDayMonthYearByPtDate(date) {

    const dateSplit = date.split('/');

    const day = parseInt(dateSplit[0]);
    const month = parseInt(dateSplit[1]);
    const year = parseInt(dateSplit[2]);

    return { day, month, year };
}

export function handleSimpleColumnOrder(newColumn, columnOrder, setColumnOrder) {

    console.log("NEW COLUMN: ", newColumn);
    console.log("columnOrder: ", columnOrder);

    const currentColumn = columnOrder.substring(0, 1);
    const currentOrder = columnOrder.substring(1);

    let toReturn = null;
    if (currentColumn != newColumn) {

        toReturn = newColumn + '1';
    } else {
        if (currentOrder == '1') {
            toReturn = newColumn + '2';
        } else {
            toReturn = newColumn + '1';
        }
    }

    setColumnOrder(toReturn);
}

export function showSimpleColumnOrderIcon(column, currentColumnOrder) {

    if (currentColumnOrder) {
        if (column != currentColumnOrder.substring(0, 1)) {
            return null;
        } else {
            const style = { verticalAlign: 'bottom' };

            console.log('currentColumn: ', currentColumnOrder.substring(1));
            if (currentColumnOrder.substring(1) == 1) {
                return <ArrowDropDown style={style} />
            } else {
                return <ArrowDropUp style={style} />
            }
        }
    } else {
        return null;
    }

}

export function getTotalApplicationsAndRescuesByTransactions(transactions) {
    if (!transactions) {
        return { totalApplications: 0, totalRescues: 0 }
    }
    const applicationsTransactions = transactions.filter(
        el =>
            el.operation_id == 1
    );

    let totalApplications = 0;
    let totalRescues = 0;

    if (applicationsTransactions && applicationsTransactions.length > 0) {

        totalApplications = applicationsTransactions.reduce(
            (previous, current) => previous + parseFloat(current.amount),
            0
        )
    }

    const rescuesTransactions = transactions.filter(
        el =>
            el.operation_id == 2
    );
    if (rescuesTransactions && rescuesTransactions.length > 0) {

        totalRescues = rescuesTransactions.reduce(
            (previous, current) => previous + parseFloat(current.amount),
            0
        )
    }

    return { totalApplications, totalRescues }
}


/**
 * Retorna o período anterior
 * período segue o padrão MES/ANO exemplo: 1/2022, 12/2023 
 * @param {String} periodString
 * @returns {String} 
 */
export function getPeriodBefore(periodString) {

    const splittedPeriod = periodString.split('/');
    const monthPeriod = parseInt(splittedPeriod[0]);
    const yearPeriod = parseInt(splittedPeriod[1]);

    let beforeMonthPeriod = monthPeriod - 1;
    let beforeYearPeriod = yearPeriod;

    if (beforeMonthPeriod == 0) {
        beforeMonthPeriod = 12;
        beforeYearPeriod--;
    }

    return beforeMonthPeriod + '/' + beforeYearPeriod;
}

/**
 * Retorna o período posterior
 * período segue o padrão MES/ANO exemplo: 1/2022, 12/2023 
 * @param {String} periodString
 * @returns {String} 
 */
export function getNextPeriod(periodString, withMonthPadStart) {

    const splittedPeriod = periodString.split('/');
    const monthPeriod = parseInt(splittedPeriod[0]);
    const yearPeriod = parseInt(splittedPeriod[1]);

    let nextMonthPeriod = monthPeriod + 1;
    let nextYearPeriod = yearPeriod;

    if (nextMonthPeriod == 13) {
        nextMonthPeriod = 1;
        nextYearPeriod++;
    }

    return (withMonthPadStart ? nextMonthPeriod.toString().padStart(2, "0") : nextMonthPeriod) + '/' + nextYearPeriod;
}

/**
 * Retorna o ano do periodo formatado em Ex: 1/2021
 * @param {String} periodString
 * @returns {String} 
 */
export function getMonthAndYearPeriod(periodString) {

    const splittedPeriod = periodString.split('/');
    const monthPeriod = parseInt(splittedPeriod[0]);
    const yearPeriod = parseInt(splittedPeriod[1]);

    return {
        month: monthPeriod,
        year: yearPeriod
    };
}

/**
 * Retorna o ano do periodo formatado em Ex: 1/2021
 * @param {String} periodString
 * @returns {String} 
 */
export function getYearPeriod(periodString) {

    const splittedPeriod = periodString.split('/');
    const yearPeriod = parseInt(splittedPeriod[1]);

    return yearPeriod;
}

/**
 * Retorna o mapa de períodos a partir do período inicial até o período final
 * @param {String} periodString
 * @returns {String} 
 */
export function getAllPeriods(initPeriodString, endPeriodString) {

    const mapPeriods = {};
    let loopPeriod = getNextPeriod(initPeriodString);

    let secureCount = 0;
    while ((loopPeriod != endPeriodString) && secureCount != 99) {

        const yearPeriod = getYearPeriod(loopPeriod);

        if (!mapPeriods[yearPeriod]) {
            mapPeriods[yearPeriod] = []
        }

        mapPeriods[yearPeriod].push(loopPeriod);

        loopPeriod = getNextPeriod(loopPeriod);
        secureCount++;
    }

    const yearPeriod = getYearPeriod(loopPeriod);
    if (!mapPeriods[yearPeriod]) {
        mapPeriods[yearPeriod] = []
    }

    mapPeriods[yearPeriod].push(loopPeriod);


    return mapPeriods;

}

export function truncateDecimals(num, digits) {
    var numS = num.toString(),
        decPos = numS.indexOf('.'),
        substrLength = decPos == -1 ? numS.length : 1 + decPos + digits,
        trimmedResult = numS.substr(0, substrLength),
        finalResult = isNaN(trimmedResult) ? 0 : trimmedResult;

    return parseFloat(finalResult);
}

export const OperationStringMap = {
    1: "Aplicação",
    2: "Resgate",
    3: "Amortização"
}

export function customTrimCNPJ(str) {
    let charArray = str.split('');
    return charArray.filter(el => el != " ").join("").padStart(18, "0");
}

export function isPeriodBefore(period1, period2) { //period no formato MM/YYYY
    // Separar mês e ano para cada período
    let [month1, year1] = period1.split("/").map(Number);
    let [month2, year2] = period2.split("/").map(Number);

    // Comparar os anos
    if (year1 > year2) {
        return false;
    } else if (year1 < year2) {
        return true;
    } else {
        // Se os anos são iguais, comparar os meses
        if (month1 > month2) {
            return false;
        } else if (month1 < month2) {
            return true;
        } else {
            return false;
        }
    }
}

export function getNovember(year) {
    let lastNovember = new Date(parseInt(year), 10, 1); // Novembro do ano atual (mês 10, pois é zero-based)

    return lastNovember;
}

export function getYearsWithNovembers(startDate, endDate) {

    const start = startDate;
    const end = endDate;

    if (start > end) {
        console.log("A data inicial deve ser anterior à data final.");
        return [];
    }

    // Inicializa o array de anos
    const yearsWithNovembers = [];

    for (let year = start.getFullYear(); year <= end.getFullYear(); year++) {
        // Verifica se há um novembro nesse ano dentro do intervalo
        // const novemberStart = new Date(year, 1, 1); // 10 = novembro (meses começam em 0)
        // const novemberEnd = new Date(year, 1, 30); // Último dia de novembro
        const novemberStart = new Date(year, 10, 1); // 10 = novembro (meses começam em 0)
        const novemberEnd = new Date(year, 10, 30); // Último dia de novembro

        if (
            (novemberStart >= start && novemberStart <= end) ||
            (novemberEnd >= start && novemberEnd <= end) ||
            (novemberStart <= start && novemberEnd >= end)
        ) {
            yearsWithNovembers.push(year);
        }
    }

    return yearsWithNovembers;
}



