import axios from "axios";

export const SUCCESS = true;
export const FAILURE = false;

/**
 * @type {'PROD' | 'HOMOLOG' | 'DEV'}
 */
const LOCAL_ENV = "PROD";

export const APP_VERSION = 'v2.4.0';

export const ENV = LOCAL_ENV;
export const isDevENV = ENV === "DEV";

const SERVER_URL = ENV === 'PROD' ? 'https://unoapp.com.br/server/' : 'http://localhost:5001/';
export const LOGOS_URL = "https://uno-prod-files.s3.sa-east-1.amazonaws.com/" + 'logos/';
const SERVER_API = SERVER_URL + 'api/v1';

export const REPORTS_URL = SERVER_URL;

export default axios.create({

    baseURL: SERVER_API,

    responseType: "json"

});

export const defaultConfig = {
    headers: {
        'x-access-token': localStorage.getItem('token'),
    }
}