import { Box, Checkbox, FormHelperText, IconButton, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import FolderIcon from '@material-ui/icons/Folder';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import Axios from 'axios';
import JSZip from 'jszip';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";
import ServerFilesController from "../../../API/server_files";
import { headerSearch, unoThemeStyles } from '../../../assets/styles/unoTheme';
import MyAutoComplete from '../../utils/MyAutoComplete';
import MyTextField from '../../utils/MyTextField';
import UnoButton from '../../utils/UnoButton';
import { CONSULTINGS_IDS } from '../../utils/utils';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    toStickyHeader: {
        display: 'flex',
        height: '100%',
    },
    dirName: {
        color: 'black',
        cursor: 'pointer',
        '&:hover': {
            color: 'blue'
        }
    },
    file: {
        cursor: 'pointer',
        '&:hover': {
            color: 'blue',
            '& svg': {
                color: 'blue'
            }
        }

    }
});

export default function FilesAndDocumentsScreen() {
    const classes = useStyles();
    const unoThemeClasses = unoThemeStyles()
    const dispatch = useDispatch();
    const [files, setFiles] = useState(null);
    const [breadcrumb, setBreadcrumb] = useState();
    const [showNewFolderRow, setShowNewFolderRow] = useState(false);
    const fileElement = useRef(null);
    const [fileValue, setFileValue] = useState('');
    const currentUser = useSelector(state => state.currentUser.user);
    const isLEMAConsulting = useMemo(() => {
        return currentUser?.consulting_id === CONSULTINGS_IDS.LEMA;
    }, [currentUser]);
    const enabledToChangeDirectory = useMemo(() => currentUser?.isSuperAdmin && isLEMAConsulting, [currentUser, isLEMAConsulting]);
    const consultingsOptions = Object.entries(CONSULTINGS_IDS).map(([key, value]) => ({
        id: value,
        label_name: key
    }));
    const [selectedConsulting, setSelectedConsulting] = useState(null);
    const NEW_FOLDER_NAME_DEFAULT_VALUE = '';
    const [newFolderName, setNewFolderName] = useState(NEW_FOLDER_NAME_DEFAULT_VALUE);
    const [checked, setChecked] = React.useState([]);
    const [isFetching, setIsFetching] = useState(false);

    const doGetFilesByDir = useCallback(async (dir = '') => {
        setIsFetching(true);
        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Carregando diretório...',
        }));

        const newInnerLoad = { show: false };
        const consultingId = selectedConsulting?.id;
        const response = await ServerFilesController.getFilesByDir({
            dir,
            consultingId
        });
        if (response.success) {
            const newBredcrumb = response.body.breadcrumb;
            setBreadcrumb(newBredcrumb);

            const newFiles = response.body.files;
            setFiles(newFiles);

            if (newFiles?.length === 0) {
                newInnerLoad.show = true;
                newInnerLoad.emptyMessage = 'Esta pasta está vazia';
            }
        }

        dispatch(allActions.innerLoadActions.setInnerLoad(newInnerLoad));
        setIsFetching(false);
    }, [dispatch, selectedConsulting]);

    async function downloadFile(path) {
        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Baixando o arquivo...',
        }));

        try {
            const { data } = await ServerFilesController.downloadFile({
                dir: path
            });
            const a = document.createElement('a');
            a.href = data.presignedUrl;
            a.click();
        } catch (error) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Erro ao baixar arquivo"
            }));
        };

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));
    }

    const currentBreadcrumb = useMemo(() => breadcrumb?.[breadcrumb?.length - 1], [breadcrumb]);

    const uploadFiles = useCallback(async (e) => {
        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Enviando arquivo(s)...',
        }));

        const files = e.target.files;
        const form = new FormData();
        form.append('path', currentBreadcrumb.path);
        for (const file of files) {
            form.append('files', file, file.name);
        };

        try {
            const { data: {
                message
            } } = await ServerFilesController.uploadFiles(form);
            await doGetFilesByDir(currentBreadcrumb.path);
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message
            }));
        } catch (error) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Erro ao criar arquivo"
            }));
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: false,
            }));
        }

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));

        setFileValue('');
    }, [currentBreadcrumb, doGetFilesByDir, dispatch]);

    async function createNewFolder(folderName) {
        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Criando pasta...',
        }));

        const folderNameTrimmed = folderName.trim();
        const folderPath = currentBreadcrumb.path + folderNameTrimmed;
        try {
            const { data: {
                message
            } } = await ServerFilesController.createNewFolder(folderPath);
            await resetCreateNewFolder();
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message
            }));
        } catch (error) {
            const message = "Erro ao criar pasta";

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message
            }));

            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: false,
            }));
        }
    }


    async function resetCreateNewFolder() {
        setShowNewFolderRow(false);
        setNewFolderName(NEW_FOLDER_NAME_DEFAULT_VALUE);
        return doGetFilesByDir(currentBreadcrumb.path);
    };

    const handleCheckboxSelection = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    useEffect(() => {
        if (selectedConsulting) {
            doGetFilesByDir();
        }
    }, [selectedConsulting]);

    useEffect(() => {
        if (currentUser) {
            const defaultValue = consultingsOptions.find(option => option.id === currentUser?.consulting_id);
            setSelectedConsulting(defaultValue);
        }
    }, [currentUser]);

    return (
        <div className={classes.root}>
            <div className={classes.headerSearch}>
                <Typography variant="h6" gutterBottom align="left">
                    {breadcrumb?.map((crumb, index) => {
                        const isLast = index === breadcrumb.length - 1;

                        return (
                            <span className={classes.dirName} onClick={() => doGetFilesByDir(crumb.path)}>{crumb.label}{!isLast && ' > '}</span>
                        );
                    })}
                </Typography>

                <Box className={unoThemeClasses.headerSearchRightSectionWrapper}>
                    {enabledToChangeDirectory && (
                        <>
                            <MyAutoComplete
                                disabled={isFetching}
                                options={consultingsOptions}
                                autoComplete
                                customWidth='200px'
                                value={selectedConsulting}
                                onChange={(event, newConsulting) => {
                                    setSelectedConsulting(newConsulting)
                                }}
                                placeholder={"Selecione a consultoria"}
                                getOptionLabel={(option) => option.label_name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderOption={(option) => (
                                    <React.Fragment key={'autocomplete-' + option.id}>
                                        <span>{option.label_name}</span>
                                    </React.Fragment>
                                )}
                            />
                            <UnoButton
                                isIconButton
                                size='small'
                                type='error'
                                myIcon={<DeleteIcon />}
                                onClick={async () => {
                                    if (checked.length > 0) {
                                        try {
                                            dispatch(allActions.innerLoadActions.setInnerLoad({
                                                show: true,
                                                loadMessage: 'Deletando arquivo(s)...',
                                            }));

                                            await ServerFilesController.deleteByPaths(checked);

                                            dispatch(allActions.mainAlertActions.setMainAlert({
                                                show: true,
                                                type: 'success',
                                                message: 'Diretório(s) deletado(s) com sucesso'
                                            }));

                                            setChecked([]);

                                            await doGetFilesByDir(currentBreadcrumb.path);
                                        } catch (error) {
                                            dispatch(allActions.mainAlertActions.setMainAlert({
                                                show: true,
                                                type: 'error',
                                                message: "Erro ao deletar diretório(s)"
                                            }));
                                        }

                                        dispatch(allActions.innerLoadActions.setInnerLoad({
                                            show: false,
                                        }));
                                    }
                                }}
                            />
                        </>
                    )}


                    <UnoButton
                        isIconButton
                        size='small'
                        type='success'
                        myIcon={<GetAppIcon />}
                        onClick={async () => {
                            if (checked.length === 1 && !checked[0].endsWith('/')) {
                                return downloadFile(checked[0]);
                            }

                            if (checked.length >= 1) {
                                try {
                                    dispatch(allActions.innerLoadActions.setInnerLoad({
                                        show: true,
                                        loadMessage: 'Baixando arquivo(s)...',
                                    }));

                                    const filesResponse = await ServerFilesController.downloadRecursive(checked);
                                    const presignedUrls = Object.entries(filesResponse.data.presignedUrls);

                                    const zip = new JSZip();
                                    for (const [path, presignedUrl] of presignedUrls) {
                                        const response = await Axios.get(presignedUrl, { responseType: 'blob' });
                                        const fileName = path.replace(currentBreadcrumb.path, '');

                                        zip.file(fileName, response.data, {
                                            createFolders: true
                                        });
                                    }

                                    const content = await zip.generateAsync({ type: "blob" });

                                    const link = document.createElement("a");
                                    link.href = URL.createObjectURL(content);
                                    link.download = "arquivos_e_documentos.zip";
                                    link.click();

                                    dispatch(allActions.mainAlertActions.setMainAlert({
                                        show: true,
                                        type: 'success',
                                        message: 'Diretório(s) baixado(s) com sucesso'
                                    }));

                                    setChecked([]);

                                    await doGetFilesByDir(currentBreadcrumb.path);
                                } catch (error) {
                                    dispatch(allActions.mainAlertActions.setMainAlert({
                                        show: true,
                                        type: 'error',
                                        message: "Erro ao baixar diretório(s)"
                                    }));
                                }

                                dispatch(allActions.innerLoadActions.setInnerLoad({
                                    show: false,
                                }));
                            }
                        }}
                    />

                    {enabledToChangeDirectory && (
                        <>
                            <UnoButton
                                isIconButton
                                size='small'
                                type='warning'
                                disabled={showNewFolderRow}
                                onClick={() => {
                                    dispatch(allActions.innerLoadActions.setInnerLoad({
                                        show: false,
                                    }));
                                    setShowNewFolderRow(true)
                                }}
                                myIcon={<CreateNewFolderIcon />}
                            />
                            <UnoButton
                                isIconButton
                                size='small'
                                type='primary'
                                myIcon={<PublishIcon />}
                                onClick={() => {
                                    fileElement.current.click()
                                }}
                            />
                            <input
                                type="file"
                                name="file"
                                value={fileValue}
                                onChange={uploadFiles}
                                ref={fileElement}
                                multiple
                                hidden
                            />
                        </>
                    )}
                </Box>

            </div>
            <div class={'defaultScrollWithHeaderContent'}>
                <List component="nav"
                    style={{ width: '100%', padding: '16px', overflowY: 'scroll' }}
                    aria-label="main mailbox folders">
                    {showNewFolderRow && (
                        <ListItem
                            key={`new-folder-row-${files?.length}`}
                            style={{ width: '100%' }}
                        >
                            <ListItemIcon>
                                <FolderIcon style={{ color: '#e1b12c' }} />
                            </ListItemIcon>
                            <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                                <ListItemText primary={
                                    <>
                                        <Box style={{
                                            display: 'flex',
                                            gap: 4
                                        }}>
                                            <MyTextField required
                                                id={`new-folder-${files?.length}`}
                                                verysmall
                                                variant="outlined"
                                                value={newFolderName}
                                                onChange={(value) => setNewFolderName(value)}
                                                onBlur={resetCreateNewFolder}
                                                onKeyDown={async (event) => {
                                                    const ESC_KEY_CODE = 27;
                                                    const ENTER_KEY_CODE = 13;
                                                    const isNewFolderNameEmpty = newFolderName.trim() !== '';
                                                    if (event.keyCode === ENTER_KEY_CODE && isNewFolderNameEmpty) {
                                                        return createNewFolder(newFolderName);
                                                    } else if (event.keyCode === ESC_KEY_CODE) {
                                                        return resetCreateNewFolder();
                                                    }
                                                }}
                                                placeholder="Insira o nome da nova pasta..."
                                                autoFocus
                                            />
                                            <IconButton
                                                aria-label={`cancel-new-folder-${files?.length}`}
                                                disableRipple
                                                style={{
                                                    padding: 0,
                                                    background: 'none'
                                                }}
                                                onClick={resetCreateNewFolder}
                                            >
                                                <CancelIcon color="error" />
                                            </IconButton>
                                        </Box>
                                        <FormHelperText>
                                            Após preenchido, aperte <strong>Enter</strong>.
                                        </FormHelperText>
                                    </>
                                }
                                />
                            </div>
                        </ListItem>
                    )}
                    {files && files.map((row, index) => {
                        const key = `file-in-dir-${row.fileName}-${index}`;
                        const labelId = row.path;

                        return (
                            <ListItem
                                key={key}
                                onClick={() => (row.isDir ? doGetFilesByDir : downloadFile)(row.path)}
                                button={row.isDir}
                                style={{ width: '100%' }}
                                className={!row.isDir ? classes.file : ''}
                            >
                                <ListItemIcon style={{
                                    alignItems: 'center'
                                }}>
                                    <Checkbox
                                        checked={checked.indexOf(labelId) !== -1}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleCheckboxSelection(labelId);
                                        }}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                    {
                                        row.isDir ?
                                            <FolderIcon style={{ color: '#e1b12c' }} />
                                            :
                                            <DescriptionIcon style={{ color: '#9c88ff' }} />
                                    }
                                    <span
                                        style={{
                                            marginRight: '20px'
                                        }}
                                    />
                                </ListItemIcon>

                                <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                                    <ListItemText primary={row.fileName} />
                                    {
                                        !row.isDir ?
                                            <span style={{ fontSize: '10px' }}>Clique para baixar</span> : null
                                    }
                                </div>
                            </ListItem>
                        )
                    })}
                </List>
            </div>
        </div >
    );
}



