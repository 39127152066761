import API from './config';

const ServerFilesController = {
    getFilesByDir: async (body) => {

        let config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.post('/server_files/getFilesByDir', body, config)

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            console.log("ERROR");

            return {
                success: false,
                body: error.response ? error.response.data : 'NO_DATA_ERROR'
            };
        }

    },

    /**
     * 
     * @param {string[]} filePaths 
     * @returns {Promise<import('axios').AxiosResponse<{
     *  presignedUrl: string;
     * }>>}
     */
    downloadFile: async (body) => {
        /**
         * @type {import('axios').AxiosRequestConfig}
         */
        let config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            },
        };

        return API.post('/server_files/download', body, config);
    },
    downloadRecursive: async (dir) => {
        let config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        };

        return API.post('/server_files/downloadRecursive', { dir }, config);
    },
    /**
     * 
     * @param {string} folder
     */
    createNewFolder: async (folder) => {
        let config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        };

        return API.post('/server_files/createNewFolder', { folder }, config);
    },
    uploadFiles: (formData) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-access-token': localStorage.getItem('token'),
            }
        };

        return API.post(`/server_files/uploadFiles`, formData, config);
    },
    deleteByPaths: async (keys) => {
        let config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            },
            data: { keys }
        };

        return API.delete('/server_files/deleteByPaths', config);
    },
    downloadByPaths: async (keys) => {
        let config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            },
            data: { keys }
        };

        return API.get('/server_files/downloadByPaths', config);
    }
};

export default ServerFilesController;