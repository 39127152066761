import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { getConsultingId, getUrlLogo } from '../../utils/utils';

//Text field 
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import MyDatePicker from '../../utils/MyDatePicker';
import MySelect from '../../utils/MySelect';
import MySwitch from '../../utils/MySwitch';
import MyTextField from '../../utils/MyTextField';
import { isValidCnpj, justNumbers } from '../../utils/utils';


//UnoButton

//getSuitabilityOptions
import { getSuitabilityIndex, getSuitabilityOptions } from '../../utils/suitability';

//Grid
import { Grid, Tooltip } from "@material-ui/core";

//API
import { getInfosByCep } from '../../../API/cep';
import { getCitByCityNameAndUf } from '../../../API/city';
import { createClient, getClientsByCityId, listAllActiveClients, updateClient, updateLogoClient } from '../../../API/client';

//Utils
import { isValidCep } from '../../utils/utils';

//Modals
import SelectAndCropImageModal from '../../modals/SelectAndCropImageModal';

//Redux
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";



import moment from 'moment';
import { headerSearch } from "../../../assets/styles/unoTheme";

// Utils
import _, { isEmpty } from 'lodash';
import { roles } from '../../utils/user.js';

const LightTooltip = withStyles((theme) => ({
    arrow: {
        "&:before": {
            border: "1px solid #000000"
        },
        color: theme.palette.common.white
    },
    tooltip: {
        fontSize: '15px',
        backgroundColor: theme.palette.common.white,
        border: "1px solid #000000",
        color: "#000000"
    }
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
    },

    main: {
        padding: theme.spacing(2),
    },

    headerSearch: headerSearch,
    switchs: {

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',

        '& .MuiTypography-root': {
            marginTop: '-2px',
        }

    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },

    buttonsHeader: {
        display: 'flex',
        alignItems: 'center',
    },

    maginRight2: {
        marginRight: theme.spacing(2),
    },
    input: {
        display: 'none',
    },

    imageSelected: {
        width: '100px',
        borderRadius: '10px',
        float: 'left',
        cursor: 'pointer',
        //border: 'solid 1px ' + unoTheme.mainColor
    }

}));

export default function NewClientScreen({
    clientOnScreen,
    flagSaveClient,
    flagUpdateClient }) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const clientOn = useSelector(state => state.clientOn)
    const currentUser = useSelector(state => state.currentUser)

    const location = useLocation();
    const history = useHistory();

    const [listPromanagements,] = useState([
        'Não',
        'PG I',
        'PG II',
        'PG III',
        'PG IV',
    ])

    const [listSuitabilityOptions,] = useState(getSuitabilityOptions())

    //form new client
    const [submitted, setSubmitted] = useState(false);

    const [client, setClient] = useState(undefined)
    const [initClient, setInitClient] = useState(undefined);
    //CLIENT
    const [inputClientNameRpps, setInputClientNameRpps] = useState("");
    function changeClientNameRpps(value) {
        setInputClientNameRpps(value)
    }

    const [inputClientInitialRpps, setInputClientInitialRpps] = useState("");
    const [openClientInitialValidatedTooltip, setOpenClientInitialValidatedTooltip] = useState(false);

    function containsSpecialChars(str) {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return specialChars.test(str);
    }

    function changeClientInitialRpps(value) {
        let newValue = value;
        if (containsSpecialChars(newValue)) {
            newValue = newValue.replace(/[^a-zA-Z0-9 ]/g, '');;
            //newValue = newValue.replace('/', '');
            setOpenClientInitialValidatedTooltip(true);
        }
        setInputClientInitialRpps(newValue)
    }

    const [inputClientCep, setInputClientCep] = useState("");
    const [infosCep, setInfosCep] = useState("");

    async function changeClientCep(value) {

        if (value.length <= 9) {

            setInputClientCep(value)
            if (value.length == 9) {

                setInfosCep("searching");

                let responseCep = await getInfosByCep(value);

                if (responseCep.body.erro) {
                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'warning',
                        message: "CEP não encontrado"
                    }));

                    setInfosCep("");
                    setInputClientStreet("");
                    setInputClientNeighborhood("");
                    setInputClientCity("");
                    setInputClientUF("");
                } else {
                    setInfosCep(responseCep.body);
                    setInputClientStreet(responseCep.body.logradouro);
                    setInputClientNeighborhood(responseCep.body.bairro);
                    setInputClientCity(responseCep.body.localidade);
                    setInputClientUF(responseCep.body.uf);
                }
            }

        }
    }

    const [inputClientStreet, setInputClientStreet] = useState("");
    function changeClientStreet(value) {
        setInputClientStreet(value)
    }

    const [inputClientNeighborhood, setInputClientNeighborhood] = useState("");
    function changeClientNeighborhood(value) {
        setInputClientNeighborhood(value)
    }

    const [inputClientNumberAddress, setInputClientNumberAddress] = useState("");
    function changeClientNumberAddress(value) {
        setInputClientNumberAddress(value)
    }

    const [inputClientComplementAddress, setInputClientComplementAddress] = useState("");
    function changeClientComplementAddress(value) {
        setInputClientComplementAddress(value)
    }

    const [inputClientCity, setInputClientCity] = useState("");
    function changeClientCity(value) {
        setInputClientCity(value)
    }

    const [inputClientUF, setInputClientUF] = useState("");
    function changeClientUF(value) {
        setInputClientUF(value)
    }

    const [inputClientInstituitionalEmail, setInputClientInstituitionalEmail] = useState("");
    function changeClientInstituitionalEmail(value) {
        setInputClientInstituitionalEmail(value)
    }

    const [inputClientCnpj, setInputClientCnpj] = useState("");
    function changeClientCnpj(value) {
        setInputClientCnpj(value)
    }

    // The first commit of Material-UI
    //const [selectedDate, setSelectedDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    const [selectedDate, setSelectedDate] = useState(new Date(2020, 12, 0));
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [inputClientPortfolioSegregated, setInputClientPortfolioSegregated] = useState(false);
    const changePortfolioSegregated = (event) => {
        setInputClientPortfolioSegregated(event.target.checked);
    };

    const [inputClientUfRpps, setInputClientUfRpps] = useState(false);
    const changeClientUfRpps = (event) => {
        setInputClientAssistRpps(false);
        setInputClientUfRpps(event.target.checked);
    };

    const [inputClientAssistRpps, setInputClientAssistRpps] = useState(false);
    const changeClientAssistRpps = (event) => {
        setInputClientUfRpps(false)
        setInputClientAssistRpps(event.target.checked);
    };

    const [inputClientPromanagement, setInputClientPromanagement] = useState(0);
    const [inputClientSuitability, setInputClientSuitability] = useState(null);

    async function updateActiveClients() {

        const response = await listAllActiveClients(getConsultingId(currentUser));

        if (response.success) {
            dispatch(allActions.clientActions.setActiveClients(response.body.rows));
        }

    }



    //Upload LOGO
    const inputImageFile = useRef(null);
    const [showSelectAndCropImage, setShowSelectAndCropImage] = useState(false)
    const [imgSrc, setImgSrc] = useState('');
    const [cropSelected, setCropSelected] = useState('');

    function doClickInputImageFile() {
        inputImageFile.current.click()
    }

    function handleActionSelectedAndCropImageModal(action) {
        if (action) {
            console.log('action: ', action);
            const dataUrlBase64 = action.toDataURL()
            console.log('BASE 64: ', dataUrlBase64);
            setCropSelected(dataUrlBase64);
        }
        setShowSelectAndCropImage(false)
    }

    function onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {

            setShowSelectAndCropImage(true);
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    async function doUpdateLogoClient(clientId, logo) {
        const reponseUpdateLogo = await updateLogoClient(clientId, logo);
        return reponseUpdateLogo;
    }

    async function doSaveClient(event) {

        event.preventDefault();

        setSubmitted(true);

        let client = {
            rpps_name: inputClientNameRpps,
            rpps_name_initial: inputClientInitialRpps,
            cpf_cnpj: inputClientCnpj,
            street_address: inputClientStreet,
            number_address: inputClientNumberAddress,
            neighborhood: inputClientNeighborhood,
            complement: inputClientComplementAddress,
            cep: inputClientCep,
            city_id: "",
            institutional_email: inputClientInstituitionalEmail,
            portfolio_segregated: inputClientPortfolioSegregated,
            portfolio_init: moment.utc(selectedDate).format("DD/MM/YYYY"),
            promanagement_code: inputClientPromanagement,
            suitability: inputClientSuitability != null ? listSuitabilityOptions[inputClientSuitability] : null,
            is_uf_rpps: inputClientUfRpps,
            is_assist_rpps: inputClientAssistRpps,
            consulting_id: getConsultingId(currentUser)
        }

        //validação prévia
        if (isValidCnpj(justNumbers(client.cpf_cnpj))
            && isValidCep(justNumbers(client.cep))
            && client.rpps_name != ""
            && client.street_address != ""
            && client.neighborhood != ""
            && client.suitability !== null
            && client.consulting_id) {

            setSubmitted(false);

            // dispatch(allActions.fullLoadActions.setFullLoad({
            //     show: true,
            //     message: "Cadastrando cliente..."
            // }))

            const responseCity = await getCitByCityNameAndUf(inputClientCity, inputClientUF)


            if (responseCity.success && responseCity.body) {

                const { id: cityId, state_id } = responseCity.body;

                client.city_id = cityId;

                //checando se já existe cliente cadastrado para esse municipio
                const responseCheck = await getClientsByCityId(client.city_id);
                console.log('responseCheck: ', responseCheck);
                if (responseCheck.success) {

                    let normalRppsAble = true;
                    let ufRppsAble = true;
                    let assistRppsAble = true;

                    if (responseCheck.body && responseCheck.body.rows && responseCheck.body.rows.length > 0) {

                        responseCheck.body.rows.forEach(element => {

                            console.log('element.is_uf_rpps: ', element.is_uf_rpps);
                            console.log('element.is_assist_rpps: ', element.is_assist_rpps);

                            if (element.is_uf_rpps) {
                                ufRppsAble = false;
                            }
                            if (element.is_assist_rpps) {
                                assistRppsAble = false;
                            }
                            if (!element.is_uf_rpps && !element.is_assist_rpps) {
                                normalRppsAble = false;
                            }

                        });

                    }

                    console.log('normalRppsAble: ', normalRppsAble);
                    console.log('ufRppsAble: ', ufRppsAble);
                    console.log('assistRppsAble: ', assistRppsAble);

                    if (client.is_uf_rpps && !ufRppsAble) {

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'error',
                            message: "Já existe cadastro de RPPS para esse ESTADO"

                        }));

                        return;

                    }

                    if (client.is_assist_rpps && !assistRppsAble) {

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'error',
                            message: "Já existe cadastro de RPPS ASSISTÊNCIA para esse MUNICÍPIO"

                        }));

                        return;

                    }

                    if (!client.is_assist_rpps && !client.is_uf_rpps && !normalRppsAble) {

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'error',
                            message: "Já existe cadastro de RPPS para esse MUNICÍPIO. Considere marcar uma das opções UF RPPS ou ASSIST RPPS"

                        }));

                        return;

                    }

                    const response = await createClient({
                        ...client,
                        uf_rpps_id: client.is_uf_rpps ? state_id : null,
                    });

                    if (response.success) {

                        console.log("RESPONSE CLIENTE CREATE: ", response);
                        console.log("cropSelected: ", cropSelected);

                        if (cropSelected) {
                            const responseUpdateImage = await doUpdateLogoClient(response.body.client_id, cropSelected);
                        }

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'success',
                            message: "Cliente cadastrado com sucesso"

                        }));

                        clearForm();

                        updateActiveClients();

                        dispatch(allActions.fullLoadActions.setFullLoad({
                            show: false,
                        }))

                        history.push('/administration/clients');

                    } else {

                        dispatch(allActions.fullLoadActions.setFullLoad({
                            show: false,
                        }))

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'error',
                            message: "Erro ao cadastrar o cliente: " + response.body.message

                        }));
                    }



                } else {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: "Erro ao verificar possíveis cadastros pelo ID do municipio"

                    }));
                }


            } else {

                dispatch(allActions.fullLoadActions.setFullLoad({
                    show: false,
                }))
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: "Erro ao obter código de cidade"

                }));
            }


        } else {



            console.log("Não passou pre validação")
            console.log("moment.isDate(selectedDate): ", moment.isDate(selectedDate))
            console.log("suitability ", inputClientSuitability)
            console.log("selectedDate: ", selectedDate)

        }


    }

    async function doUpdateClient(event) {

        event.preventDefault();

        setSubmitted(true);

        let newClient = {
            rpps_name: inputClientNameRpps,
            rpps_name_initial: inputClientInitialRpps,
            cpf_cnpj: inputClientCnpj,
            street_address: inputClientStreet,
            number_address: inputClientNumberAddress,
            neighborhood: inputClientNeighborhood,
            complement: inputClientComplementAddress,
            cep: inputClientCep,
            city_id: "",
            institutional_email: inputClientInstituitionalEmail,
            portfolio_segregated: inputClientPortfolioSegregated,
            portfolio_init: moment.utc(selectedDate).format("DD/MM/YYYY"),
            //portfolio_init: selectedDate,
            promanagement_code: inputClientPromanagement,
            suitability: inputClientSuitability != null ? listSuitabilityOptions[inputClientSuitability] : null,
            consulting_id: getConsultingId(currentUser)
        }

        //validação prévia
        if (isValidCnpj(newClient.cpf_cnpj)
            && isValidCep(newClient.cep)
            && newClient.rpps_name != ""
            && newClient.street_address != ""
            && newClient.neighborhood != ""
            && newClient.suitability != null
            && newClient.consulting_id) {

            setSubmitted(false);

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: true,
                message: "Atualizando cliente..."
            }))

            const responseCity = await getCitByCityNameAndUf(inputClientCity, inputClientUF)

            if (responseCity.success && responseCity.body) {

                const { id: cityId, state_id } = responseCity.body;

                newClient.city_id = cityId;

                if (!_.isEqual(initClient, newClient)) {

                    let response = await updateClient(client.id, newClient);

                    if (response.success) {


                        let newLogoPath = null;
                        if (cropSelected) {

                            const responseUpdateImage = await doUpdateLogoClient(client.id, cropSelected);
                            if (responseUpdateImage.success) {
                                console.log("NEW LOGO PATH: ", responseUpdateImage.body.path);
                                newLogoPath = responseUpdateImage.body.path;
                            }

                        }

                        //updateActiveClients();

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'success',
                            message: "Cliente atualizado com sucesso"

                        }));

                        console.log("RESPONSE UPATE OK: ", response)

                        if (clientOn
                            && clientOn.client
                            && clientOn.client.id == response.body.id) {

                            clientOn.client.updateInfosClient = true;
                            if (newLogoPath) {
                                response.body.logo_path = newLogoPath;
                            }
                            let updatedClient = {
                                ...clientOn.client,
                                ...response.body
                            }

                            console.log("Atualizando cliente: ", updatedClient);
                            dispatch(allActions.clientActions.setClient(updatedClient))
                        }

                        dispatch(allActions.fullLoadActions.setFullLoad({
                            show: false,
                        }))

                        history.push('/administration/clients');

                    } else {

                        console.log("RESPONSE ERROR: ", response)

                        dispatch(allActions.fullLoadActions.setFullLoad({
                            show: false,
                        }))

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'error',
                            message: "Erro ao cadastrar o cliente: " + response.body.message

                        }));
                    }

                } else {

                    if (cropSelected) {

                        const responseUpdateImage = await doUpdateLogoClient(client.id, cropSelected);

                        dispatch(allActions.fullLoadActions.setFullLoad({
                            show: false,
                        }))
                        if (responseUpdateImage.success) {

                            dispatch(allActions.mainAlertActions.setMainAlert({
                                show: true,
                                type: 'success',
                                message: "Cliente atualizado com sucesso"

                            }));
                        } else {
                            dispatch(allActions.mainAlertActions.setMainAlert({
                                show: true,
                                type: 'error',
                                message: "Falha ao atualizar a logo"

                            }));
                        }


                    } else {

                        dispatch(allActions.fullLoadActions.setFullLoad({
                            show: false,
                        }))

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'warning',
                            message: "Nenhuma alteração foi detectada"

                        }));
                    }


                }





            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: "Erro ao obter código de cidade"

                }));
            }


        } else {
            console.log("Não passou pre validação")
            console.log("suitability ", inputClientSuitability)
        }


    }

    function clearForm() {

        setInputClientNameRpps("");
        setInputClientInitialRpps("");
        setInputClientCep("");
        setInfosCep("");
        setInputClientStreet("")
        setInputClientNumberAddress("");
        setInputClientNeighborhood("");
        setInputClientComplementAddress("");
        setInputClientCity("");
        setInputClientUF("");
        setInputClientCnpj("");
        setInputClientInstituitionalEmail("");
        setSelectedDate(new Date())
        setInputClientPortfolioSegregated(false);
        setInputClientPromanagement(0);
        setInputClientSuitability(null);
        setInputClientUfRpps(false);
        setInputClientAssistRpps(false);
    }

    function initForm(client) {

        console.log("CLIENT TO UPDATE: ", client);
        console.log("CLIENT TO UPDATE: ", moment.utc(client.portfolio_init).format("DD/MM/YYYY"));


        setInputClientNameRpps(client.rpps_name);
        setInputClientInitialRpps(client.rpps_name_initial);
        setInputClientCep(client.cep);
        setInfosCep("");
        setInputClientStreet(client.street_address)
        setInputClientNumberAddress(client.number_address);
        setInputClientNeighborhood(client.neighborhood);
        setInputClientComplementAddress(client.complement);
        setInputClientCity(client.city_name);
        setInputClientUF(client.uf);
        setInputClientCnpj(client.cpf_cnpj);
        setInputClientInstituitionalEmail(client.institutional_email);
        setSelectedDate(moment.utc(client.portfolio_init))
        setInputClientPortfolioSegregated(client.portfolio_segregated);
        setInputClientPromanagement(client.promanagement_code);
        setInputClientSuitability(getSuitabilityIndex(client.suitability));
        setInputClientUfRpps(client.is_uf_rpps);
        setInputClientAssistRpps(client.is_assist_rpps);

        setInitClient({
            rpps_name: client.rpps_name,
            rpps_name_initial: client.rpps_name_initial,
            cpf_cnpj: client.cpf_cnpj,
            street_address: client.street_address,
            number_address: client.number_address,
            neighborhood: client.neighborhood,
            complement: client.complement,
            cep: client.cep,
            city_id: client.city_id,
            institutional_email: client.institutional_email,
            portfolio_segregated: client.portfolio_segregated,
            portfolio_init: moment.utc(client.portfolio_init).format("MM/YYYY"),
            promanagement_code: client.promanagement_code,
            suitability: client.suitability
        })
    };

    const isSearchingByCEP = infosCep === "searching";

    const isCurrentUserRoleManager = currentUser.user && currentUser.user.role === roles.manager.title;

    useEffect(() => {

        if (!client) {

            if (clientOnScreen) {

                setClient(clientOnScreen)

            }
            // if (location && location.state) {

            //     setClient(location.state.client)

            // }

        } else {
            console.log("Passou aqui");
            initForm(client);
        }

        if (flagSaveClient) {

            doSaveClient(flagSaveClient);

        }

        if (flagUpdateClient) {

            doUpdateClient(flagUpdateClient);

        }


    }, [client,
        clientOnScreen,
        flagSaveClient,
        flagUpdateClient]);

    return (
        <div className={classes.root}>

            <div class='defaultScrollWithHeaderContent'>
                <div className={classes.main}>
                    <form className={classes.rootForm} noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={7} md={7}>
                                <MyTextField required
                                    id="client_name_rpps"
                                    outerlabel="Nome do RPPS"
                                    size={'small'}
                                    variant="outlined"
                                    value={inputClientNameRpps}
                                    validator={inputClientNameRpps == "" && submitted ? true : undefined}
                                    helperText={"Insira o nome do RPPS"}
                                    onChange={changeClientNameRpps} />
                            </Grid>
                            <Grid item xs={12} sm={5} md={5}>
                                <LightTooltip open={openClientInitialValidatedTooltip}
                                    arrow
                                    interactive={true}
                                    title='Caracteres especiais como ( \ / : * ? " < > | ) não são permitidos'
                                    placement="top">
                                    <MyTextField
                                        id="client_initial_rpps"
                                        outerlabel="Sigla"
                                        size={'small'}
                                        variant="outlined"
                                        onBlur={() => setOpenClientInitialValidatedTooltip(false)}
                                        value={inputClientInitialRpps}
                                        helperText={"Insira a sigla"}
                                        onChange={changeClientInitialRpps} />
                                </LightTooltip>

                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <MyTextField
                                    id="client_cep"
                                    disabled={isSearchingByCEP || isCurrentUserRoleManager}
                                    value={inputClientCep}
                                    onChange={changeClientCep}
                                    size={'small'}
                                    variant="outlined"
                                    validator={!isValidCep(inputClientCep) && submitted ? true : undefined}
                                    helperText={"Insira o CEP"}
                                    mask={['cep']}
                                    outerlabel="CEP"
                                    endadornment={
                                        <>
                                            {
                                                infosCep == "searching" ?
                                                    <InputAdornment position="end">
                                                        <CircularProgress className={classes.progressInput} />
                                                    </InputAdornment> : null

                                            }
                                        </>

                                    }
                                />


                            </Grid>
                            <Grid item xs={12} sm={7} md={7}>
                                <MyTextField required
                                    id="client_street"
                                    outerlabel="Rua"
                                    // disabled={infosCep == ""
                                    //     || infosCep == "searching"
                                    //     || (infosCep.logradouro && infosCep.logradouro != "")}
                                    size={'small'}
                                    variant="outlined"
                                    value={inputClientStreet}
                                    validator={infosCep != "" && infosCep != 'searching' ? inputClientStreet == "" && submitted : null}
                                    helperText={"Insira a rua"}
                                    onChange={(value) => changeClientStreet(value)} />
                            </Grid>
                            <Grid item xs={12} sm={2} md={2}>
                                <MyTextField
                                    id="client_number_address"
                                    disabled={isEmpty(infosCep) || isSearchingByCEP || isCurrentUserRoleManager}
                                    value={inputClientNumberAddress}
                                    onChange={(value) => changeClientNumberAddress(value)}
                                    size={'small'}
                                    variant="outlined"
                                    mask={['onlyNumbers']}
                                    outerlabel="N°" />
                            </Grid>

                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <MyTextField
                                    id="client_complement_address"
                                    disabled={isEmpty(infosCep) || isSearchingByCEP || isCurrentUserRoleManager}
                                    value={inputClientComplementAddress}
                                    size={'small'}
                                    onChange={(value) => changeClientComplementAddress(value)}
                                    variant="outlined"
                                    outerlabel="Complemento" />
                            </Grid>

                            <Grid item xs={12} sm={3} md={3}>
                                <MyTextField
                                    id="client_neighborhood"
                                    // disabled={infosCep == ""
                                    //     || infosCep == "searching"
                                    //     || (infosCep.bairro && infosCep.bairro != "")}
                                    value={inputClientNeighborhood}
                                    variant="outlined"
                                    size={'small'}
                                    onChange={(value) => changeClientNeighborhood(value)}
                                    validator={infosCep != "" && infosCep != 'searching' ? inputClientNeighborhood == "" && submitted : null}
                                    helperText={"Insira o bairro"}
                                    outerlabel="Bairro" />
                            </Grid>

                            <Grid item xs={12} sm={3} md={3}>
                                <MyTextField
                                    id="client_city"
                                    disabled={true}
                                    value={inputClientCity}
                                    variant="outlined"
                                    size={'small'}
                                    onChange={(value) => changeClientCity(value)}
                                    helperText={"Insira a cidade"}
                                    outerlabel="Cidade" />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3} lg={3} className={classes.switchs}>

                                <MySwitch
                                    disabled={isCurrentUserRoleManager || clientOnScreen}
                                    outerlabel={'UF RPPS?'}
                                    checked={inputClientUfRpps}
                                    onChange={changeClientUfRpps}
                                    color="primary" />

                                <MySwitch
                                    disabled={isCurrentUserRoleManager || clientOnScreen}
                                    outerlabel={'ASSIST RPPS?'}
                                    checked={inputClientAssistRpps}
                                    onChange={changeClientAssistRpps}
                                    color="primary" />

                                <div style={{ width: '80px' }}>
                                    <MyTextField
                                        id="client_uf"
                                        disabled={true}
                                        value={inputClientUF}
                                        variant="outlined"
                                        size={'small'}
                                        onChange={(value) => changeClientUF(value)}
                                        helperText={"Insira a UF"}
                                        outerlabel="UF" />
                                </div>



                            </Grid>

                            <Grid item xs={12} sm={3} md={3}>
                                <MyTextField
                                    id="client_cnpj"
                                    value={inputClientCnpj}
                                    variant="outlined"
                                    size={'small'}
                                    onChange={changeClientCnpj}
                                    validator={!isValidCnpj(inputClientCnpj) && submitted ? true : undefined}
                                    helperText={"Insira um CNPJ válido"}
                                    mask={["cnpj"]}
                                    outerlabel="CNPJ" />
                            </Grid>

                            <Grid item xs={12} sm={4} md={4}>
                                <MyTextField
                                    id="client_institutional_email"
                                    value={inputClientInstituitionalEmail}
                                    variant="outlined"
                                    size={'small'}
                                    onChange={changeClientInstituitionalEmail}
                                    outerlabel="Email institucional" />
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} lg={2}>

                                <MyDatePicker
                                    disabled={isCurrentUserRoleManager}
                                    className={classes.myDatePicker}
                                    views={["month", "year"]}
                                    margin="dense"
                                    id="date-picker-dialog"
                                    outerlabel="Início da carteira"
                                    format="MM/yyyy"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                // validator={!moment.isDate(selectedDate) && submitted ? true : undefined}
                                // helperText={"Data inválida"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3} className={classes.switchs}>
                                <MySelect
                                    disabled={isCurrentUserRoleManager}
                                    outerlabel={'PRÓ-GESTÃO'}
                                    value={inputClientPromanagement}
                                    onChange={(e) => setInputClientPromanagement(e.target.value)}
                                    options={listPromanagements.map((row, index) => (
                                        <option key={'client-promanagement-' + index} value={index}>{row}</option>
                                    ))}
                                />
                                <MySwitch
                                    disabled={isCurrentUserRoleManager}
                                    outerlabel={'Segregado?'}
                                    checked={inputClientPortfolioSegregated}
                                    onChange={changePortfolioSegregated}
                                    name="portfolioSegregated"
                                    color="primary" />
                                {/* <MySwitch
                                    outerlabel={'Pró-Gestão?'}
                                    checked={inputClientPromanagement}
                                    onChange={changePromanagement}
                                    name="promanagemente"
                                    color="primary" /> */}

                            </Grid>

                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <MySelect
                                    initblank
                                    outerlabel={'SUITABILITY'}
                                    value={inputClientSuitability}
                                    onChange={(e) => setInputClientSuitability(e.target.value)}
                                    options={listSuitabilityOptions.map((row, index) => (
                                        <option key={'client-suitability-' + index} value={index}>{row}</option>
                                    ))}
                                    helperText={"Insira o perfil suitability"}
                                    validator={inputClientSuitability == null && submitted ? true : undefined}
                                />

                            </Grid>

                            <Grid item xs={12} sm={9} md={9} lg={9}>
                                <MyTextField
                                    size={'small'}
                                    outerlabel="Logo"
                                    inputImageComponent={
                                        <>
                                            <input ref={inputImageFile} style={{ marginTop: '12px', display: cropSelected || getUrlLogo(clientOnScreen) ? 'none' : 'block' }} type="file" accept="image/*" onChange={onSelectFile} />
                                            <img style={{ marginTop: '5px' }} className={classes.imageSelected} src={cropSelected ? cropSelected : getUrlLogo(clientOnScreen)} onClick={() => doClickInputImageFile()} />
                                        </>
                                    } />


                            </Grid>



                        </Grid>

                    </form>
                </div>
            </div>

            <SelectAndCropImageModal
                open={showSelectAndCropImage}
                imgSrc={imgSrc}
                handleAction={(action) => handleActionSelectedAndCropImageModal(action)} />


        </div >
    );
}
