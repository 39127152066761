export const ArticleParams = {

    limitsByResolution: {

        resolution_4963: {

            //artigos fora dos dois limites 18 e 19
            offLimitArt18And19Ids: [35],

            limiteArt18: 20,
            offLimitArt18Ids: [34, 35],

            limiteArt19: 15,
            extraLimiteArt19: 5,
            fullOffLimitArt19: [35],
            offLimitArt19Ids: [41, 42, 43], //Em caso de FIDCs, FI Renda Fixa “Crédito Privado” e FI “Debêntures”, o limite é de 5% do patrimônio líquido do fundo

            limiteArt20: 5,

            limiteArt21: 50,

        }

    },


}