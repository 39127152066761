import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PrintIcon from '@material-ui/icons/Print';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";
import { headerSearch, toogleButton } from '../../../assets/styles/unoTheme';
import MyTable from '../../utils/MyTable';

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { getAllClientsInflationRatesByPeriod, getInflationRatesByYear } from "../../../API/inflation_rate";
import { NO_RENT_TO_COMPARE, doGetAllRentsClients, doGetAllRentsClients2, formatTargetsInClientsRents, getMoneyRentsClientsByPeriod } from "../../../controllers/ClientController";
import MySelect from "../../utils/MySelect";
import { applyMaskAmount, getConsultingId, getConsultingNameById, getDayMonthYearByPtDate, getLastDateAbleToQuotas, getMonthName, getYearsByYearUntilNow, greenOrRed, handleSimpleColumnOrder, removeAccents, showSimpleColumnOrderIcon } from "../../utils/utils";

//Import excel
import XLSX from 'xlsx';
import { downloadBlob, s2ab } from "../../../controllers/ExcelController";
import UnoButton from '../../utils/UnoButton';
import { BalancesValidationsAPI } from '../../../API/balances_validations';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    toogleButton: toogleButton,
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    toStickyHeader: {
        display: 'flex',
        height: '100%',
    },
    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },

    sectionTableCell: {
        display: 'table-cell',
        padding: '0px !important',
    },
    multipleHorizontalTableCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-around',
        '& > div': {
            width: '33%',
        }
    },
}));

//[EXCEL] Ordenando clientes por ordem rentabilidade acumulada
function compareSortExcelRentAcumDesc(a, b) {

    if (b.rentAcum == undefined || b.rentAcum == null || b.rentAcum == NO_RENT_TO_COMPARE) {
        return -1;
    }

    if (a.rentAcum == undefined || a.rentAcum == null || a.rentAcum == NO_RENT_TO_COMPARE) {
        return 1
    }

    if (a.rentAcum > b.rentAcum)
        return -1;
    if (a.rentAcum < b.rentAcum)
        return 1;
    return 0;
}

//Ordenando clientes por ordem rentabilidade acumulada
function compareSortRentAcumDesc(a, b) {

    if (a.rentAcum > b.rentAcum)
        return -1;
    if (a.rentAcum < b.rentAcum)
        return 1;
    return 0;
}

function compareSortRentAcumAsc(a, b) {

    if (a.rentAcum > b.rentAcum)
        return 1;
    if (a.rentAcum < b.rentAcum)
        return -1;
    return 0;
}

//Ordenando clientes por ordem rentabilidade mensal
function compareSortRentMonthDesc(a, b) {

    if (a.rentMonth > b.rentMonth)
        return -1;
    if (a.rentMonth < b.rentMonth)
        return 1;
    return 0;
}

//Ordenando clientes por ordem rentabilidade mensal
function compareSortRentMonthAsc(a, b) {

    if (a.rentMonth > b.rentMonth)
        return 1;
    if (a.rentMonth < b.rentMonth)
        return -1;
    return 0;
}

//Ordenando clientes por ordem gap mensal
function compareSortGapMonthDesc(a, b) {

    if (a.gapMonth > b.gapMonth)
        return -1;
    if (a.gapMonth < b.gapMonth)
        return 1;
    return 0;
}

//Ordenando clientes por ordem rentabilidade mensal
function compareSortGapMonthAsc(a, b) {

    if (a.gapMonth > b.gapMonth)
        return 1;
    if (a.gapMonth < b.gapMonth)
        return -1;
    return 0;
}

//Ordenando clientes por ordem gap mensal
function compareSortGapAcumDesc(a, b) {

    if (a.gapAccumulated > b.gapAccumulated)
        return -1;
    if (a.gapAccumulated < b.gapAccumulated)
        return 1;
    return 0;
}

//Ordenando clientes por ordem gap acumulado
function compareSortGapAcumAsc(a, b) {

    if (a.gapAccumulated > b.gapAccumulated)
        return 1;
    if (a.gapAccumulated < b.gapAccumulated)
        return -1;
    return 0;
}

//Ordenando clientes por ordem target mensal
function compareSortTargetMonthDesc(a, b) {

    if (a.targetMonth > b.targetMonth)
        return -1;
    if (a.targetMonth < b.targetMonth)
        return 1;
    return 0;
}

//Ordenando clientes por ordem target mensal
function compareSortTargetMonthAsc(a, b) {

    if (a.targetMonth > b.targetMonth)
        return 1;
    if (a.targetMonth < b.targetMonth)
        return -1;
    return 0;
}

//Ordenando clientes por ordem target acum
function compareSortTargetAcumDesc(a, b) {

    if (a.targetAccumulated > b.targetAccumulated)
        return -1;
    if (a.targetAccumulated < b.targetAccumulated)
        return 1;
    return 0;
}

//Ordenando clientes por ordem target acum
function compareSortTargetAcumAsc(a, b) {

    if (a.targetAccumulated > b.targetAccumulated)
        return 1;
    if (a.targetAccumulated < b.targetAccumulated)
        return -1;
    return 0;
}

export default function ClientsRentsScreen() {

    const classes = useStyles();
    const dispatch = useDispatch();

    const rightContent = useSelector(state => state.rightContent)
    const currentUser = useSelector(state => state.currentUser)

    const [clientRentsData, setClientRentsData] = useState([]);
    const [clientRentsDataToShow, setClientRentsDataToShow] = useState([]);

    const [period, setPeriod] = useState('ano');

    const [optionsMonths, setOptionsMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState();

    const initOptionsYears = 2015
    const optionsYears = getYearsByYearUntilNow(initOptionsYears);
    const [selectedYear, setSelectedYear] = useState();

    //Primeiro número indica a coluna e o segundo número a ordem ASC ou DESC
    //11: rent ASC
    //12: rent DESC
    //21: rentAcum ASC
    //22: rentAcum DESC
    //31: gap ASC
    //32: gap DESC
    //41: gapAcum DESC
    //42: gapAcum DESC
    const [columnOrder, setColumnOrder] = useState('21'); //Iniciando com rent ASC

    async function loadData() {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: "Carregando rentabilidades...",
        }));


        console.log("period, selectedMonth, selectedYear: ", period, selectedMonth, selectedYear);

        const response = await doGetAllRentsClients2(period, selectedMonth, selectedYear, getConsultingId(currentUser));


        if (response.success) {

            //const response = await doGetAllRentsClients(period, selectedMonth, selectedYear);
            const responseTargets = await getAllClientsInflationRatesByPeriod(selectedYear, getConsultingId(currentUser));
            const responseInflationRates = await getInflationRatesByYear(selectedYear);
            const responseMoneyRentsClients = await getMoneyRentsClientsByPeriod(period, selectedMonth, selectedYear, getConsultingId(currentUser));

            console.log("responseMoneyRentsClients: ", responseMoneyRentsClients);
            formatTargetsInClientsRents(
                response.clients,
                responseTargets.body.rows,
                responseInflationRates.body.rows, selectedMonth, selectedYear)

            if (response.success) {
                setClientRentsData(response.clients);
                const toShow = [...response.clients.map(el => ({
                    ...el,
                    rentMoneyAcum: responseMoneyRentsClients[el.id]?.result,
                    notFullPeriod: responseMoneyRentsClients[el.id]?.notFullPeriod
                }))];
                console.log("TO SHOW: ", JSON.parse(JSON.stringify(toShow)));
                toShow.sort(compareSortExcelRentAcumDesc);
                setClientRentsDataToShow(toShow);

            }
        }

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));
    }


    const [searchText, setSearchText] = useState("");

    const delayedChange = (text) => {
        setTimeout(() => {
            setSearchText(text)
        }, 800)
    }

    function changeSearch(e) {

        delayedChange(removeAccents(e.target.value.toUpperCase()))
        clearTimeout(delayedChange);
    }

    const handlePeriod = (event, newFormats) => {
        if (newFormats && newFormats.length) {
            setPeriod(newFormats);
        }
    };

    function loadInitialDate() {
        // const referenceDate = getLastDateAbleToQuotas();        
        // console.log('referenceDate: ', referenceDate);
        // const { month, year } = getDayMonthYearByPtDate(referenceDate);

        // setSelectedMonth(month);
        // setSelectedYear(year);

        setSelectedMonth(12);
        setSelectedYear(new Date().getFullYear() - 1);


    }

    async function doExcel(items, month, year, consultingName) {

        //obtendo soma dos saldos
        const responseBalances = await BalancesValidationsAPI.getAllClientsSumBalancesByMonthAndYearAndConsultingId(month, year, getConsultingId(currentUser));
        const responseAccountsBalances = await BalancesValidationsAPI.getAllClientsSumAccountBalancesByMonthAndYearAndConsultingId(month, year, getConsultingId(currentUser));
        console.log("responseBalances: ", responseBalances);
        console.log("responseAccountBalances: ", responseAccountsBalances);

        const plByClient = {};

        if (responseBalances.success && responseAccountsBalances.success) {
            Object.entries(responseBalances.data).map(([clientId, balance]) => {

                const accBalance = responseAccountsBalances.data[clientId] ?? 0;
                const assetsBalance = responseBalances.data[clientId] ?? 0;
                plByClient[clientId] = assetsBalance + accBalance;

            })
        }

        const fileName = "Levantamento_Geral_Clientes_" + consultingName + "_" + year;

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Gerando planilha...',
        }));

        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: fileName,
            Author: "UNO",
        }

        const sheetName = "Clientes " + year;
        wb.SheetNames.push(sheetName);

        const ws_data = [];

        ws_data.push([
            'CLIENTE',
            'RENT_ACUM(R$)',
            'RENT_ACUM(%)',
            'META_ACUM',
            'GAP_ACUM',
            "PL"
        ])

        const toExcelItems = [...items];

        console.log("toExcelItems: ", toExcelItems);
        console.log("plByClient: ", plByClient);
        console.log(toExcelItems.find(el => el.id == 267));

        [...toExcelItems.sort(compareSortExcelRentAcumDesc)].forEach((element, index) => {
            ws_data.push(
                !element.notFullPeriod && element.rentAcum != NO_RENT_TO_COMPARE ?
                    [
                        element.label_name,
                        !element.notFullPeriod && element.rentAcum != NO_RENT_TO_COMPARE ? applyMaskAmount(element.rentMoneyAcum, true) : "-",
                        element.rentAcum != NO_RENT_TO_COMPARE ? applyMaskAmount(element.rentAcum) + "%" : "-",
                        element.targetAccumulated ? applyMaskAmount(element.targetAccumulated) + "%" : "-",
                        element.gapAccumulated ? applyMaskAmount(element.gapAccumulated) + "%" : "-",
                        plByClient[element.id] ? applyMaskAmount(plByClient[element.id], true) : "-"
                    ] : [
                        element.label_name,
                        "-",
                        "-",
                        "-",
                        "-",
                    ])


        });

        const ws = XLSX.utils.aoa_to_sheet(ws_data);

        wb.Sheets[sheetName] = ws;
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

        downloadBlob(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), fileName + '.xlsx');

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));

    }

    useEffect(() => {

        if (columnOrder && clientRentsData && clientRentsData.length) {

            setClientRentsDataToShow(null);
            const newClientRentsData = [...clientRentsData];

            switch (columnOrder) {
                case '11':

                    newClientRentsData.sort(compareSortRentMonthDesc);
                    setClientRentsDataToShow(newClientRentsData);
                    break;
                case '12':
                    newClientRentsData.sort(compareSortRentMonthAsc);
                    setClientRentsDataToShow(newClientRentsData);
                    break;

                case '21':
                    newClientRentsData.sort(compareSortRentAcumDesc);
                    setClientRentsDataToShow(newClientRentsData);
                    break;
                case '22':
                    newClientRentsData.sort(compareSortRentAcumAsc);
                    setClientRentsDataToShow(newClientRentsData);
                    break;

                case '31':
                    newClientRentsData.sort(compareSortGapMonthDesc);
                    setClientRentsDataToShow(newClientRentsData);
                    break;
                case '32':
                    newClientRentsData.sort(compareSortGapMonthAsc);
                    setClientRentsDataToShow(newClientRentsData);
                    break;

                case '41':
                    newClientRentsData.sort(compareSortGapAcumDesc);
                    setClientRentsDataToShow(newClientRentsData);
                    break;
                case '42':
                    newClientRentsData.sort(compareSortGapAcumAsc);
                    setClientRentsDataToShow(newClientRentsData);
                    break;

                case '51':
                    newClientRentsData.sort(compareSortTargetMonthDesc);
                    setClientRentsDataToShow(newClientRentsData);
                    break;
                case '52':
                    newClientRentsData.sort(compareSortTargetMonthAsc);
                    setClientRentsDataToShow(newClientRentsData);
                    break;

                case '61':
                    newClientRentsData.sort(compareSortTargetAcumDesc);
                    setClientRentsDataToShow(newClientRentsData);
                    break;
                case '62':
                    newClientRentsData.sort(compareSortTargetAcumAsc);
                    setClientRentsDataToShow(newClientRentsData);
                    break;

                default:
                    break;
            }
        }

    }, [columnOrder])

    useEffect(() => {

        if ((rightContent && rightContent.action)) {

            dispatch(allActions.rightContentActions.setRightContent(null))

        }

    }, [period, rightContent]);

    useEffect(() => {
        if (!selectedMonth && !selectedYear) {
            loadInitialDate();
        }
    }, [])

    useEffect(() => {
        if (selectedMonth && selectedYear) {
            loadData();
        }
    }, [selectedMonth])

    useEffect(() => {
        if (!selectedYear) return;
        const today = new Date();
        if (selectedYear == today.getFullYear()) {
            const currentMonth = today.getMonth() + 1;
            const months = [];
            for (let m = 1; m < currentMonth; m++) {
                months.push(m);
            }
            setOptionsMonths([...months])
            setSelectedMonth([months[months.length - 1]]);
        } else {
            setOptionsMonths([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
            setSelectedMonth(12);
        }
    }, [selectedYear])



    return (
        <div className={classes.root}>

            <div className={classes.headerSearch}>


                <div className={classes.leftHeaderDetail}>

                    <Typography variant="h6" gutterBottom align="left">
                        Rentabilidade dos clientes
                    </Typography>
                </div>

                <div className={classes.rightHeader}>

                    {selectedMonth ? <MySelect
                        style={{ marginRight: '16px' }}
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                        options={optionsMonths.map((row, index) => (
                            <option key={'month-in-clients-rents-' + index} className={classes.optionNativeSelect} value={row}>
                                {getMonthName(row)}
                            </option>
                        ))}
                    /> : null}
                    {selectedYear ? <MySelect
                        style={{ marginRight: '16px' }}
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                        options={optionsYears.map((row, index) => (
                            <option key={'year-in-clients-rents-' + index} className={classes.optionNativeSelect} value={row}>
                                {row}
                            </option>
                        ))}
                    /> : null}

                    <ToggleButtonGroup
                        className={classes.toogleButton}
                        style={{ marginRight: '16px' }}
                        exclusive value={period} onChange={handlePeriod}>
                        <ToggleButton value="ano" aria-label="Ano">
                            {'Ano'}
                        </ToggleButton>
                        <ToggleButton value="12" aria-label="12 meses">
                            {'12 meses'}
                        </ToggleButton>
                        <ToggleButton value="24" aria-label="24 meses">
                            {'24 meses'}
                        </ToggleButton>
                        <ToggleButton value="36" aria-label="36 meses">
                            {'36 meses'}
                        </ToggleButton>

                    </ToggleButtonGroup>

                    <TextField id="standard-basic"
                        onChange={(event) => changeSearch(event)}
                        className={classes.inputSearch}
                        label="Buscar..."
                        variant="outlined"
                        size="small" />

                    {
                        period == "ano"
                        && <UnoButton
                            size={'small'}
                            onClick={() => doExcel(clientRentsDataToShow, selectedMonth, selectedYear, getConsultingNameById(getConsultingId(currentUser)))}
                            type='success'
                            isIconButton
                            myIcon={<PrintIcon />}>
                        </UnoButton>
                    }


                    {/* <UnoButton
                        style={{ marginLeft: '16px' }}
                        variant="contained"
                        type='primary'
                        onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                            path: 'newLocalBenchmark'
                        }))}
                        startIcon={<AddIcon />}>
                        {"NOVO BENCHMARK"}
                    </UnoButton> */}
                </div>
            </div>
            {/* <Divider /> */}
            <div class={'defaultScrollWithHeaderContent'}>
                <MyTable
                    selectable="true"
                    stickyHeader
                    size="small">
                    <colgroup>
                        <col width="15%" />
                        <col width="28.3%" />
                        <col width="28.3%" />
                        <col width="28.3%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableTh} align="left">Cliente</TableCell>
                            <TableCell className={classes.sectionTableCell}
                                align="center">
                                <div>{'RENTABILIDADE'}</div>
                                <div className={classes.multipleHorizontalTableCell}>
                                    <div onClick={() => handleSimpleColumnOrder('1', columnOrder, setColumnOrder)}>{'Mês (%) '} {showSimpleColumnOrderIcon('1', columnOrder)}</div>
                                    <div onClick={() => handleSimpleColumnOrder('2', columnOrder, setColumnOrder)}>{'Acum. (%) '} {showSimpleColumnOrderIcon('2', columnOrder)}</div>
                                </div>
                            </TableCell>
                            <TableCell className={classes.sectionTableCell}
                                align="center">
                                <div>{'META'}</div>
                                <div className={classes.multipleHorizontalTableCell}>
                                    <div onClick={() => handleSimpleColumnOrder('5', columnOrder, setColumnOrder)}>{'Mês (%) '} {showSimpleColumnOrderIcon('5', columnOrder)}</div>
                                    <div onClick={() => handleSimpleColumnOrder('6', columnOrder, setColumnOrder)}>{'Acum. (%)'} {showSimpleColumnOrderIcon('6', columnOrder)}</div>
                                </div>
                            </TableCell>
                            <TableCell className={classes.sectionTableCell}
                                align="center">
                                <div>{'GAP'}</div>
                                <div className={classes.multipleHorizontalTableCell}>
                                    <div onClick={() => handleSimpleColumnOrder('3', columnOrder, setColumnOrder)}>{'Mês (p.p.) '} {showSimpleColumnOrderIcon('3', columnOrder)}</div>
                                    <div onClick={() => handleSimpleColumnOrder('4', columnOrder, setColumnOrder)}>{'Acum. (p.p.)'} {showSimpleColumnOrderIcon('4', columnOrder)}</div>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            clientRentsDataToShow && clientRentsDataToShow.length ? clientRentsDataToShow.map((row, index) => (
                                <>
                                    {
                                        searchText == "" || (
                                            removeAccents(row.label_name.toUpperCase()).includes(searchText)) ?
                                            <TableRow key={'client-rent-' + index}>
                                                <TableCell align="left">{row.label_name}</TableCell>
                                                <TableCell align="center" className={classes.sectionTableCell}>
                                                    <div className={classes.multipleHorizontalTableCell}>
                                                        <div style={{ color: greenOrRed(row.rentMonth) }}>{row.rentMonth && row.rentMonth != NO_RENT_TO_COMPARE ? applyMaskAmount(row.rentMonth) + '%' : '-'}</div>
                                                        <div style={{ color: greenOrRed(row.rentAcum) }}>{row.rentAcum && row.rentAcum != NO_RENT_TO_COMPARE ? applyMaskAmount(row.rentAcum) + '%' : '-'}</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center" className={classes.sectionTableCell}>
                                                    <div className={classes.multipleHorizontalTableCell}>
                                                        <div style={{ color: greenOrRed(row.targetMonth) }}>{row.targetMonth ? applyMaskAmount(row.targetMonth) + '%' : '-'}</div>
                                                        <div style={{ color: greenOrRed(row.targetAccumulated) }}>{row.targetAccumulated ? applyMaskAmount(row.targetAccumulated) + '%' : '-'}</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center" className={classes.sectionTableCell}>
                                                    <div className={classes.multipleHorizontalTableCell}>
                                                        <div style={{ color: greenOrRed(row.gapMonth) }}>{row.gapMonth ? applyMaskAmount(row.gapMonth) + 'p.p.' : '-'}</div>
                                                        <div style={{ color: greenOrRed(row.gapAccumulated) }}>{row.gapAccumulated ? applyMaskAmount(row.gapAccumulated) + 'p.p.' : '-'}</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow> : null
                                    }
                                </>
                            )) : null
                        }
                    </TableBody>
                </MyTable>
                {/* </div> */}
            </div>
        </div >
    );
}



